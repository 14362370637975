import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import { Plan } from "../../../../../version1/bo/transformation/Plan";
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import { PeriodKind, maxPeriods } from "../../../../bo/vdml/PeriodKind";
import { PlanDetailsViewModel } from "./PlanDetailsViewModel";
import { AdvancedPlanDetails2ViewModel } from "./AdvancedPlanDetails2ViewModel";
import { Phase2Mixin } from "../../../../bo/transformation/Phase2Mixin";
import { add, getYear, getMonth, getQuarter, getTime, setDayOfYear, getDayOfYear, lastDayOfMonth, format, addDays } from "date-fns";
import { daysPerWeekly, daysPer4Weekly, periodsPerYearWeely, periodsPerYear4Weely, getMonthFromDate } from "../../../../../../utils";
import { UTCDate } from "@date-fns/utc";

var path = DataManager.getDataManager().buildAppNsPath(
    "transformation.views.properties",
    global.version
);

export class PlanDetails2ViewModel extends PlanDetailsViewModel {

    createPlan() {
        var self = this;
        window.utils.startSpinner("planComplete", "Saving Plan...");
        self.saveWorkspaceChanges(function (workspaceCreated) {
            if (!self.model) {
                if (!self.description()) {
                    self.description(self.name());
                }
                Plan.createPlanModel(self.name(),self.description().trim(),
                    function (plan) {
                        var dataManager = DataManager.getDataManager();
                        dataManager.savePlanArifactsData(plan.get("id"),dataManager.get("currentWorkspaceId"));
                        self.model = plan;
                        plan.loadCommonWorkspace(true, function () {
                            self.savePlanDetails(plan, function () {
                                //2.0 changes start
                                self.model.set({ periodKind: self.periodKind()});
                                var stepData = self.updateStepData();
                                var defSce = plan.createPlanScenarioInstance(self.planScenarioStartTime(), stepData);
                                plan.set("defaultScenario", defSce.id);
                                plan.set("defaultExecutionScenario", defSce.get('defaultExecutionScenario'));
                                self.advancedViewInstance.saveAdvancedView(plan);
                                //end
                                dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),
                                    function (response) {
                                        self.saveAndUpdateLicence(true, workspaceCreated, function () {
                                                self.refreshPage();
                                                window.utils.stopSpinner("planComplete");
                                                window.vdmModelView.addPlanToHomePage(plan);
                                            }
                                        );
                                    }
                                );
                            });
                        });
                    }
                );
            } else {
                self.savePlanDetails(self.model, function (plan) {
                    var scId = plan.get("defaultScenario");
                    const changeinPeriodKind = self.model.get('periodKind') !== self.periodKind();
                    const changeinStart = self.defaultScenario?.get("startTime") != self.planScenarioStartTime();
                    if (changeinPeriodKind || changeinStart) {
                        plan.updatePeriodKindOrStartTime(scId, self.periodKind(), self.planScenarioStartTime());
                        plan.set('periodKind', self.periodKind());
                    }
                    plan.updatePlanDefaultScenarioInstance(self.planScenarioStartTime(), self.scenarioPathStep(), scId);
                    self.advancedViewInstance.saveAdvancedView(plan);
                    self.saveAndUpdateLicence(false, false, function () {
                        self.refreshPage();
                        window.utils.stopSpinner("planComplete");
                        window.vdmModelView.addPlanToHomePage(plan, true);
                    });
                });
            }
        });
    }

    updateStepData() {
        var self = this;
        var stepData = [];
        let phases = self.model.get("phase").models;
        phases.forEach(phase => {
            var phaseStep = self.scenarioPathStep().find((obj) => obj.name === phase.get('name'));
            if(phaseStep){
                var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
                var obj = {id: alt.id, noOfPeriods: phaseStep.noOfPeriods};
                stepData.push(obj);
            }
        });
        return stepData;
    }

    getYearRange() {
        const currentYear = new Date().getFullYear();
        const startYear = 2000;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }

        return years;
    }
    getStartPeriodOptions(val) {
        var self = this;
        if (val === PeriodKind.symbols()[0].name) {
            return [1];
        } else if (val === PeriodKind.symbols()[1].name) {
            return self.getAllPeriods(4);
        } else if (val === PeriodKind.symbols()[2].name) {
            return self.getAllPeriods(12);
        } else if (val === PeriodKind.symbols()[3].name) {
            return self.getAllPeriods(periodsPerYear4Weely);
        } else if (val === PeriodKind.symbols()[4].name) {
            return self.getAllPeriods(periodsPerYearWeely);
        }
    }

    getAllPeriods(num) {
        const periods = [];
        for (let index = 1; index <= num; index++) {
            periods.push(index);
        }
        return periods;
    }

    deletePlanObject = function (planModel, callback) {
        planModel.deleteScenario(function () {
            planModel.deletePlan(function () {
                callback();
            });
        });

    }

    deletePhase() {
        var self = this;
        super.deletePhase();
        self.updateScenarioPathStepData();
    };

    createPhase(view, event) {
        var self = this;
        if (self.goalValue() !== undefined) {
            bootbox.alert("Cannot create Phase after Goal Phase");
            return false;
        }
        if ($.find("#modal" + self.phaseArray.length).length > 0) {
            $("#modal" + self.phaseArray.length).modal('show');
        }
        else {
            const periodsUntillCurPhase = self.scenarioPathStep().reduce((accumulator, currentObject) => {
                return accumulator + currentObject.noOfPeriods;
            }, 0);
            const sDate = Phase2Mixin.calcPhaseStartDate(self.periodKind(), self.planScenarioStartTime(), periodsUntillCurPhase);
            const eDate = Phase2Mixin.calcPhaseEndDate(self.periodKind(), sDate, 1);
            var options = { "planDetails": "plan", "phaseArray": self.phaseArray, startDate: sDate, endDate: eDate };
            window.getAndCreateModalDialog(self.model, self.phaseArray.length, Phase2Mixin, null, "PhaseDetails", _.bind(self.addChildModel, self), options);
        }
    };

    getNoOfPeriods(modalId) {
        // getting the no of periods if the phase is already present or created
        // here scenarioPathStep will contain phase details which are not still saved and defaultScenario contains phase details which is coming from backend
        // so giving priority to scenarioPathStep as it may contain data which is recently updated and not saved 
        var self = this;
        const stepDataInCache = self.scenarioPathStep().find((obj) => obj.modalId === modalId);
        // if no of periods is greater than the max possible no of periods
        const noOfPeriodsInCahche = stepDataInCache && stepDataInCache?.noOfPeriods > self.maxNoOfPeriods() ? self.maxNoOfPeriods() : stepDataInCache?.noOfPeriods;
        const stepDataInBackend = self.defaultScenario?.get('step').models;
        const noOfPeriods = noOfPeriodsInCahche != undefined ? noOfPeriodsInCahche : stepDataInBackend?.find((obj) => obj.get('phaseId') === modalId)?.get('noPeriods');
        return noOfPeriods != undefined ? parseInt(noOfPeriods, 10) : 1;
    }

    calculateYearlyScenarioPathStepData(id, numbersOfPeriods) {
        var self = this;
        var startYear = parseInt(self.scenarioStartYear(), 10);
        var phaseStartPeriod = parseInt(self.scenarioStartPeriod(), 10);
        const data = [];
        // in data.stepInitialPeriod denotes the starting period of the phase in a year.
        // in data.startPeriod denotes the what is the position of first period of a phase if the position of first period of first phase is 1.
        var firstPeriodPosition = 1;
        self.phaseCollection().map((ele, index) => {
            const noOfPeriods = (!isNaN(numbersOfPeriods) && ele.modalId === id) ? numbersOfPeriods : self.getNoOfPeriods(ele.modalId);
            if (ele.del === false && noOfPeriods != 0) {
                var range = null;
                //subtracting 1 from month because javascript starts counting the month 0 and in ui it is starting from 1.
                const startDate = new Date(startYear, 0, 1);
                const endDate = add(new Date(startYear, 11, 31), {
                    years: noOfPeriods - 1,
                });
                const endYear = getYear(endDate);
                range = noOfPeriods === 1 ? `${startYear}` : `${startYear} - ${endYear}`;
                startYear = startYear + noOfPeriods;
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: firstPeriodPosition, range: range, stepInitialPeriod: phaseStartPeriod, startDate: format(startDate, 'MM/dd/yyyy'), endDate: format(endDate, 'MM/dd/yyyy') });
                firstPeriodPosition = firstPeriodPosition + noOfPeriods;

            } else if (ele.del === false && noOfPeriods == 0) {
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: 0, range: null, stepInitialPeriod: 0, startDate: null, endDate: null });
            } else if (ele.del === true) {
                const stepData = self.scenarioPathStep().find((obj) => obj.modalId === ele.modalId);
                self.scenarioPathStep.remove(stepData);
            }

        });
        return data;
    }

    calculateMonthlyScenarioPathStepData(id, numbersOfPeriods) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var startYear = parseInt(self.scenarioStartYear(), 10);
        var phaseStartPeriod = parseInt(self.scenarioStartPeriod(), 10);
        const data = [];
        // in data.stepInitialPeriod denotes the starting period of the phase in a year.
        // in data.startPeriod denotes the what is the position of first period of a phase if the position of first period of first phase is 1.
        var firstPeriodPosition = 1;
        self.phaseCollection().map((ele, index) => {
            const noOfPeriods = (!isNaN(numbersOfPeriods) && ele.modalId === id) ? numbersOfPeriods : self.getNoOfPeriods(ele.modalId);
            if (ele.del === false && noOfPeriods != 0) {
                var range = null;
                //subtracting 1 from month because javascript starts counting the month 0 and in ui it is starting from 1.
                const startDate = new Date(startYear, phaseStartPeriod - 1, 1);
                const endDate = lastDayOfMonth(add(startDate, {
                    months: noOfPeriods - 1,
                }));
                const startMonth = dataManager.get('localeManager').get(getMonthFromDate(new Date(startYear, phaseStartPeriod - 1, 1)));
                const endMonth = dataManager.get('localeManager').get(getMonthFromDate(endDate));
                const endYear = getYear(endDate);

                range = noOfPeriods === 1 ? `${startYear}/${startMonth}` : `${startYear}/${startMonth} - ${endYear}/${endMonth}`;
                const startDateForNextPhase = add(startDate, {
                    months: noOfPeriods,
                });
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: firstPeriodPosition, range: range, stepInitialPeriod: phaseStartPeriod, startDate: format(startDate, 'MM/dd/yyyy'), endDate: format(endDate, 'MM/dd/yyyy') });
                startYear = getYear(startDateForNextPhase);
                phaseStartPeriod = getMonth(startDateForNextPhase) + 1;
                firstPeriodPosition = firstPeriodPosition + noOfPeriods;

            } else if (ele.del === false && noOfPeriods == 0) {
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: 0, range: null, stepInitialPeriod: 0, startDate: null, endDate: null });
            } else if (ele.del === true) {
                const stepData = self.scenarioPathStep().find((obj) => obj.modalId === ele.modalId);
                self.scenarioPathStep.remove(stepData);
            }
        });
        return data;
    }

    calculateQuaterlyScenarioPathStepData(id, numbersOfPeriods) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var startYear = parseInt(self.scenarioStartYear(), 10);
        var phaseStartPeriod = parseInt(self.scenarioStartPeriod(), 10);
        const data = [];
        // in data.stepInitialPeriod denotes the starting period of the phase in a year.
        // in data.startPeriod denotes the what is the position of first period of a phase if the position of first period of first phase is 1.
        var firstPeriodPosition = 1;
        let startDateForNextPhase;
        self.phaseCollection().map((ele, index) => {
            const noOfPeriods = (!isNaN(numbersOfPeriods) && ele.modalId === id) ? numbersOfPeriods : self.getNoOfPeriods(ele.modalId);
            if (ele.del === false && noOfPeriods != 0) {
                var range = null;
                // In one quarter there are 3 months so we have to multiply by 3 in the period to get the required month.
                // For getting the first month number of a quarter we need to subtract 3.
                const monthsInOneQuarter = 3;
                const startDate = new Date(startYear, (phaseStartPeriod * monthsInOneQuarter) - 3, 1);
                const endDate = lastDayOfMonth(add(startDate, {
                    months: (noOfPeriods * monthsInOneQuarter) - 1,
                }));
                const startMonth = dataManager.get('localeManager').get(getMonthFromDate(startDate));
                const endMonth =  dataManager.get('localeManager').get(getMonthFromDate(endDate));
                const endYear = getYear(endDate);

                range = `${startYear}/${startMonth} - ${endYear}/${endMonth}`;
                startDateForNextPhase = add(endDate, {
                    days: 1,
                });
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: firstPeriodPosition, range: range, stepInitialPeriod: phaseStartPeriod, startDate: format(startDate, 'MM/dd/yyyy'), endDate: format(endDate, 'MM/dd/yyyy') });
                startYear = getYear(startDateForNextPhase);
                phaseStartPeriod = getQuarter(startDateForNextPhase);
                firstPeriodPosition = firstPeriodPosition + noOfPeriods;
            } else if (ele.del === false && noOfPeriods == 0) {
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: 0, range: null, stepInitialPeriod: 0, startDate: null, endDate: null });
            } else if (ele.del === true) {
                const stepData = self.scenarioPathStep().find((obj) => obj.modalId === ele.modalId);
                self.scenarioPathStep.remove(stepData);
            }

        });
        return data;
    }

    calculate4WeeklyScenarioPathStepData(id, numbersOfPeriods) {
        var self = this;
        var startYear = parseInt(self.scenarioStartYear(), 10);
        var phaseStartPeriod = parseInt(self.scenarioStartPeriod(), 10);
        const data = [];
        // in data.stepInitialPeriod denotes the starting period of the phase in a year.
        // in data.startPeriod denotes the what is the position of first period of a phase if the position of first period of first phase is 1.
        var firstPeriodPosition = 1;
        var stepInitialPeriod = phaseStartPeriod;
        var stepInitialYear = startYear;
        self.phaseCollection().map((ele, index) => {
            // (!isNaN(numbersOfPeriods) && ele.modalId === id) this condition is true when the user is chenging no of periods of particular phase
            const noOfPeriods = (!isNaN(numbersOfPeriods) && ele.modalId === id) ? numbersOfPeriods : self.getNoOfPeriods(ele.modalId);
            if (ele.del === false && noOfPeriods != 0) {
                let noOfYears = Math.floor(Math.floor(stepInitialPeriod + noOfPeriods - 1) / periodsPerYear4Weely);
                let stepLastPeriod = ((stepInitialPeriod + noOfPeriods - 1) % periodsPerYear4Weely);
                if (stepLastPeriod === 0) {
                    noOfYears = noOfYears - 1;
                    stepLastPeriod = periodsPerYear4Weely;
                }
                const startOfRange = `${stepInitialYear}/${stepInitialPeriod}`
                const startDate = setDayOfYear(new Date(stepInitialYear, 1, 1), ((stepInitialPeriod - 1) * daysPer4Weekly) + 1);
                const endOfRange = `${stepInitialYear + noOfYears}/${stepLastPeriod}`;
                const endDate = setDayOfYear(new Date(stepInitialYear + noOfYears, 1, 1), ((stepLastPeriod) * daysPer4Weekly));
                const range = noOfPeriods === 1 ? startOfRange : startOfRange + " - " + endOfRange;
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: firstPeriodPosition, range: range, stepInitialPeriod: phaseStartPeriod, startDate: format(startDate, 'MM/dd/yyyy'), endDate: format(endDate, 'MM/dd/yyyy') });
                if (stepLastPeriod === periodsPerYear4Weely) {
                    stepInitialYear = stepInitialYear + noOfYears + 1;
                    stepInitialPeriod = 1;
                } else {
                    stepInitialYear = stepInitialYear + noOfYears;
                    stepInitialPeriod = stepLastPeriod + 1;
                }
                firstPeriodPosition = firstPeriodPosition + noOfPeriods;
            } else if (ele.del === false && noOfPeriods == 0) {
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: 0, range: null, stepInitialPeriod: 0, startDate: null, endDate: null });
            } else if (ele.del === true) {
                const stepData = self.scenarioPathStep().find((obj) => obj.modalId === ele.modalId);
                self.scenarioPathStep.remove(stepData);
            }

        });
        return data;
    }

    calculateWeeklyScenarioPathStepData(id, numbersOfPeriods) {
        var self = this;
        var wk = DataManager.getDataManager().get('localeManager').get('week');
        var startYear = parseInt(self.scenarioStartYear(), 10);
        var phaseStartPeriod = parseInt(self.scenarioStartPeriod(), 10);
        const data = [];
        // in data.stepInitialPeriod denotes the starting period of the phase in a year.
        // in data.startPeriod denotes the what is the position of first period of a phase if the position of first period of first phase is 1.
        var firstPeriodPosition = 1;
        var stepInitialPeriod = phaseStartPeriod;
        var stepInitialYear = startYear;
        self.phaseCollection().map((ele, index) => {
            // (!isNaN(numbersOfPeriods) && ele.modalId === id) this condition is true when the user is chenging no of periods of particular phase
            const noOfPeriods = (!isNaN(numbersOfPeriods) && ele.modalId === id) ? numbersOfPeriods : self.getNoOfPeriods(ele.modalId);
            if (ele.del === false && noOfPeriods != 0) {
                let noOfYears = Math.floor(Math.floor(stepInitialPeriod + noOfPeriods - 1) / periodsPerYearWeely);
                let stepLastPeriod = ((stepInitialPeriod + noOfPeriods - 1) % periodsPerYearWeely);
                if (stepLastPeriod === 0) {
                    noOfYears = noOfYears - 1;
                    stepLastPeriod = periodsPerYearWeely;
                }
                const startOfRange = `${stepInitialYear}/${wk} ${stepInitialPeriod}`
                const startDate = setDayOfYear(new Date(stepInitialYear, 1, 1), ((stepInitialPeriod - 1) * daysPerWeekly) + 1);
                const endOfRange = `${stepInitialYear + noOfYears}/ ${wk} ${stepLastPeriod}`;
                const endDate = setDayOfYear(new Date(stepInitialYear + noOfYears, 1, 1), ((stepLastPeriod) * daysPerWeekly));
                const range = noOfPeriods === 1 ? startOfRange : startOfRange + " - " + endOfRange;
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: firstPeriodPosition, range: range, stepInitialPeriod: phaseStartPeriod, startDate: format(startDate, 'MM/dd/yyyy'), endDate: format(endDate, 'MM/dd/yyyy') });
                if (stepLastPeriod === periodsPerYearWeely) {
                    stepInitialYear = stepInitialYear + noOfYears + 1;
                    stepInitialPeriod = 1;
                } else {
                    stepInitialYear = stepInitialYear + noOfYears;
                    stepInitialPeriod = stepLastPeriod + 1;
                }
                firstPeriodPosition = firstPeriodPosition + noOfPeriods;
            } else if (ele.del === false && noOfPeriods == 0) {
                data.push({ maxNoOfPeriods: self.maxNoOfPeriods(), name: ele.name, modalId: ele.modalId, originalId: ele.originalId, noOfPeriods: noOfPeriods, startPeriod: 0, range: null, stepInitialPeriod: 0, startDate: null, endDate: null });
            } else if (ele.del === true) {
                const stepData = self.scenarioPathStep().find((obj) => obj.modalId === ele.modalId);
                self.scenarioPathStep.remove(stepData);
            }

        });
        return data;
    }

    updateScenarioPathStepData(id, numbersOfPeriods) {
        var self = this;
        const perKind = self.periodKind();
        if (perKind === PeriodKind.symbols()[0].name) {
            self.maxNoOfPeriods(maxPeriods.maxPeriodsForYearly);
            self.scenarioPathStep(self.calculateYearlyScenarioPathStepData(id, numbersOfPeriods));
        } else if (perKind === PeriodKind.symbols()[2].name) {
            self.maxNoOfPeriods(maxPeriods.maxPeriodsForMonthly);
            self.scenarioPathStep(self.calculateMonthlyScenarioPathStepData(id, numbersOfPeriods));
        } else if (perKind === PeriodKind.symbols()[1].name) {
            self.maxNoOfPeriods(maxPeriods.maxPeriodsForQuarterly);
            self.scenarioPathStep(self.calculateQuaterlyScenarioPathStepData(id, numbersOfPeriods));
        } else if (perKind === PeriodKind.symbols()[3].name) {
            self.maxNoOfPeriods(maxPeriods.maxPeriodsFor4Weekly);
            self.scenarioPathStep(self.calculate4WeeklyScenarioPathStepData(id, numbersOfPeriods));
        } else if (perKind === PeriodKind.symbols()[4].name) {
            self.maxNoOfPeriods(maxPeriods.maxPeriodsForWeekly);
            self.scenarioPathStep(self.calculateWeeklyScenarioPathStepData(id, numbersOfPeriods));
        }
    }


    onPeriodChange(event) {
        var self = this;
        const numberOfPeriods = parseInt(event?.target.value);
        self.updateScenarioPathStepData(event?.target?.getAttribute('modalId'), numberOfPeriods);
    }

    setPlanScenarioStartTime() {
        var self = this;
        const year = self.scenarioStartYear();
        const periodNumber = self.scenarioStartPeriod();
        const perKind = self.periodKind();
        if (perKind === PeriodKind.symbols()[2].name) {
            //subtracting 1 from month because javascript starts counting the month 0 and in ui it is starting from 1.
            self.planScenarioStartTime(getTime(new UTCDate(year, periodNumber - 1, 1)));
        } else if (perKind === PeriodKind.symbols()[1].name) {
            // one quarter contain 3 months so we have to multiply from 3 to get total no of months.
            // to get first month of the quarter we have to subtract from 3.
            self.planScenarioStartTime(getTime(new UTCDate(year, (periodNumber * 3) - 3, 1)));
        } else if (perKind === PeriodKind.symbols()[3].name) {
            // one 4-Weekly contain 28 day so we have to get the first day of the selected period.
            // So first we calculate the total no of days in the year till previous period so for that -1 is substracted first from the current period and then multiplied from 28 to get total no of days.
            // finally adding 1 to get the first day of the selected period.
            const dayNumbersInYear = ((periodNumber - 1) * daysPer4Weekly) + 1;
            self.planScenarioStartTime(getTime(setDayOfYear(new UTCDate(year, 0, 1), dayNumbersInYear)));
        } else if (perKind === PeriodKind.symbols()[4].name) {
            // one Weekly contain 7 days so we have to get the first day of the selected period.
            // So first we calculate the total no of days in the year till previous period of required period so for that -1 is substracted first from the current period and then multiplied from 7 to get total no of days.
            // finally adding 1 to get the first day of the selected period.
            const dayNumbersInYear = ((periodNumber - 1) * daysPerWeekly) + 1;
            self.planScenarioStartTime(getTime(setDayOfYear(new UTCDate(year, 0, 1), dayNumbersInYear)));
        } else {
            self.planScenarioStartTime(getTime(new UTCDate(year, 0, 1)));
        }
    }
    getDefaultStartYearAndPeriod(startTime) {
        var self = this;
        const perKind = self.periodKind();
        const startTimeDate = new Date(startTime).toUTCString();
        if (perKind === PeriodKind.symbols()[2].name) {
            return {
                startYear: getYear(startTimeDate),
                startPeriod: getMonth(startTimeDate) + 1
            }
        } else if (perKind === PeriodKind.symbols()[1].name) {
            return {
                startYear: getYear(startTimeDate),
                startPeriod: getQuarter(startTimeDate)
            }
        } else if (perKind === PeriodKind.symbols()[3].name) {
            return {
                startYear: getYear(startTimeDate),
                startPeriod: self.calculatePeriodFromStartTime(startTimeDate, daysPer4Weekly)
            }
        } else if (perKind === PeriodKind.symbols()[4].name) {
            return {
                startYear: getYear(startTimeDate),
                startPeriod: self.calculatePeriodFromStartTime(startTimeDate, daysPerWeekly)
            }
        } else {
            return {
                startYear: getYear(startTimeDate),
                startPeriod: 1
            }
        }
    }
    calculatePeriodFromStartTime(startTimeDate, daysPerPeriod) {
        const dayNumbersInYear = getDayOfYear(startTimeDate);
        let completePeriods = Math.floor(dayNumbersInYear / daysPerPeriod);
        // adding 1 in completePeriods because completePeriods will be the count of all periods excluding the last period
        completePeriods += 1;
        return completePeriods;
    }

    createTable() {
        var self = this;
        self.phaseDataTable =
            $('#phaseTimelineTable').DataTable({
                data: self.scenarioPathStep(),
                columns: [
                    { data: 'name', title: "Phase Name", width: "35%", className: 'col-xs-3' },
                    {
                        data: 'noOfPeriods',
                        title: "Periods",
                        width: "20%",
                        className: 'editable col-xs-2',
                        render: function (data, type, row) {
                            const cellId = "inputTag" + row.modalId;
                            return "<input class='periodInput' value='" + data + "' id='" + cellId + "' modalId='" + row.modalId + "' type='number' min='0' step='1' >"
                        }
                    },
                    { data: 'range', title: "Range", width: "45%", className: 'col-xs-5' },
                ],
                rowId: function (data) { return data.modalId },
                scrollY: "200px",
                scrollCollapse: true,
                paging: false,
                width: "100%",
                ordering: false,
                searching: false,
                bInfo: false
            });
    }
    submitToMarket() {
        var self = this;
        var dataManager = DataManager.getDataManager();
        if (self.model) {
            var planId = self.model.id;
            dataManager.checkOwnedPackage(self.model, function (resp) {
                if (resp && self) {
                    if (resp.owner) {
                        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                        var planDoc = dataManager.get("artifactsDocuments")[planId];
                        if (resp.marketplaceId) {
                            dataManager.navigateToCrmPage("workspace/" + currentWorkspaceId + "/submitted/" + resp.marketplaceId + "/" + planDoc.artifactId);
                        } else {
                            dataManager.navigateToCrmPage("workspace/" + currentWorkspaceId + "/" + planDoc.artifactId + "/marketplace");
                        }
                    } else {
                        bootbox.alert("Package Submission is not allowed");
                    }
                }
            });
        }
    }

    cleanUp() {
        super.cleanUp();
        if (ko.components.isRegistered('AdvancedPlanDetails2Template')) {
            ko.components.unregister('AdvancedPlanDetails2Template');
        }
    }

    init(model, options, detailedView) {
        super.init(model, options);
        var self = this;
        this.onPeriodChange = _.bind(this.onPeriodChange, this);
        this.PlanDetails2ViewModel = this;
        self.parentOptions = options;
        options = null;
        self.model = model;
        model = null;
        var dataManager = DataManager.getDataManager();
        self.defaultScenario = dataManager.get('defaultScenario');
        self.showDetails = ko.observable(true);
        self.showPermissions = ko.observable(false);
        self.showAdvanced = ko.observable(false);
        self.showHorizon = ko.observable(false);
        self.canExport = ko.observable(false);
        self.showMarketSubmit = ko.observable(false);
        self.planScenarioStartTime = ko.observable(
            self.defaultScenario?.get("startTime") ? self.defaultScenario.get("startTime") : null
        );
        self.periodKindOptions = PeriodKind.symbols();
        self.periodKind = ko.observable(
            self.model ? self.model.get("periodKind") : PeriodKind.Monthly.name
        );
        self.startYearOption = self.getYearRange();
        self.startPeriodOptions = ko.observableArray(
            self.getStartPeriodOptions(self.periodKind())
        );
        self.scenarioStartYear = ko.observable(
            self.defaultScenario ? self.getDefaultStartYearAndPeriod(self.defaultScenario.get("startTime")).startYear : getYear(new Date())
        );
        self.scenarioStartPeriod = ko.observable(
            self.defaultScenario ? self.getDefaultStartYearAndPeriod(self.defaultScenario.get("startTime")).startPeriod : getMonth(new Date()) + 1
        );
        self.scenarioPathStep = ko.observableArray([]);
        self.maxNoOfPeriods = ko.observable(maxPeriods.maxPeriodsForMonthly);

        self.phaseDataTable = null;

        self.labels["periodKind"] = kb.observable(
            dataManager.get("localeManager"),
            "periodKind"
        );
        self.labels["Yearly"] = kb.observable(
            dataManager.get("localeManager"),
            "Yearly"
        );
        self.labels["Quarterly"] = kb.observable(
            dataManager.get("localeManager"),
            "Quarterly"
        );
        self.labels["Monthly"] = kb.observable(
            dataManager.get("localeManager"),
            "Monthly"
        );
        self.labels["4-Weekly"] = kb.observable(
            dataManager.get("localeManager"),
            "4-Weekly"
        );
        self.labels["Weekly"] = kb.observable(
            dataManager.get("localeManager"),
            "Weekly"
        );
        self.labels["Publish"] = kb.observable(
            dataManager.get("localeManager"),
            "Publish"
        );
        for (var i = 0; i < self.periodKindOptions.length; i++) {
            self.periodKindOptions[i].description =
                self.labels[self.periodKindOptions[i].name]();
        }
        self.periodKind.subscribe(function (val) {
            self.startPeriodOptions(self.getStartPeriodOptions(val));
            self.updateScenarioPathStepData();
            self.setPlanScenarioStartTime();
        });
        self.scenarioStartPeriod.subscribe(function (val) {
            self.updateScenarioPathStepData();
            self.setPlanScenarioStartTime();
        });
        self.scenarioStartYear.subscribe(function (val) {
            self.updateScenarioPathStepData();
            self.setPlanScenarioStartTime();
        });
        self.phaseCollection.subscribe(function (val) {
            self.updateScenarioPathStepData();
        });
        self.scenarioPathStep.subscribe(function (val) {
            self.phaseDataTable?.clear().draw(true);
            for (var i = 0; i < val.length; i++) {
                self.phaseDataTable?.row.add(val[i]).draw(true);
            }
        });
        self.advancedViewInstance = AdvancedPlanDetails2ViewModel.getInstance(self.model);
        if (!ko.components.isRegistered('AdvancedPlanDetails2Template')) {
            ko.components.register('AdvancedPlanDetails2Template', {
                template: window.utils.getHtmlPage('AdvancedPlanDetails2Template'),
                viewModel: { instance: self.advancedViewInstance },
            });
        }
        if (self.model) {
            this.canExport = ko.observable(true);
            this.canExport(DataManager.getDataManager().canExport(self.model));
            if (!DataManager.getDataManager().get('readMode') && !window.utils.checkIsLinked(null, true) && this.canExport()) {
                //also check submitted and purchased products
                var roles = DataManager.getDataManager().get('serverRoles');
                var show = false;
                if ((roles && roles.indexOf('LEGAL_ENTITY_ADMIN') != -1) || window.utils.isVMPUser()) {
                    show = true;
                }
                if (!DataManager.getDataManager().isEnterpriseEdition()) {
                    show = false;
                }
                self.showMarketSubmit(show);
            }
        }

        this.editPhaseButtonClick = function (view, event) {
            var id = view.originalId;
            const stepData = self.scenarioPathStep().find((obj) => obj.modalId === view.modalId);
            var options = { "planDetails": "plan", "phaseArray": self.phaseArray, startDate: stepData.startDate, endDate: stepData.endDate };
            if (id !== "") {
                if ($.find("#modal" + window.utils.htmlEscape(id)).length > 0) {
                    for (var i = 0; i < self.children.length; i++) {
                        if (window.utils.htmlEscape(id) === self.children[i].modalId) {
                            window.updateView(
                                self.children[i].view,
                                self.children[i].oldData
                            );
                            break;
                        }
                    }
                    $("#modal" + window.utils.htmlEscape(id)).modal("show");
                } else {
                    var phase = self.model.get("phase").findWhere({ id: id });
                    window.getAndCreateModalDialog(
                        self.model,
                        window.utils.htmlEscape(id),
                        Phase2Mixin,
                        phase,
                        "PhaseDetails",
                        _.bind(self.addChildModel, self),
                        options
                    );
                }
            } else {
                id = view.modalId;
                for (var i = 0; i < self.children.length; i++) {
                    if (id === self.children[i].modalId) {
                        options = $.extend({}, { 'modalId': id, 'addOptions': options }, options);
                        self.children[i].view.options = $.extend({}, options,  self.children[i].view.options);
                        window.updateView(self.children[i].view, self.children[i].oldData);
                        self.children[i].view.getDatesForPlanDialog();
                        break;
                    }
                }
                $("#modal" + id).modal("show");
            }
        };
        var modalId = '#modal' + self.parentOptions.modalId;
        if ($(modalId).find('.modal-dialog')) {
            $(modalId).find('.modal-dialog')[0].style.width = window.innerWidth - window.innerWidth / 2 + 'px'
        }
    }
    afterRenderView(node, view) {
        super.afterRenderView(node, view)
        var self = view;
        self.setPlanScenarioStartTime();

        if (self.model && self.defaultScenario) {
            self.updateScenarioPathStepData();
        }
        self.createTable();
        if (self.phaseDataTable) {
            $('#phaseTimelineTable tbody').on('input', 'td.editable input', function (event) {
                // Remove non-numeric characters
                event.target.value = event.target.value.replace(/[^0-9]/g, "");
                
                // Ensure the value doesn't exceed maxPeriods
                if (event.target.value > self.maxNoOfPeriods()) {
                    event.target.value = event.target.prevValue || maxPeriods;
                } else {
                    event.target.prevValue = event.target.value;
                }
                event.target.focus();
            });
            $('#phaseTimelineTable tbody').on('change', 'td.editable input', function (event) {
                self.onPeriodChange(event);
            });
        }
        $('#mainTab' + self.encodeId + ' a').click(function (e) {
            e.preventDefault();
            $(this).tab('show');
            if ($(this)[0].className === "Permissions") {
                self.showPermissions(true);
                self.showDetails(false);
                self.showAdvanced(false);
                self.showHorizon(false);
            }
            else if ($(this)[0].className === "Details") {
                self.showDetails(true);
                self.showPermissions(false);
                self.showAdvanced(false);
                self.showHorizon(false);
            }
            else if ($(this)[0].className === "Horizon") {
                self.showHorizon(true);
                self.showDetails(false);
                self.showPermissions(false);
                self.showAdvanced(false);
                self.phaseDataTable.columns.adjust();
            }
            else if ($(this)[0].className === "Advanced") {
                self.showDetails(false);
                self.showPermissions(false);
                self.showAdvanced(true);
                self.showHorizon(false);
            }
        });
    }

    static getInstance(model, options) {
        var view = new PlanDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.PlanDetails2ViewModel = PlanDetails2ViewModel;
