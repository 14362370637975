import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'


	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
	export class AlternativeDetailsViewModel{

	htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    };
	cleanUp(){
		this.packages.removeAll();
		this.selectedPackage.removeAll();
	};
	/*fetchAlternativePackages(){
		var self = this;
		for(var i=0;i<self.model.get('phaseDesignPart').length;i++){
		var designModel = self.model.get('phaseDesignPart').models[i];
		var enableMain = true;
		var beep = designModel.get('beepReference');
		var show = true;
		var own = true;
		if(beep.indexOf("Common") > -1 && designModel.get('beepType') !== 'report_BeepReport' && designModel.get('beepType') !== 'dashboard_Analytics'){
			enableMain = false;
			show= false; //until we implement the common scenario
		}
		if(show) {
				var beepOwner = ''+ self.model.get('phaseAlternativeOwner').get('name') +' ('+ self.model.get('name') +')';
				if(designModel.get('beepType') === 'report_BeepReport' || designModel.get('beepType') === 'dashboard_Analytics'){
					beepOwner = 'Common';
					own = true;
					continue;//not showing report
				}
				/if(designModel.get('beepType') !== 'vdml_ValueDeliveryModel'){
					continue;
				}/
				var obj = {seq:self.htmlEscape(designModel.get("beepReference")),name:designModel.get("name"),originalId:designModel.get("beepReference"),check:true,own:own,owner:beepOwner,isMain:designModel.get("isMain"),enableMain:enableMain};
				self.packages.push(obj);
			}
		}
	};
	fetchPreviousPlanAlternativesPackages(preAlternatives){
		var self = this;
		var preAlternativesPackagelist = preAlternatives.fetchNonCommonPackagesFromAlternative();
		for(var i=0;i<preAlternativesPackagelist.length;i++){
			if(preAlternativesPackagelist.at(i).get('beepType') === 'dashboard_Analytics' || preAlternativesPackagelist.at(i).get('beepType') === 'report_BeepReport'){
				continue;
			}
			var packpresent = false;
			var revisionExists = false;
			var beepOwner = ''+ preAlternatives.get('phaseAlternativeOwner').get('name') +' ('+ preAlternatives.get('name') +')';
			for(var k=0;k<self.packages().length;k++){
				var packageScenarioSuffix = self.packages()[k].originalId.substr(self.packages()[k].originalId.lastIndexOf('@')+1);
				var masterSuffix = preAlternativesPackagelist.at(i).get('beepReference').substr(preAlternativesPackagelist.at(i).get('beepReference').lastIndexOf('@')+1);
				if(masterSuffix === packageScenarioSuffix){
					revisionExists = true;
				}
				if(preAlternativesPackagelist.at(i).get('beepReference') === self.packages()[k].originalId) {
					self.packages()[k].own = false;
					var packAltId = DataManager.getDataManager().getRepositoryId(self.packages()[k].originalId);
					if(packAltId !== preAlternatives.get('id')){
						var packOwner = self.fetchPackageOriginalOwner(packAltId);
						beepOwner = ''+ packOwner.get('phaseAlternativeOwner').get('name') +' ('+ packOwner.get('name') +')';
					}
					self.packages()[k].owner = beepOwner;
					packpresent = true;
					break;
				}
			}
			/if(preAlternativesPackagelist.at(i).get('beepType') !== 'vdml_ValueDeliveryModel'){
				continue;
			}/
			if(!packpresent && !revisionExists){
				var obj = {seq:self.htmlEscape(preAlternativesPackagelist.at(i).get("beepReference")),name:preAlternativesPackagelist.at(i).get("name"),originalId:preAlternativesPackagelist.at(i).get("beepReference"),check:false,own:false,owner:beepOwner,isMain:preAlternativesPackagelist.at(i).get("isMain"),enableMain:false};
				self.packages.push(obj);
			}
		}
	};
	fetchMasterPackages(){
		var self = this;
	    var master = self.model.get('phaseAlternativeOwner').get('master');
	    if (!master) {
	        return;
	    }
		var masterPackages = master.get('phaseDesignPart');
		for(var j=0;j<masterPackages.length;j++){
			var packageExists = false;
			var revisionExists = false;
			for(var k=0;k<self.packages().length;k++){
				var packageScenarioSuffix = self.packages()[k].originalId.substr(self.packages()[k].originalId.lastIndexOf('@')+1);
				var masterSuffix = masterPackages.at(j).get('beepReference').substr(masterPackages.at(j).get('beepReference').lastIndexOf('@')+1);
				if(masterSuffix === packageScenarioSuffix){
					revisionExists = true;
				}
				if(self.packages()[k].originalId === masterPackages.at(j).get('beepReference')) {
					packageExists = true;
					self.packages()[k].own = false;
					var packAltId = DataManager.getDataManager().getRepositoryId(self.packages()[k].originalId);
					var beepOwner = ''+ master.get('phaseAlternativeOwner').get('name') +' ('+ master.get('name') +')';
					if(packAltId !== master.get('id') && masterPackages.at(j).get('beepType') !== 'report_BeepReport' && masterPackages.at(j).get('beepType') !== 'dashboard_Analytics'){
						var packOwner = self.fetchPackageOriginalOwner(packAltId);
						beepOwner = ''+ packOwner.get('phaseAlternativeOwner').get('name') +' ('+ packOwner.get('name') +')';
					}/else {
						beepOwner = 'Common';
					}/
					self.packages()[k].owner = beepOwner;
					break;
				}
			}
			if(!packageExists && !revisionExists){
				if(masterPackages.at(j).get('beepReference').indexOf("Common") > -1 /&& masterPackages.at(j).get('beepType') !== 'report_BeepReport'/){
					continue;
				}
				if(masterPackages.at(j).get('beepType') !== 'vdml_ValueDeliveryModel'){
					continue;
				}
				else {
					var obj = {seq:self.htmlEscape(masterPackages.at(j).get('beepReference')),name:masterPackages.at(j).get('name'),originalId:masterPackages.at(j).get('beepReference'),check:false,own:false,owner:master.get('name'),isMain:true,enableMain:false};
					self.packages.push(obj);
				}
			}
		}
		
	};	
	fetchPackageOriginalOwner(packAltId){
		var self = this;
		var presentPhase = self.model.get('phaseAlternativeOwner');
		var altfound = null;
		function fetchAltId(prevPhase, packAltId){
			var prevAlternatives = prevPhase.get('phaseAlternative');
			for(var i=0;i<prevAlternatives.length;i++){
				if(prevAlternatives.at(i).get('id') === packAltId){
					altfound = prevAlternatives.at(i);
					break;
				}
			}
			if(!altfound) {
				prevPhase = prevPhase.get('previousPhase');
				prevPhase && fetchAltId(prevPhase,packAltId);
			}
			
		}
		presentPhase && fetchAltId(presentPhase,packAltId);
		return altfound;
	};
	fetchNestedDependentPackage(pack,depPackArray) {
		var self = this;
		var depPackage = pack.get('dependentPackage');
		for(var i=0;i<depPackage.length;i++){
			if(depPackArray.indexOf(depPackage.at(i).id) === -1) {
				depPackArray.push(depPackage.at(i).id);
				self.fetchNestedDependentPackage(depPackage.at(i),depPackArray);
			}
		}
		return depPackArray;
	};				                    			
	toggleAssociationHandler(view,event) {
		var self = this;
		if(view.check) {
			var packAltId = DataManager.getDataManager().getRepositoryId(view.originalId);
			var packageAlt = self.fetchPackageOriginalOwner(packAltId);
			window.utils.startSpinner('loadPackageSpinner', "loading Package...");
			setTimeout(function(){
				packageAlt.getBeepPackageWithId(view.originalId,'vdml_ValueDeliveryModel',function(beepPackage){
					if(beepPackage) {
						if(beepPackage.get('type') === "ecomap_EcoMap"){
							var depPackages = beepPackage.get('dependentPackage');
							var phaseDesignPart = self.model.get('phaseDesignPart');
							var found = true;
							for(var i=0;i<depPackages.length;i++){
								if(!phaseDesignPart.findWhere({'beepReference':depPackages.at(i).id})){
									found = false;
									break;
								}
							}
							if(found){
								self.updateDependencyList(view.originalId,false);
							}
						}else {
							var depPackArray = [beepPackage.id];
							self.fetchNestedDependentPackage(beepPackage,depPackArray);
							for(var i=0;i<depPackArray.length;i++){
								$("#package"+self.htmlEscape(depPackArray[i])).prop("checked", true);
								self.updateDependencyList(depPackArray[i],false);
							}
							$("#main"+view.seq).removeAttr("disabled");
						}						
					}
					window.utils.stopSpinner('loadPackageSpinner');
				});
			},10);
		}
		else {
			if(view.originalId.indexOf("Common") > -1) {
				self.updateDependencyList(view.originalId,true);
			}else {
				var packAltId = DataManager.getDataManager().getRepositoryId(view.originalId);
				var packageAlt = self.fetchPackageOriginalOwner(packAltId);
				var refNextAlts = self.model.getAlternativesWhereICanBeReffered();
				window.utils.startSpinner('loadPackageSpinner', "loading Package...");
				setTimeout(function(){
					packageAlt.getBeepPackageWithId(view.originalId,'vdml_ValueDeliveryModel',function(beepPackage){
						if(beepPackage){
							var planDep = false;
							var acrossPhaseDep = false;
							var depPackagesList = [];
							var alts = packageAlt.getAlternativesWhereICanBeReffered();
							async.eachSeries(alts,function(alt,handleAlt){
								DataManager.getDataManager().getWorkspaceDataWithId(alt.id,function(wsData){
									handleAlt();
								});	
							},function(){
								var phaseAlts = self.model.get('phaseAlternativeOwner').get('phaseAlternative');
								var isPrimary = false;
								if(self.model.get('phaseAlternativeOwner').get('primary') === self.model){
									isPrimary = true;
								}
								DataManager.getDataManager().getReferencesToModel2(beepPackage,function(result){
		                    		var len = result.length;
						        	while(len--){
						        		if(result[len].predicate === "transformation_ScenarioProxy-scenario" || result[len].predicate === "transformation_PackageReference-beepReference") {
					                        result.splice(len,1);
					                    }else if(/result[len].type === 'vdml_EcoMapDiagram' && /beepPackage.get('type') === 'ecomap_EcoMap'){
						                    	/var diagrams = beepPackage.get('diagrams').concat();
						                    	var ecoMapFound = _.filter(diagrams,function(obj){return  obj.id === result[len].id;});
				                    			if(ecoMapFound){/
				                    				result.splice(len,1);
				                    			//}
					                    }else {
					                    	var resultPrefix = DataManager.getDataManager().getRepositoryId(result[len].id);
					                    	if(resultPrefix === window.plansKey){
					                    		planDep = true;
					                    	}else{
					                    		if(!isPrimary && !phaseAlts.findWhere({'id':resultPrefix})){
					                    			result.splice(len,1);
					                    		}
					                    		else if(result[len].predicate === "beeppackage_BeepPackage-dependentPackage"){
					                    			if(result[len].type === "ecomap_EcoMap"){
					                    				result.splice(len,1);
					                    			}else {
					                    				depPackagesList.push(result[len].id);
					                    			}
					                    			function checkPacExistsInNextPhases(altId){
					                    				var cond = false;
						                    			for(var i=0;i<refNextAlts.length;i++){
						                    				if(refNextAlts[i].id === altId){
						                    					cond = true;
						                    					break;
						                    				}
						                    			}
						                    			return cond;
					                    			}
					                    			if(resultPrefix !== packAltId && checkPacExistsInNextPhases(resultPrefix)){
					                    				acrossPhaseDep = true;
					                    			}else {
					                    				var depPackArray = [beepPackage.id];
					                    				function checkNestedDependentPackage(pack) {
						                    				var depPackage = pack.get('dependentPackage');
						                    				for(var i=0;i<depPackage.length;i++){
						                    					var pacPrefix = DataManager.getDataManager().getRepositoryId(depPackage.at(i).id);
							                    				if(pacPrefix !== resultPrefix){
							                    					if(checkPacExistsInNextPhases(pacPrefix)){
							                    						acrossPhaseDep = true;
							                    						break;
							                    					}
							                    				}
							                    				if(depPackArray.indexOf(depPackage.at(i).id) === -1 && !acrossPhaseDep && depPackage.at(i).get('type') !== "ecomap_EcoMap") {
							                    					depPackArray.push(depPackage.at(i).id);
							                    					depPackagesList.push(depPackage.at(i).id);
							                    					checkNestedDependentPackage(depPackage.at(i));
							                    				}
							                    			}
						                    			}
						                    			if(beepPackage.get('type') !== "ecomap_EcoMap"){
						                    				checkNestedDependentPackage(beepPackage);
						                    			}
					                    			}
					                    		}
					                    	}
					                    }
						        	}
						        	window.utils.stopSpinner('loadPackageSpinner');
					                if(result.length==0) {
					                	self.updateDependencyList(view.originalId,true);
				                	}else{
				                		depPackagesList = _.uniq(depPackagesList);
										if(depPackagesList.length > 0 && !planDep && !acrossPhaseDep){
											var message = DataManager.getDataManager().get('localeManager').get('DependenciesCheck',beepPackage.get('name'));
											bootbox.confirm(message, function(result) {
												if(result){
													var refAlts = self.model.getAlternativesThatICanReffer();
													for(var i=0;i<depPackagesList.length;i++){
														var depId = depPackagesList[i];
														var depAltId = DataManager.getDataManager().getRepositoryId(depId);
														for(var j=0;j<refAlts.length;j++){
						                    				if(refAlts[j].id === depAltId){
						                    					self.updateDependencyList(depId,true);
																$("#package"+self.htmlEscape(depId)).prop("checked", false);
						                    					break;
						                    				}
						                    			}
													}
													self.updateDependencyList(view.originalId,true);
												}else {
													$("#package"+view.seq).prop("checked", true);
												}
											});
										}else {
											bootbox.alert(DataManager.getDataManager().get('localeManager').get('DependenciesBlock'));
		        				 			$("#package"+view.seq).prop("checked", true);
										}  
				                	}
                                }, null,'vbc:cmof_EObject-name');
			                });
						}else {
							window.utils.stopSpinner('loadPackageSpinner');
						}
						
					});
				},10);
			}
		}
		return true;		 
			       
    };
    updateDependencyList(packId,uncheck){
    	var self = this;
    	var view = null;
    	for(var i=0;i<self.packages().length;i++) {
			if(self.packages()[i].originalId === packId){
				if(uncheck){
					self.packages()[i].check = false;
				}
				view = self.packages()[i];
				break;
			}
		}
		if(view){
			for(var i=0;i<self.selectedPackage().length;i++) {
				if(self.selectedPackage()[i].originalId === packId){
					self.selectedPackage.replace(self.selectedPackage()[i], view);
					break;
				}
				else {
					if(!uncheck){
						view.check = true;
					}
					self.selectedPackage.push(view);
				}
			}
			if(self.selectedPackage().length < 1) {
				self.selectedPackage.push(view);
			}
			$("#main"+view.seq).attr("disabled", true);
		}
    };*/
    deleteAlternative(){
    	var self = this;
    	function doDelete(){
	    	if(self.parentView){
				return self.options.callback(self,self.encodeId,true);
			}
		}
		var phase = self.model?self.model.get('phaseAlternativeOwner'):null;
		if(self.model && phase && phase.get('master') !== self.model && phase.get('primary') !== self.model){
			var packagesColl = self.model.fetchNonCommonPackagesFromAlternative();
			if(packagesColl.length > 0){
					bootbox.confirm(DataManager.getDataManager().get('localeManager').get('DeleteAlternative'), function(result) {
	                if(result){
	                    setTimeout(doDelete,0);
	                }
	            });
			}
			else{
				 setTimeout(doDelete,0);
			}
		}
		else{
			setTimeout(doDelete,0);
		}
    }; 
    saveAlternativeDetails(){
    	var self = this;
		if(self.parentView){
			if(self.name() !== null && self.name() !== "") {
				return self.options.callback(this,self.encodeId,false);
			}
		}
	};
	/*this.reGenerateRDF = function(){
		self.model.reGenerateRDF(function(){
			bootbox.alert("Done re-generating RDF for Alternative");
		});	
	}*/
	checkAlternativeNameUnique(name){
		var self = this;
		if(self.options.addOptions.alternatives){
			var alternatives = self.options.addOptions.alternatives;
			for(var i=0;i<alternatives.length;i++){
				if(alternatives[i].name === name.trim() && alternatives[i].modalId!==self.encodeId){
					return false;
				}
			}
			return true;
		}
	};
	showDirectValues(){
		var self = this;
		var id = self.encodeId + "alternativeInputValues";
		var planModel = self.options.addOptions.planModel;
    	var addOptions = {'parentModal':self.parentModal,'planModel':planModel,'altName':self.name(),'width':'690px','cloneId':self.cloneId};
    	if($.find("#modal"+id).length > 0){
			$("#modal"+id).modal('show');
		}
		else {
			// var AlternativeMixin = Backbone.Relational.store.getObjectByName("transformation.AlternativeMixin");
			window.getAndCreateModalDialog(true,id,self.model.getMixinClass(),self.model,"AlternativeInputValues",function(view,encodeId){
                self.inputValuesView = view;
			},addOptions);
		}
	};
	fillDerivedFromText(){
		var self = this;
		if(self.model){
			self.deriveFromVisible(false);
			self.derivedFrom(true);
		}
		const derivedFromId = self.model ? self.model.get('clonedFrom') : null;
    	if (derivedFromId) {
			var derivedFromObj = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: self.model.get('clonedFrom')});
    	    self.derivedFromText(derivedFromObj.get('name'));
    	} else {
    	    self.derivedFrom(false);
    	}
	}
	cleanModal(){
		var self = this;
		/*if(self.parentView && self.encodeId){
			window.cleanDialogModel(self.encodeId,self);
		}*/
		if(self.modalShown == 1){
	    	window.cleanDialogModel(self.encodeId,self);
			var inputValuesView = self.inputValuesView;
			if(inputValuesView) {
				var inputValuesEncodeId = inputValuesView.encodeId;
				window.cleanDialogModel(inputValuesEncodeId,inputValuesView);
			}
	    }
	};
	fillDerivedFrom() {
		var self = this;
		var currentPhaseName = '';
		var previousPhaseName;
		var prevPhaseAlts = [];
		var phaseAlts;
		var alternatives = [];
		if(self.parentModal){
			currentPhaseName = self.parentModal.get('name');
			phaseAlts = self.parentModal.get('phaseAlternative').models;
			if(self.parentModal.get('previousPhase')!==null){
				previousPhaseName = self.parentModal.get('previousPhase').get('name');
				prevPhaseAlts = self.parentModal.get('previousPhase').get('phaseAlternative').models;
			}
			alternatives = [...phaseAlts, ...prevPhaseAlts];
		}
		else if (self.options.addOptions.prevPhase){
			var prevPhase = self.options.addOptions.prevPhase;
			previousPhaseName = prevPhase.get('name');
			prevPhaseAlts = prevPhase.get('phaseAlternative').models;
			alternatives = [...prevPhaseAlts];
		}
		var nameCount = {};

		alternatives.forEach(alt => {
			const name = alt.get('name');
			if (name) {
				nameCount[name] = (nameCount[name] || 0) + 1;
			}
		});
		var alternativeText = alternatives.map(alt => {
			const altName = alt.get('name');
			const isDuplicate = nameCount[altName] > 1;
			const id = alt.get('id');
			let displayText = altName;
        	if (isDuplicate) {
        	    if (phaseAlts.includes(alt) && currentPhaseName) {
        	        displayText = `${altName} [${currentPhaseName}]`;
        	    } else if (!phaseAlts.includes(alt)) {
        	        displayText = `${altName} [${previousPhaseName}]`;
        	    }
        	}
			return {
				id: id,
				text: displayText
			};
		});
		self.alternativeOptions(alternativeText);
	}
	afterRenderView(node,view){
		var self = view;
		if(self.options.addOptions.createNewAlternative){
        	$("#titleLabel"+self.encodeId).text(''+DataManager.getDataManager().get('localeManager').get('CreateAlternative'));
        }
        else {
        	$("#titleLabel"+self.encodeId).text(''+DataManager.getDataManager().get('localeManager').get('EditAlternative'));
        }
		$("#alternativeName"+self.encodeId).keyup(function (view) {
            var newValue = view.currentTarget.value;
            if(newValue.trim()!== '' && self.checkAlternativeNameUnique(newValue)) {
            	self.enableComplete(true);
            	self.showWarningDuplicateName(false);
            	$('#message'+self.encodeId).text("");
            }
            else{
            	self.enableComplete(false);
            	self.showWarningDuplicateName(true);
            	if(newValue.trim() !== ''){
            		$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('AlternativeDuplicate'));
            	}
            }
        });
        window.utils.applyFontSizeInDialog();
	};
	init(model,options,detailedView){
		var self = this;
		this.AlternativeDetailsViewModel = this;
		this.model = model;
		this.options = options;
		this.id = kb.observable(model, 'id');
		this.packages = ko.observableArray();
		this.values = ko.observableArray();
		this.showValuesButton = ko.observable(true);
		this.deriveFromVisible = ko.observable(true);
		this.derivedFrom = ko.observable(false);
		this.derivedFromText = ko.observable('');
		//this.directValueChanges = [];
		this.encodeId = self.htmlEscape(this.id());
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.parentModal = options.parentModal;
			if(!options.addOptions.planModel && !options.addOptions.parentModal && !this.model){
				self.showValuesButton(false);
			}
		}
		this.version = kb.observable(model, 'version');
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description =ko.observable(model ? model.get('description') : "");
		this.purpose = ko.observable(model ? model.get('purpose') : "");
		this.selectedPackage = ko.observableArray();
		this.enableComplete = ko.observable(false);
		this.showDelete = ko.observable(false);
		this.showWarningDuplicateName = ko.observable(false);
		this.cloneId = null;
		if(self.encodeId.indexOf('BaseAlternative') > -1){
			self.name('Base Alternative');
			self.description('Base Alternative');
		}
		var modalShown = 0;
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
			window.utils.focusElement("#newModal .focus-ele");
			if(self.name() && self.name!==null && !self.options.addOptions.alternativesCustom){				
				self.showDelete(true);
			}
			modalShown++;
			self.modalShown = modalShown;
		});
		if(self.name() && self.name!==null){
			self.enableComplete(true);
		}
		if(!options.addOptions.createNewAlternative){//when clicked on Alternative  edit
			self.showDelete(true);
		}
		if(self.options.addOptions.alternativesCustom){
			self.showDelete(false);
		}
    	this.selectedAlternative = ko.observable();
		this.alternativeOptions = ko.observableArray([]);
		this.fillDerivedFrom();
		this.fillDerivedFromText();
		this.selectedAlternative.subscribe(function(newValue) {
			if (newValue) { 
				var selectedAltId = self.selectedAlternative();
				if (!selectedAltId) return;
				self.cloneId = selectedAltId; 
			}
		});
		if(self.alternativeOptions().length==0){
			self.deriveFromVisible(false);
		}
		
		/*if(model){
			self.fetchAlternativePackages();
			var master = self.model.get('phaseAlternativeOwner').get('master');
			if(model !== master){
				self.fetchMasterPackages();	
			}		
			else {
				var prevphase = self.model.get('phaseAlternativeOwner').get('previousPhase');
				if(prevphase){
					self.fetchPreviousPlanAlternativesPackages(prevphase.get("primary"));
				}
			}
		}*/
		this.name.subscribe(function(val){
			self.description(val);
		});
		//self.toggleAssociation = _.bind(self.toggleAssociationHandler,self)
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Alternative'
			,'version'
			,'name'
			,'description'
			,'Close'
			,'Complete'
			,'Packages'
			,'Attached'
			,'IsMain'
			,'EditAlternative'
			,'AddAlternative'
			,'Delete'
			,'InputValues'
			,'selectalternative'
			,'DerivedFrom'
			,'DeriveFrom'
			,'Purpose']);
	};
	
	
	static getInstance(model,options){
		var view = new AlternativeDetailsViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.AlternativeDetailsViewModel = AlternativeDetailsViewModel;