import * as $ from 'jquery';
import * as ko from 'knockout';
import * as kb from 'knockback';
import { DataManager } from '../../../../../com/vbee/data/DataManager';

var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties",global.version);

export class DemoCourseViewModel {
    afterRenderView(node,view) {
		var self = view;
		if(self.options.selectedId){
		   $('#CoursesTable').show();
		}
	};

    init(model, options) {
		var self = this;
		this.DemoCourseViewModel = this;
		this.model = model;
		this.options = options;
		this.labels = {
			Close: "Close"
		};
		this.iframeSrc = ko.observable('http://localhost:8089/mod/scorm/player.php?a=2&currentorg=articulate_rise&scoid=4&display=popup&mode=browse');
	}

    cleanDemoModal(){
		var self=this;
		window.cleanDialogModel(self.encodeId,self);
	};  

    static getInstance(model, options) {
        const view = new DemoCourseViewModel(model, options);
		view.init(model, options);
        return view;
    }
}
path.DemoCourseViewModel = DemoCourseViewModel;