
//import * as require from 'require'
import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import {Tag} from './Tag'
//import {Element} from './Element'
//import * as  backboneLawnchair from 'backbonelawnchair'

var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);
export class EObjectMixin {
	
	defaults(){
		var ret = {
			type: "cmof_EObject",
			id:DataManager.getDataManager().guidGenerator()
		}
		return ret;
	}
	static getSubModelTypes(){
		return {
			beeppackage_BeepPackage : 'beeppackage.BeepPackage',
			cmof_Element : 'cmof.Element',
			cmof_Tag : 'cmof.Tag',
			preference_UserPreferences : 'preference.UserPreferences',
			preference_Preference : 'preference.Preference',
			smm_SmmElement : 'smm.SmmElement',
			tickets_Ticket : 'tickets.Ticket',
			tickets_Comment : 'tickets.Comment',
			tickets_User : 'tickets.User',
			tickets_Solution : 'tickets.Solution',
			tickets_Vote : 'tickets.Vote',
            transformation_NamedPlanElement: 'transformation.NamedPlanElement',
            transformation_ScenarioProxy: 'transformation.ScenarioProxy',
			vdml_Attribute : 'vdml.Attribute',
			vdml_VdmlElement : 'vdml.VdmlElement',
			vdml_Annotation : 'vdml.Annotation',
			vdml_ObjectReference : 'vdml.ObjectReference',
			concept_ConceptElement : 'concept.ConceptElement',
			beeppackage_CodeContainer : 'beeppackage.CodeContainer',
		}
	}
	static getMixinRelations(){
		return [
			{
				type :Backbone.HasMany,
				containingClass:"cmof_EObject",
				key:"tag",
				relatedModel:"cmof.Tag",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}/*,
			{
				type :Backbone.HasMany,
				containingClass:"cmof_EObject",
				key:"assignedTicket",
				relatedModel:"tickets.Ticket",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}*/
		]
	}
	
	static getCumulativeMixinRelations(){
		return EObjectMixin.getMixinRelations()
	}
	static getSuperTypes(){
		return [];
	}
	static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" }
        ];
    }
    calculateProgress() {

    }
	getParentsHierarchy(){
		var self = this;
		var parents = new Backbone.Collection();
		var parent = self.getParent();
		while(parent){
			if(parent !== self){
                parents.push(parent, {silent:true});	
			}
			var parentsParent = parent.getParent();
			if(parentsParent !== parent){
				parent = parentsParent;
			}else{
				break;
			}
		}
		return parents;
	};
	getParent(){
		return this;
	};
	getNestedParent(){
		var self = this;
		var parent = self.getParent();
		while(parent){
			var parentsParent = parent.getParent();
			if(parentsParent !== parent ){
				parent = parentsParent;
			}else{
				break;
			}
		}
		return parent;
	};
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/cmof/views/properties/EObjectPropertiesTemplate.html",
			templateName : "EObjectPropertiesTemplate",
			viewTypeStr : "appviews/cmof/views/properties/EObjectViewModel",
			tabId : "EObjectView",
			tabName: "EObject"
		}
	}
//#startCustomMethods	
    isPartOfRelation(typeMixinCls,property){
    	if(!typeMixinCls){
    		return false;
    	}
    	var relations = typeMixinCls.getCumulativeMixinRelations();
    	for(var i=0;i<relations.length;i++){
    		if(relations[i].key === property){
    			var includeInJSON = relations[i].includeInJSON;
    			if(!includeInJSON || includeInJSON === true){
    				return true;
    			}else{
    				return false;
    			}
    		}
    	}
    	return false;
    };
    getContainedModelsOneLevel(isDestroyed){
		var containedModels = [];
		var self = this;
		var typeMixinCls = DataManager.getDataManager().getModelTypeByTypeStr(self.get('type'),self.getNestedParent().get('version'),true );
		if(!typeMixinCls){
			return containedModels;
		}
		var relations = typeMixinCls.getCumulativeMixinRelations();
		_.each(relations,function(relation){
			var isPartOfRelation = self.isPartOfRelation(typeMixinCls,relation.key);
			if(isPartOfRelation){
				var relatedModel;
				if(isDestroyed){
					relatedModel = self.previousAttributes()[relation.key];	
				}else{
					relatedModel = self.get(relation.key);	
				}
				if(relatedModel){
					if(relatedModel instanceof Backbone.Model){
						containedModels.push(relatedModel);
					}
					else{
						_.each(relatedModel.models,function(model){
								containedModels.push(model);	
						});
					}
				}
			}
		});
		return containedModels;
    };
    
    getContainedModels(isDestroyed){
		var containedModels = [];
		var self = this;
		var typeMixinCls = DataManager.getDataManager().getModelTypeByTypeStr(self.get('type'),DataManager.getDataManager().get('currentVDMVersion'),true );
		if(!typeMixinCls){
			return containedModels;
		}		
		var relations = typeMixinCls.getCumulativeMixinRelations();
		_.each(relations,function(relation){
			var isPartOfRelation = self.isPartOfRelation(typeMixinCls,relation.key);
			if(isPartOfRelation){
				var relatedModel;
				if(isDestroyed){
					relatedModel = self.previousAttributes()[relation.key];	
				}else{
					relatedModel = self.get(relation.key);	
				}
				
				if(relatedModel){
					if(relatedModel instanceof Backbone.Model){
						if(relatedModel.getContainedModels){
							var subModels = relatedModel.getContainedModels();
							containedModels.push(relatedModel);
							containedModels = containedModels.concat(subModels);
						}else{
							containedModels.push(relatedModel);
						}
					}else{
						_.each(relatedModel.models,function(model){
							if(model.getContainedModels){
								var subModels = model.getContainedModels();
								containedModels.push(model);
								containedModels = containedModels.concat(subModels);
							}else{
								containedModels.push(model);	
							}
						});
					}
				}
			}
		});
		return containedModels;
    };
    
    //Called from the sync method of backbone
	invokeDestroyOnContainedModels(callback){
		var deletedModels = [];
		var self = this;
		var typeMixinCls = DataManager.getDataManager().getModelTypeByTypeStr(self.get('type'),self.getNestedParent().get('version'),true );
		if(!typeMixinCls){
			if(callback){
				callback();
				return;
			}
		}		
		var relations = typeMixinCls.getCumulativeMixinRelations();
		_.each(relations,function(relation){
			var isPartOfRelation = self.isPartOfRelation(typeMixinCls,relation.key);
			if(isPartOfRelation){
				var relatedModel = self.get(relation.key);
				if(relatedModel){
					if(relatedModel instanceof Backbone.Model){
						if(relatedModel.invokeDestroyOnContainedModels){
							relatedModel.invokeDestroyOnContainedModels(function(){
								deletedModels.push(relatedModel);
							});
						}else{
							deletedModels.push(relatedModel);
						}
					}else{
						_.each(relatedModel.models,function(model){
							if(model.invokeDestroyOnContainedModels){
								model.invokeDestroyOnContainedModels(function(){
									deletedModels.push(model);
								});
							}else{
								deletedModels.push(model);	
							}
						});
					}
				}
			}
		});
/*		async.each(deletedModels,function(delModel,modelDeleteTriggeredCallback){
			delModel.trigger('destroy',delModel);
			modelDeleteTriggeredCallback();
		},function(err){
			if(callback){
				callback();					
			}
		});*/
		_.each(deletedModels,function(delModel){
			delModel.trigger('destroy',delModel);
		});
		if(callback){
			callback();					
		}
	};
	
	unregisterModel(){
		var self = this;
		var containedModels = self.getContainedModels();
		for(var i=0;i<containedModels.length;i++){
			containedModels[i].lawnchair = null;
    		Backbone.Relational.store.unregister( containedModels[i] );			
		}
		containedModels.length = 0;
		Backbone.Relational.store.unregister(self);	
	};
	
	destroy(options){
		var self = this;
		if(!options){
			options = {};
		}
		//console.log("destroying obj:" + self.get("id"))
		if(!DataManager.getDataManager().deletedData){
			DataManager.getDataManager().deletedData = [];
			DataManager.getDataManager().deletingData = [];
		}
		if(_.indexOf(DataManager.getDataManager().deletingData,self.get('id')) >=0 ){
			if(options.success){
				options.success(self,null,options);
			}
			return;
		}
		var nestedParent = self.get("nestedParent");
		var BeepPackage;
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage", global.version);
		if (importPath.BeepPackage) {
			BeepPackage = importPath.BeepPackage;
		}
		if(!nestedParent ||  !(nestedParent instanceof BeepPackage)){
			nestedParent = self.getNestedParent();
			self.set("nestedParent",nestedParent,{silent:true});
		}
    	var containedModels = self.getContainedModels();
		for(var i=0;i<containedModels.length;i++){
			var containedModel = containedModels[i];
			if(!containedModel._isInitialized){
				containedModel.initializeRelations(options);
			}
			containedModel.set("nestedParent",nestedParent,{silent:true});
		}
		for(var i=0;i<containedModels.length;i++){
			var containedModel = containedModels[i];
			if(!options.silent){
				var containedModelId = containedModel.get('id');
				DataManager.getDataManager().deletingData.push(containedModelId);	
				if(_.indexOf(DataManager.getDataManager().deletedData,containedModelId) >=0 ){
					continue;
				}else{
					DataManager.getDataManager().deletedData.push(containedModelId);
				}
				var prevAttrs = containedModel.previousAttributes();
				if(!prevAttrs || Object.getOwnPropertyNames(prevAttrs).length === 0){
					containedModel._previousAttributes = _.clone(containedModel.attributes);
				}
			}
			if(options.silent){
				Backbone.Relational.store.unregister( containedModels[i] );
			}else{
				containedModels[i].trigger('destroy',containedModels[i]);	
			}
		}
		containedModels.length = 0;
		doDelete();        	
		function doDelete(){
			Backbone.RelationalModel.prototype.destroy.call(self,options);
			DataManager.getDataManager().deletedData.push(self.get("id"))
		}
	};
	
	getUniqueProperty(propertyName,baseValue,relationName){
		var ret = baseValue;
		var count =0;
		var relation = this.get(relationName);
		if(!relation){
			return null;
		}
		var queryObj = {};
		queryObj[propertyName] = ret;
		var found = relation.findWhere(queryObj);
		while(found){
			count++;
			ret = baseValue + count;
			queryObj[propertyName] = ret;
			found = relation.findWhere(queryObj);
		}
		return ret;
	}
	getCalculationChangeType(operationType){

	}
}
//#endCustomMethods
	path.EObjectMixin = EObjectMixin;




