import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
//import { ValueElementMixin } from '../../../../../version1/bo/vdml/ValueElementMixin'
import { DashboardMixin } from '../../../../bo/dashboard/DashboardMixin'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
import {DashboardDetailsViewModel} from './DashboardDetailsViewModel'
import {DashboardScenariosViewModel} from './DashboardScenariosViewModel'
import {DashboardDesignViewModel} from './DashboardDesignViewModel'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {PresentationViewModel} from './PresentationViewModel'
import * as Highcharts from 'highcharts';
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
import * as ReactDOM from 'react-dom';
import * as React from 'react'
import DashboardElement from './DashboardReact'
import NavigationTree from './NavigationTree'

//import { Analytics } from '../../../../../version1/bo/dashboard/Analytics'

/*define(["require", "jquery", "enhsplitter", "underscore","muuri", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/PresentationViewModel", "appbo/dashboard/DashboardMixin", "appviews/dashboard/views/chart/DashboardDetailsViewModel", "appviews/dashboard/views/chart/DashboardScenariosViewModel", "appviews/dashboard/views/chart/DashboardDesignViewModel","appviews/help/views/properties/BMGuidanceViewModel","appbo/beeppackage/CodeContainer","appcommon/com/vbee/utils/HighChartThemes"],
    function (require, $, enhsplitter, _, Muuri, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, PresentationViewModel, DashboardMixin,DashboardDetailsViewModel,DashboardScenariosViewModel,DashboardDesignViewModel,BMGuidanceViewModel,CodeContainer, HighChartThemes)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class DashboardViewModel {
  htmlEscape(str) {
    return String(str).replace(/@/g, "").replace(/ /g, "").replace(/#/g, "");
  }
  cleanUp() {
    var self = window.vdmModelView;
    _.each(this.charts(), function (presentationView) {
      /*var json = JSON.parse(presentationView.model.get('chartConfig'));
			if(presentationView.viewInstance && presentationView.viewInstance.selectedChartType){
				json['selectedChartType'] = presentationView.viewInstance.selectedChartType();
			}
			if(presentationView.viewInstance && presentationView.viewInstance.selectedGroupType){
				json['selectedGroupType'] = presentationView.viewInstance.selectedGroupType();
				//json['savedMinimum'] = Date.parse(presentationView.viewInstance.minimumDate());
				//json['savedMaximum'] = Date.parse(presentationView.viewInstance.maximumDate());
				json['savedInterval'] = presentationView.viewInstance.selectedIntervalValue();
			}
			if(presentationView.viewInstance && presentationView.viewInstance.showValues){
				json['showValues'] = presentationView.viewInstance.showValues();
			}
			presentationView.model.set('chartConfig', JSON.stringify(json));*/
      presentationView.viewInstance &&
        presentationView.viewInstance.cleanUp &&
        presentationView.viewInstance.cleanUp();
      presentationView.cleanUp();
    });
    //jQuery('#summernote').summernote('destroy');
    jQuery("#summernotecss").attr("href", "");
    if (self.modeler) {
      self.modeler.destroy();
    }
    /*if (self.codeContainersList) {
      var codeContainersList = self.codeContainersList;
      _.each(codeContainersList, function (key) {
        CodeContainer.getBlobInstanceWithId(
          key,
          DataManager.getDataManager().get("currentPlan"),
          function (codeContainer) {
            if (
              codeContainer &&
              codeContainer.get("lastModified") > self.viewTime
            ) {
              codeContainer.save(null, {
                success: function () {
                  DataManager.getDataManager().unRegisterCodeContainer(
                    codeContainer
                  );
                },
                error: function (error) {
                  console.log(error);
                  //DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                },
              });
            }
          }
        );
      });
      codeContainersList.length = 0;
    }*/
    jQuery(window, ".resize").unbind("resize");
    if (ko.components.isRegistered("Views")) {
      ko.components.unregister("Views");
    }
    if (ko.components.isRegistered("Scenarios")) {
      ko.components.unregister("Scenarios");
    }
    if (ko.components.isRegistered("DashboardGuidance")) {
      ko.components.unregister("DashboardGuidance");
    }
    if (ko.components.isRegistered("DBDetails")) {
      ko.components.unregister("DBDetails");
    }
    //window.utils.loadTemplate(null,true);
    if (self.spliter) {
      self.spliter.enhsplitter("remove");
    }
    if (self.highchartsDefaultTheme) {
      Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
      Highcharts.setOptions(self.highchartsDefaultTheme);
    }
    //window.chartTemplate = null;
    document.documentElement.style.overflow = "visible"
  }

  toggleTOC() {
    var self = this;
    const RHSSplitterWidth  = document.getElementById("DashboardContainer").parentElement.style.width.replace(/[a-zA-Z]/g,"");
    const navigationWidth = document.getElementById("navbar").style.width.replace(/[a-zA-Z]/g,"");
    const toggleIconWidth = document.getElementById("toggleIcon").style.width.replace(/[a-zA-Z]/g,"");

    if(!self){
      self = window.vdmModelView;
    }
    var bool = false
    if(!self.toc()){
      bool = true;
      document.getElementById("Dashboard").style.width = `${RHSSplitterWidth- +toggleIconWidth}px`
    }else{
      document.getElementById("Dashboard").style.width = `${RHSSplitterWidth- +navigationWidth}px`
    }
    self.toc(bool)
    if(self.treeData!==null) {
      self.eventBus.emit("updatedNav", { component: NavigationTree,treeData:self.treeData });
    }
  }


  handlePublicMenus() {
    DataManager.getDataManager().addContextBarMenu(
      "glyphicon glyphicon-wrench",
      "Show Object Explorer",
      jQuery.proxy(this.showobjectExplorerTree, this)
    );
  }

  openAccordion(phase, alt) {
    var self = this;
    if (!alt) {
      jQuery('.panel-collapse:not(".in")').collapse("show");
    } else {
      jQuery("#collapse" + self.htmlEscape(phase)).addClass("collapse in");
      jQuery("#collapse" + self.htmlEscape(alt)).addClass("collapse in");
    }
  }

  findPresentationIdInFlow(nodes, targetNodeId, chart) {
    var self = this;
    return nodes?.map((node) => {
      if (node.id === targetNodeId) {
        self.chartCollection.push(chart);
      } else if (node.subRows && node.subRows.length > 0) {
        return {
          ...node,
          subRows: this.findPresentationIdInFlow(
            node.subRows,
            targetNodeId,
            chart
          ),
        };
      }
      return node;
    });
  }

  mapping = {
    "length":"h",
    "border":"b"
  }

  JSONMigration(obj) {    
    var self = this;
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(item => self.JSONMigration(item, self.mapping));
    }

    const newObj = {};
    for (let key in obj) {
        const newKey = self.mapping[key] || key;
        newObj[newKey] = self.JSONMigration(obj[key], self.mapping);
    }
    return {...newObj,uId:window.uuidGenerator()};
  }

  renderNewPresentation(chart,parsedArr){
    var self = this;
    if(!parsedArr){
      var treeStructure = self.model.get("treeStructure");
      if (treeStructure !== undefined && treeStructure !== null) {
        parsedArr = JSON.parse(treeStructure);
      }
    }
    var chartBBModel = new Backbone.Model({
      id: chart.id,
      name: chart.get("name"),
      parent: chart.getNestedParent().id,
      description: chart.get("description"),
      presentationType: chart.get("presentationType"),
    });
    self.findPresentationIdInFlow(parsedArr, chart.id, chartBBModel);   
  }

 renderPresentation(id) {
    var self = this;
    var charts = self.model.get("presentation").models.concat();
    var treeStructure = self.model.get("treeStructure");
    var parsedArr;
    if (treeStructure !== undefined && treeStructure !== null) {
      parsedArr = JSON.parse(treeStructure);
    }
    for (var i = 0; i < charts.length; i++) {
      var chart = charts[i];
      if (chart) {
        if (id) {
          if (id === chart.id) {
            var chartObj = window.vdmModelView.charts().some(d=>d.id()===id)
            if(chartObj){
              var presentationView = _.filter(window.vdmModelView.charts(), function(chartView){return chartView.id() === id})[0];
              if(presentationView && presentationView.viewInstance){
                presentationView.viewInstance.initializePresentation(true);
                break;
              }
            }else{
              self.renderNewPresentation(chart,[{...parsedArr}])
            }            
          }
        } else {
          self.renderNewPresentation(chart,[{...parsedArr}])         
        }
      }
    }

  }

  dragHandler(e,splitter_container,navWidth,iconWidth){
    var self =  this;
    window.utils.splitterBoundary(e,splitter_container);
    let LHSWidth
    if(!self.toc()){
      LHSWidth = splitter_container.currentPosition - +navWidth;
    }else{
      LHSWidth = splitter_container.currentPosition- +iconWidth
    }
    document.getElementById("Dashboard").style.width = `${LHSWidth}px`
    if(splitter_container.currentPosition<=230){
      self.toc(true);
    }
  }

   handleIntialDashContent(splitter_container,navWidth,iconWidth){
    var self = this;
    let LHSWidth
    if(!self.toc()){
      LHSWidth = splitter_container - +navWidth;
    }else{
      LHSWidth = splitter_container - +iconWidth
    }
    document.getElementById("Dashboard").style.width = `${LHSWidth}px`
   }

  afterRenderView() {
    var self = window.vdmModelView;
    var dash = self.model;
    var treeData = dash.get("treeStructure");
    var parsedTreeData = treeData ? JSON.parse(treeData) : [];
       
    const parentPresentationWidth = document.getElementById("Dashboard").parentElement.style.width;
    document.getElementById("Dashboard").style.width = parentPresentationWidth
    document.documentElement.style.overflow = "hidden"

    const navigationWidth = document.getElementById("navbar").style.width.replace(/[a-zA-Z]/g,"")
    const toggleIconWidth = document.getElementById("toggleIcon").style.width.replace(/[a-zA-Z]/g,"")

    self.spliter = jQuery("#splitPanels").enhsplitter({
      minSize: 1,
      onDrag:(e,splitter_container)=>{self.dragHandler(e,splitter_container,navigationWidth,toggleIconWidth)} ,
      collapse: "right",
      position: "60%",
      handle: "block",
      splitterSize: "9px",
      height: jQuery("#splitPanels").height() - 10,
      onDragEnd: self.reIntializePresentation,
    });
      /*$('.splitter_handle').click(function(){
        setTimeout(() => {
          self.reIntializePresentation();
      }, 10);
    });*/

    var timeDelay = 100;
    // var charts = self.model.get("presentation").models.concat();
    if (parsedTreeData.subRows.length > 0 ) {
      if(self.spliter){
        self.spliter.enhsplitter("collapse");
      }
      timeDelay = 500;
        /*$('.splitter_handle').click(function(){
        setTimeout(() => {
          self.reIntializePresentation();
      }, 10);
    });*/}

    $('#DashboardView').on('click', function (e) {
      var presentations = window.vdmModelView.model.get("presentation").models.concat()
      for (let i=0;i<presentations.length;i++){
        if (!e.target.closest('.dropdown-menu-right') && !(e.target.id === 'dropdown' + +window.utils.htmlEscape(presentations[i].id))) {
            $("#presWrapper"+window.utils.htmlEscape(presentations[i].id)).css('overflow','hidden');
            $("#dropdownMenu" + +window.utils.htmlEscape(presentations[i].id)).hide();
        }
      }
    });
    const splitterContainer = jQuery("#splitPanels");
    const leftPanelWidth = splitterContainer.children().first().width();

    self.handleIntialDashContent(leftPanelWidth,navigationWidth,toggleIconWidth)
    setTimeout(() => {
      try {
        //self.refreshWidgetData(false);
        if (parsedTreeData.length > 0) {
          self.renderPresentation("", parsedTreeData);
        } else {
          self.renderPresentation("", []);
        }

        var timeStampArray = self.fetchDashboardTimeStampsList();
        if (timeStampArray.length > 0) {
          //window.utils.startSpinner('updateScenario','Updating Scenarios ...');
          //setTimeout(function(){
          var ObservationMixin = Backbone.Relational.store.getObjectByName(
            "smm.ObservationMixin"
          );
          ObservationMixin.updateObservations(timeStampArray, function () {
            //window.utils.showSpinnerText('Saving Scenario...');
            DataManager.getDataManager().invokeValueUpdates(function () {
              setTimeout(function () {
                self.initializeGrid();
                //self.setResizeHeight(); // zoom is removed
              }, timeDelay);
            });
          });
          //}, 100);
        } else {
          setTimeout(self.initializeGrid, timeDelay);
          //self.setResizeHeight(); // zoom is removed
        }
        //});
        //self.setResizeHeight(true);// true to refresh on zoom... else not required.
        //self.openAccordion(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'));
      } catch (e) {
        console.log(e);
      }
   }, 10);
    jQuery("#rightTab a").click(function (e) {
      e.preventDefault();
      if (jQuery(this).text() === "Collaboration") {
        return false;
      }
      jQuery(this).tab("show");
      if (jQuery(this).text() === "Guidance") {
        if (jQuery("#guidanceTab").is(":empty")) {
          var viewStr = window.utils.getHtmlPage("DashboardGuidanceTemplate");
          //jQuery.get('js/app/version1/views/help/views/properties/DashboardGuidanceTemplate.html',function(viewStr){
          jQuery("#guidanceTab").empty();
          jQuery("#guidanceTab").append(viewStr);
          window.utils.applyFontSize(jQuery("#guidanceTab"));
          //})
        }
      } else if (jQuery(this).text() === "Scenarios") {
        if (self.importData) {
          self.dashboardScenariosViewModel.fillAlternatives();
          self.importData = false;
        }
        self.openAccordion(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'));
      } else if(jQuery(this).text() === "Details"){
        if (self.dashboardDetailsViewModel) {
            self.dashboardDetailsViewModel.selectedValues.removeAll();
            self.dashboardDetailsViewModel.loadTableData();
            self.dashboardDetailsViewModel.displayTable();
        }
      }
      self.setResizeHeight();
    });
    //jQuery('.bmArea').animate({'zoom':0.80},400);
    /*jQuery(window).resize(function (view, event) {
      if (!event) {
        this.timer = clearTimeout();
        this.timer = setTimeout(function () {
          self && self.setResizeHeight && self.setResizeHeight(true);
        }, 200);
      }
    });*/

    const dialogConfig = {
      title: "<div><img class='smallIcon' src='img/icons/icons_22.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('DashboardArrowVideo')+"</div>",
      videos: [
          { id: 'NVRfJQzZnt8', title: '', width: '25vw', height: '14vw',description: DataManager.getDataManager().get('localeManager').get('DashboardDescription') },
      ],
    };
    DataManager.getDataManager().handleVideoClick(dialogConfig,'DashboardVideo');
  };
  showobjectExplorerTree() {
    var self = this;
    var addOptions = this;
    var dashboard = self.model;
    function closeView() {
      window.cleanDialogModel(self.encodeId, DashboardMixin);
    }
    window.getAndCreateModalDialog(
      true,
      self.htmlEscape(self.model.id),
      DashboardMixin,
      dashboard,
      "explorerModelJson",
      closeView,
      addOptions
    );
  }

  getViews() {
    var self = this;
    self.updateHtmlPresentation = function (
      nodes,
      targetNodeId,
      htmlPresentation
    ) {
      return nodes.map((node) => {
        if (node.presentation.id === targetNodeId) {
          return { ...node, htmlPresentation };
        }

        if (node.subRows && node.subRows.length > 0) {
          return {
            ...node,
            subRows: self.updateHtmlPresentation(
              node.subRows,
              targetNodeId,
              htmlPresentation
            ),
          };
        }
        return node;
      });
    };
    var treeStructure = self.model.get("treeStructure");

    if (typeof treeStructure === "string") {
      try {
        var arr = JSON.parse(treeStructure);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.warn("treeStructure is not a valid JSON string");
    }
    jQuery(".grid-item").each(function (index, item) {
      var gridViewEle = jQuery(this).children().eq(0);
      if (gridViewEle && gridViewEle.children().length !== 0) {
        const targetNodeId = gridViewEle[0]?.id;
        const htmlPresentation = $("#" + targetNodeId).html();
        arr = self.updateHtmlPresentation(arr, targetNodeId, htmlPresentation);
        return arr;
      } else {
        gridViewEle.remove();
      }
    });
    return arr;
  }

  initializeGrid() {
    var self = window.vdmModelView;
    //var dragCounter = 0;
    if (!self.getViews) {
      return;
    }

    window.autoProcess = false;
    self.docElem = document.getElementById("DashboardContainer");
    window.utils.stopSpinner("dashboardSpinner");
  }

  resizeNavWidth(width){
    var self = this;
    var actualWidth = self.navbarWidth-(+width);
    var adjWidth = +self.navbarWidth-40;
    const element = document.getElementById('navbar')
    element.style.width = actualWidth.toString()+"px"
    if(width>=140){
      self.toc(true);
    }
  }

  /*assignHeight(nodes) {
    var tempHeight = 0
    nodes.map((node) => {
      if (node.id !== '') {
      tempHeight = tempHeight + jQuery("#"+node?.id).actual("outerHeight") + 20;
      } else if (node.subRows && node.subRows.length > 0) {
        return {
          ...node,
          subRows: this.assignHeight(node.subRows),
        };
      }
      return node;
    });
    return tempHeight
  }*/
 

  setResizeHeight(redraw) {
    setTimeout(function () {
      var self = window.vdmModelView;
      var splitterNode = jQuery("#splitPanels");
      var height = 0;

        /*var treeStructure = self.model.get("treeStructure");
        if (treeStructure !== undefined && treeStructure !== null) {
          var parsedArr = JSON.parse(treeStructure);
          height = self.assignHeight(parsedArr);
        }*/

      height = jQuery(".DashboardContent").height()
     
      var rightTabHeight = 0;
      if (jQuery("#rightTab").width() > 0) {
        rightTabHeight = jQuery("#rightTab").outerHeight();
        if (jQuery("#rightTab .active a")[0]) {
          var propDivId = jQuery("#rightTab .active a")[0].hash;
          if (propDivId != null && jQuery("#rightTab .dropdown .active a")[0]) {
            propDivId = jQuery("#rightTab .dropdown .active a")[0].hash;
          }
        }
        var propNode = jQuery("" + propDivId);
        rightTabHeight = rightTabHeight + propNode.outerHeight();
      }
      var topAreaHeight = jQuery(".navbar").outerHeight();
      var lhsHeight = height + topAreaHeight;
      var windowHeight = window.innerHeight - jQuery(".navbar").height();
      var rightTab = jQuery("#rightTab");
      height = _.max([
        topAreaHeight,
        lhsHeight,
        windowHeight,
        rightTabHeight + topAreaHeight,
      ]);
      splitterNode.css("height", height + 150 + "px");
      if (redraw == true) {
        self.reIntializePresentation();
      }
      jQuery("#splitPanels").enhsplitter("refresh");
      if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains("dropdown") /*&& rightTab.height() < 50*/) {
        var dropdownDiv = rightTab.children().last();
        var appendList = dropdownDiv.find("li");
        var removedList = [];
        for (var i = 0; i < appendList.length; i++) {
          dropdownDiv.before(appendList[i]);
          removedList.push(appendList[i]);
        }
        if (appendList.length === removedList.length) {
          dropdownDiv.remove();
        }
      }
      if (
        rightTab.width() > 10 &&
        rightTab.width() < 465 &&
        rightTab.children().length > 2 /* && rightTab.height() > 50*/
      ) {
        var rightChildren = rightTab.children();
        var appendList = [];
        for (var i = rightChildren.length; i > 0; i--) {
          if (!rightChildren[i - 1].classList.contains("dropdown")) {
            appendList.push(rightChildren[i - 1]);
            if (rightTab.width() < 415 && rightChildren[i - 2]) {
              appendList.push(rightChildren[i - 2]);
              if (rightTab.width() < 330 && rightChildren[i - 3]) {
                appendList.push(rightChildren[i - 3]);
                if (rightTab.width() < 235 && rightChildren[i - 4]) {
                  appendList.push(rightChildren[i - 4]);
                }
                break;
              }
              break;
            } else {
              break;
            }
          }
        }
        if (!rightChildren.last()[0].classList.contains("dropdown")) {
          rightTab.append(
            '<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>'
          );
        }
        for (var i = 0; i < appendList.length; i++) {
          rightTab.children().last().find("ul").prepend(appendList[i]);
        }
      }
      if (
        rightTab.children().last()[0] &&
        rightTab.children().last()[0].classList.contains("dropdown") &&
        propNode &&
        propNode.parent()[0] &&
        propNode.parent()[0].classList.contains("tab-content")
      ) {
        var dropdownDiv = rightTab.children().last();
        var appendList = dropdownDiv.find("li");
        for (var i = 0; i < appendList.length; i++) {
          appendList.eq(i).removeClass("active");
        }
      }
      window.utils.stopSpinner("refreshCharts");
    }, 200);
  }

  reIntializePresentation(event,view){
    var timeout = 100;
    /*if(event && event.target.className == "splitter_handle"){
      timeout = 200;
    }*/
    window.utils.startSpinner('refreshPre','Refreshing ...');
    setTimeout(() => {
      const maps = ["vdml_StrategyMapDiagram","canvas_BMCanvasDiagram","vdml_EcoMapDiagram","vdml_CapabilityLibraryDiagram","vdml_ValueStreamMapDiagram"]
      var self = window.vdmModelView;
      _.each(self.charts(), function (presentationView) {
        if (presentationView.viewInstance && presentationView.viewInstance.initializePresentation &&
          (presentationView.viewInstance.selectedChartType && presentationView.viewInstance.selectedChartType() !== "Table" || maps.includes(presentationView.viewInstance.type) )) {
          if($("#view"+presentationView.encodeId).is(':visible')){
            presentationView.viewInstance.initializePresentation(true);
          }
        }
      });
      self.setResizeHeight();
      window.utils.stopSpinner('refreshPre');	
    }, timeout);
  }

  fetchDashboardTimeStampsList() {
    var self = this;
    var scenarioConfig = self.model.get("config");
    var dashboardTimeStamps = [];
    if (scenarioConfig !== undefined) {
      var obj = JSON.parse(scenarioConfig);
      for (var alt in obj) {
        if (obj[alt] && obj[alt].length > 0) {
          for (var timeStamp in obj[alt]) {
            if (isNaN(obj[alt][timeStamp])) {
              dashboardTimeStamps.push(obj[alt][timeStamp]);
            }
          }
        }
      }
      return dashboardTimeStamps;
    } else {
      return dashboardTimeStamps;
    }
  }

  hasUnsavedData() {
    var self = window.vdmModelView;
    _.each(this.charts(), function (presentationView) {
      var json = JSON.parse(presentationView.model.get("chartConfig"));
      if (
        presentationView.viewInstance &&
        presentationView.viewInstance.selectedChartType
      ) {
        json["selectedChartType"] =
          presentationView.viewInstance.selectedChartType();
      }
      if (
        presentationView.viewInstance &&
        presentationView.viewInstance.selectedGroupType
      ) {
        json["selectedGroupType"] =
          presentationView.viewInstance.selectedGroupType();
        json["savedMinimum"] = Date.parse(
          presentationView.viewInstance.minimumDate()
        );
        json["savedMaximum"] = Date.parse(
          presentationView.viewInstance.maximumDate()
        );
        json["savedInterval"] =
          presentationView.viewInstance.selectedIntervalValue();
      }
      if (
        presentationView.viewInstance &&
        presentationView.viewInstance.showValues
      ) {
        json["showValues"] = presentationView.viewInstance.showValues();
      }
      presentationView.model.set("chartConfig", JSON.stringify(json));
    });
    /*if (self.codeContainersList) {
      var codeContainersList = self.codeContainersList;
      _.each(codeContainersList, function (key) {
        CodeContainer.getBlobInstanceWithId(
          key,
          DataManager.getDataManager().get("currentPlan"),
          function (codeContainer) {
            if (
              codeContainer &&
              codeContainer.get("lastModified") > self.viewTime
            ) {
              codeContainer.save(null, {
                success: function () {
                  DataManager.getDataManager().unRegisterCodeContainer(
                    codeContainer
                  );
                },
                error: function (error) {
                  console.log(error);
                  //DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                },
              });
            }
          }
        );
      });
      codeContainersList.length = 0;
    }*/
  }

  init(model, options) {
    var self = this;
    this.DashboardViewModel = this;
    this.hashTreeData = new Map();
    this.model = model;
    this.id = kb.observable(model, "id");
    this.toc = ko.observable(false);
    this.name = ko.observable(this.model.get("name"));
    this.navbarWidth = 210;
    this.description = ko.observable(this.model.get("description"));
    this.viewTime = new Date().getTime();
    this.currentAlternativeModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: DataManager.getDataManager().get("viewAlternative") }); // for selenium
    this.editMode = DataManager.getDataManager().get('readMode')?false:true;
     var dash = self.model;
    var treeData = dash.get("treeStructure");
    var parsedTreeData = treeData ? JSON.parse(treeData) : [];
    if(window.utils.checkIsLinked(null,true)){
       self.editMode = false;
    }
    this.mapInstance = {};

    self.eventBus = {
      listeners: {},
      on: function (event, callback) {
        if (!this.listeners[event]) {
          this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
      },
      emit: function (event, data) {
        const listeners = this.listeners[event];
        if (listeners) {
          listeners.forEach((callback) => callback(data));
        }
      },
    };   

    if (
      (parsedTreeData.length == 0)) {
        if(self.model.get("presentation").length>0){
          let id = window.uuidGenerator();
          const presentationList = self.model.get("presentation").map((chart) => {
            return {
              id: chart.id,
              name: chart.get("name"),
              type: 1,
              b: false,
              subRows: [],
              uId:window.uuidGenerator(),
              w: 100,
              pId: id,
              h: "700",
            };
          });
          const container = {
              id: id,
              name: "",
              type: 0,
              b: true,
              subRows: [...presentationList],
              uId:window.uuidGenerator(),
              w: 100,
              pId: "root",
              h: "700",
          }
        const rootObj = {
          uId:window.uuidGenerator(),
          id: "root",
          name: "",
          type: 0,
          b: false,
          subRows: [container],
          w: 100,
          pId: "",
          h: ""
        }
      dash.set("treeStructure", JSON.stringify(rootObj));
      }
      self.toc(true);
    } else {
      if (parsedTreeData.length > 0) {
        var migJson = self.JSONMigration({ 
          uId:window.uuidGenerator(),
          id: "root",
          name: "",
          type: 0,
          b: false,
          subRows: parsedTreeData,
          w: 100,
          pId: "",
          h: ""
        });
        dash.set("treeStructure", JSON.stringify(migJson));
      }
    }
    self.DashboardElement = DashboardElement;
    self.NavigationTree = NavigationTree;
    self.newPresentation = false;
    self.treeData = self.model.get("treeStructure") ? JSON.parse(self.model.get("treeStructure")) : null;
    self.renderId = "";

    ko.bindingHandlers.react = {
      init() {
        return { controlsDescendantBindings: true };
      },
      update: function (element, valueAccessor) {
        var component = ko.unwrap(valueAccessor().component);
        
        var renderComponent = (props) => {
          ReactDOM.render(React.createElement(component,props), element);
        };
        const eventListener = (props) => {
          renderComponent(props);
        };
        
        self.eventBus.on("propsUpdated", eventListener);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
          ReactDOM.unmountComponentAtNode(element);
        });
      },
    };

    ko.bindingHandlers.toc = {
      init() {
        return { controlsDescendantBindings: true };
      },
      update: function (element, valueAccessor) {
        var component = ko.unwrap(valueAccessor().component);
        var renderComponent = (props) => {
          ReactDOM.render(React.createElement(component,props), element);
        };
        const eventListener = (props) => {
          renderComponent(props);
        };
        self.eventBus.on("updatedNav", eventListener);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
          ReactDOM.unmountComponentAtNode(element);
        });
      },
    };   

    this.dashboardDetailsViewModel = DashboardDetailsViewModel.getInstance(this);
    if (!ko.components.isRegistered("DBDetails")) {
      ko.components.register("DBDetails", {
        //viewModel: DashboardDetailsViewModel,
        viewModel: { instance: self.dashboardDetailsViewModel },
        template: window.utils.getHtmlPage("DashboardDetailsTemplate"),
        //synchronous: true
      });
    }
    self.dashboardScenariosViewModel = DashboardScenariosViewModel.getInstance(this);
    if (!ko.components.isRegistered("Scenarios")) {
      ko.components.register("Scenarios", {
        //viewModel: DashboardScenariosViewModel,
        viewModel: { instance: self.dashboardScenariosViewModel },
        template: window.utils.getHtmlPage("DashboardScenariosTemplate"),
        synchronous: true,
      });
    }
    self.dashboardDesignViewModel = DashboardDesignViewModel.getInstance(this);
    if (!ko.components.isRegistered("Views")) {
      ko.components.register("Views", {
        //viewModel: DashboardDesignViewModel,
        viewModel: { instance: self.dashboardDesignViewModel },
        template: window.utils.getHtmlPage("DashboardDesignTemplate"),
        synchronous: true,
      });
    }

    self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
    if (!ko.components.isRegistered("DashboardGuidance")) {
      ko.components.register("DashboardGuidance", {
        viewModel: { instance: self.guidanceViewInstance },
        template: '<div id="guidanceTab"></div>',
        synchronous: true,
      });
    }
    this.labels = kb.viewModel(
      DataManager.getDataManager().get("localeManager"),
      [
        "name",
        "description",
        "Cancel",
        "next",
        "Collaboration",
        "Guidance",
        "Delete",
        "Details",
        "select",
        "Details",
        "Complete",
        "Settings",
        "Scenarios",
        "Views",
        "export",
        "PresentationType",
        "Theme"
      ]
    );
    this.chartCollection = new Backbone.Collection();
    self.charts = kb.collectionObservable(self.chartCollection, {
      view_model: PresentationViewModel,
    });
  }

  static getInstance = function (model, options) {
    var view = new DashboardViewModel(model, options);
    view.init(model, options);
    return view;
  };
}
path.DashboardViewModel = DashboardViewModel;
