import * as _ from 'underscore'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {AlternativeMixin} from './AlternativeMixin'
import { NamedPlanElementMixin } from './NamedPlanElementMixin';
import { ValueElementContext } from '../vdml/ValueElementContext';

var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);

export class Alternative2Mixin{
    // getMainScenario(){
    //     return this.getDefaultScenario();
	// };
    getDefaultScenario(){
        return DataManager.getDataManager().get("defaultScenario");
    }
    getMixinClass(){
		return Alternative2Mixin;
	}
    getViewProperties(type){
        return Alternative2Mixin.getDialogViewProperties(type);
    }
    static getMixinRelations(){
		let relations = AlternativeMixin.getMixinRelations();
		relations.splice(relations.findIndex(item => item.key === "phaseObjectiveSet"), 1)
        relations = _.union(relations,[{
            type :Backbone.HasMany,
            containingClass:"transformation_Alternative",
            key:"valueElementContext",
            relatedModel:"vdml.ValueElementContext",
            includeInJSON: Backbone.Model.prototype.idAttribute,
            reverseRelation: {
                key:"alternative",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
            },
            {
                type :Backbone.HasMany,
                containingClass:"transformation_Alternative",
                key:"alternativeValues",
                relatedModel:"vdml.ValueElement",
                includeInJSON: Backbone.Model.prototype.idAttribute,
        }])
        return relations;
    }
    static getCumulativeMixinRelations(){
		if (!AlternativeMixin.cummulativeRelations) {
            AlternativeMixin.cummulativeRelations = _.union(Alternative2Mixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return AlternativeMixin.cummulativeRelations.slice();
	}
    static getDialogViewProperties(type) { 
        if(type === "AlternativeInputValues"){
            return {
                templatePath : "views/transformation/views/properties/AlternativeInputValues2Template.html",
                templateName : "AlternativeInputValues2Template",
                viewTypeStr : "appviews/transformation/views/properties/AlternativeInputValues2ViewModel",
                tabId : "AlternativeInputValues2View",
                tabName: "AlternativeInputValues2"
            }
        } else {
            return AlternativeMixin.getDialogViewProperties(type);
        }
    }
    getPhaseAlternativeSteps(scenario){
		var self = this;
		var datasetsArr = []
		var periodKind = self.getNestedParent().get("periodKind");
        var step = scenario.get("step").findWhere({alternativeId: self.id});
        if(!step){
            return datasetsArr;
        }
        var periodsLen = step.get('noPeriods');
        var period = step.get("startPeriod");			
        for(var l = 0; l < periodsLen; l++){
            if(l != 0){
                period++;
            }
            let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
            datasetsArr.push({...yearPeriod});
        }
		return datasetsArr;
	}
    getOrCreatePhaseObjectiveSet(){
        var plan = this.get('phaseAlternativeOwner').get('phaseOwner');
        plan.getOrCreatePlanCriterionSet();
        var dataManager = DataManager.getDataManager();
        //var scenario = self.currentAlternativeModel.getDefaultScenario(value.getNestedParent());
        var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
		return scenario;
	}

    deleteValueContext(model){
		var contexts = model._previousAttributes.valueElementContext.models;
		var i = contexts.length - 1;
		while (i >= 0) {
			contexts[i].destroy();
			i--;
		}
	}
    getCalculationChangeType(operationType){
        if(operationType == "add" || operationType == "destroy"){
            return 0;
        }
	}
}
utils.customExtendClass (Alternative2Mixin,new AlternativeMixin());

path.Alternative2Mixin = Alternative2Mixin;