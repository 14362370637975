import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { StrategyMapValuesChart } from '../../../../../version1/views/strategyMap/views/designer/StrategyMapValuesChart'
import * as Highcharts from 'highcharts';
import { ValueStreamMapDetailsViewModel } from './ValueStreamMapDetailsViewModel'
import { BusinessModel } from '../../../../../version1/bo/vdml/BusinessModel'
import { Participant } from '../../../../../version1/bo/vdml/Participant'
import { ValueElement } from '../../../../../version1/bo/vdml/ValueElement'
import { ValueStreamMapDiagram } from '../../../../../version1/bo/vdml/ValueStreamMapDiagram'
import { ValueStreamMapDiagramMixin } from '../../../../../version1/bo/vdml/ValueStreamMapDiagramMixin'
import { ValueDeliveryModelMixin } from '../../../../../version1/bo/vdml/ValueDeliveryModelMixin'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
import { EcoMapMixin } from '../../../../../version1/bo/ecomap/EcoMapMixin'
import { BMGuidanceViewModel } from '../../../help/views/properties/BMGuidanceViewModel'
import * as EcoModeler from '../../../../../../libs/bower-vdml-js/dist/vdml-modeler.development'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'

//define(["require", "jquery", "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/ValueStreamMapDiagram", "appbo/vdml/ValueStreamMapDiagramMixin", "appbo/vdml/ValueDeliveryModelMixin", "knockback", "ecomodeler", "econavigatedviewer", "appviews/valueStreamMap/views/designer/ValueStreamMapDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer", "appviews/strategyMap/views/designer/StrategyMapValuesChart", "appbo/ecomap/EcoMapMixin"],
    //function (require, $, _, Backbone, ko, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, ValueStreamMapDiagram, ValueStreamMapDiagramMixin, ValueDeliveryModelMixin, kb, EcoModeler, EcoNavigatedViewer, ValueStreamMapDetailsViewModel, BMGuidanceViewModel, CodeContainer, StrategyMapValuesChart, EcoMapMixin) {

var path = DataManager.getDataManager().buildAppNsPath("valueStreamMap.views.designer", global.version);
export class ValueStreamMapDesignerViewModel {
            
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
    }

    createRevisionBasedOnContext(pack, callback) {
        if (window.checkContextForRevision()) {
            window.utils.startSpinner('revisionSpinner', "Creating a copy...");
            function fetchBmModel(newVdmPackage) {
                if (newVdmPackage) {
                    DataManager.getDataManager().releaseSaveLock();
                    DataManager.getDataManager().set('isActive', false);
                    callback(newVdmPackage);
                }
            }
            window.setTimeout(function () {
                DataManager.getDataManager().acquireSaveLock(function () {
                    window.utils.startSpinner('valueStreamMapSpinner', "Navigating to Value Stream Map...");
                    pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                });
            }, 100);
        }
        else {
            callback(pack);
        }
    };
    checkForRevision() {
        var self = this;  
        if (window.checkContextForRevision()) {
            var pack = self.model.getNestedParent();
            pack.checkRevision(self.model, function (revision) {
                if (revision) {
                    self.createRevisionBasedOnContext(pack, function (newEcoPackage) {
                        var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                        var altId = DataManager.getDataManager().getRepositoryId(newEcoPackage.id);
                        var valueStreamMap = newEcoPackage.get('diagrams').findWhere({ 'id': altId + bmId });
                        var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                        var diagrams = newEcoPackage.get('diagrams');
                        for (var i = 0; i < diagrams.length; i++) {
                            newEcoPackage.fixDiagramOnRevision(diagrams.at(i), altId,oldAltId);
                        }
                        window.utils.stopSpinner('revisionSpinner');
                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newEcoPackage.get('id') + "/" + newEcoPackage.get('type') + "/" + newEcoPackage.get('version') + "/" + valueStreamMap.id + "/" + valueStreamMap.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                    });
                }
            });
        }
    }


    fetchBmCounts() {
        var self = this;
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
            self.capabilityLibs = capabilityLibraries;
        });
        /*currentPlan.loadLibraryPackages(function(capabilities){
            self.allCapabilities = capabilities;
        })*/
    }
    
    fixActivityMapping(){
        var self = this;
        var rootEle = self.modeler._definitions ? self.modeler._definitions.rootElements: null;
        var shapes = rootEle ? rootEle[0].flowElements : [];
        _.each(shapes,function(allShape){
            if(allShape.$type == "vdml:ValueStream" && allShape.flowElements){
                var acts = allShape.flowElements;
                var valStreamObj = allShape.mid?window.utils.getElementModel(allShape.mid,['vdml.ValueStream']):null;
                _.each(acts,function(shape){
                    if (shape.$type === "vdml:Activity" && shape.mid){  
                        if(!valStreamObj){
                            shape.set('mid', null);
                            shape.set('mpid', null);
                        }else {
                            var actExists = valStreamObj.get('valueStreamActivity').findWhere({ 'id': shape.mid });
                            if(!actExists){
                                var act =  Backbone.Relational.store.getObjectByName("vdml.Activity").find({'id':shape.mid});
                                self.model.get('activity').remove(act);
                                shape.set('mid', null);
                                shape.set('mpid', null);
                            }
                        }
                    }
                })
            }
        });
    }
    fixCompetencyMapping(){
        var self = this;
        function checkResourceExists(activity,comp){
            var biFlows = comp.get('flow');
            var competencePresent = false;
            for (var k = 0; k < biFlows.length; k++) {
                var resourceColl = [];
                var resourceActivity = biFlows.at(k).get('recipient') ? biFlows.at(k).get('recipient').get('containedPortOwner') : '';
                if (activity == resourceActivity) {
                    competencePresent = true;
                    break;
                }
            }
            return competencePresent;
        }
        var rootEle = self.modeler._definitions ? self.modeler._definitions.rootElements: null;
        var shapes = rootEle ? rootEle[0].flowElements : [];
        _.each(shapes,function(allShape){
            if(allShape.$type == "vdml:ValueStream" && allShape.flowElements){
                var acts = allShape.flowElements;
                _.each(acts,function(shape){
                    if (shape.$type === "vdml:Activity") {
                        var act =  Backbone.Relational.store.getObjectByName("vdml.Activity").find({'id':shape.mid});
                        var outgoingConn = shape.outgoing;
                        if (outgoingConn){
                            var capOffers = act ? act.getActivityCompetences(): [];
                            for(var i=0; i < outgoingConn.length; i++){
                                if(outgoingConn[i].$type == "vdml:CausalRelationship" && outgoingConn[i].targetRef.$type == "vdml:CapabilityContainer"){
                                    var compEle = outgoingConn[i].targetRef.flowElements;
                                    if(compEle) {
                                        for(var j = 0; j < compEle.length; j++){
                                            if(compEle[j].mid) {
                                                var found = false;
                                                var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityOffer').find({ id: compEle[j].mid });
                                                if (capModel && capOffers.indexOf(capModel) != -1) {
                                                    found = true;
                                                }else if(!capModel && act){
                                                    capModel = Backbone.Relational.store.getObjectByName('vdml.BusinessItem').find({ id: compEle[j].mid });
                                                    found = capModel ? checkResourceExists(act,capModel): false;
                                                }
                                                if(!found){
                                                    compEle[j].set('mid', null);
                                                    compEle[j].set('mpid', null);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            }
        });
    }
	zoomReset() {
        var self = this;
        self.modeler.get("canvas").zoom("fit-viewport", true);
    }
    zoomIn() {
        var self = this;
        self.modeler.get('zoomScroll').stepZoom(1);
    }
    zoomOut() {
        var self = this;
        self.modeler.get('zoomScroll').stepZoom(-1);
    }		
    openDiagram(xml) {
        var self = this;
        /*if(window.autoProcess){ // skip loading when creating diagrams
            return;
        }*/
        //var xml = xml.split(self.model.id).join('v'+htmlEscape(self.model.id));
        //self.model.set('data',xml);
        async function myDisplay() {
            let result = new Promise(function(myResolve, myReject) {
                myResolve("Works");
            });
            try {
                result = await self.modeler.importXML(xml);
                var { warnings } = result;
                console.log(warnings);
                //self.modeler.importXML(xml, function (err) {
                // if (err) {
                //      console.error(err);
                //  }
                //self.modeler.get("canvas").zoom("fit-viewport", true);
                //$('svg').css('margin-left', 70);
                if (window.vdmModelView === self) {
                    var height = self.model.get('height');
                    if (!height) {
                        height = self.getViewportSize().h * 0.80 + $('.navbar').height();
                    }
                    height = height > 700 ? height : 700;//to avoid 2 column djs-palette
                    $("#js-canvas" + self.encodeId).height(height);
                    self.fixActivityMapping();
                    self.fixCompetencyMapping();
                    self.loadTable();
                    //$(".icon-hand-tool").click();
                    window.utils.stopSpinner('valueStreamMapSpinner');
                }
                self.initializePresentation();
            } catch (err) {
                /*if(err.message = "no process or collaboration to display"){
                    var $xml = jQuery(xml);
                    var nodes = $xml.find('[id="' + self.model.id + '"]');
                    if(nodes.length != 0){
                        var diagramType = self.model.get('type').split("_");
                        var diagramTypeSuffix = "v";
                        if(diagramType[1]){
                            diagramTypeSuffix = diagramType[1].charAt(0).toLowerCase();
                        }
                        var newId = diagramTypeSuffix + window.utils.htmlEscape(self.model.id);
                        var newXML = self.model.getNestedParent().fixXMLOnRevision(xml,self.model.id,newId);
                        self.model.set('data',newXML);
                        self.openDiagram(newXML);
                    }
                } else {*/
                    console.log(err.message, err.warnings);
                //}
            }
        }
        myDisplay();
    }
    showProperties(data, ev) {
        var self = this;
        if(self.editMode){
            var id = window.utils.htmlEscape(window.guidGenerator());
            var elementRegistry = self.modeler.get('elementRegistry');
            var options = { businessObject: data.businessObject, element: elementRegistry.get(data.businessObject.id), width: '660px' };
            window.getAndCreateModalDialog(self, id, ValueStreamMapDiagramMixin, self.model, "ShapeProperties", null, options);
        }
    }
    loadTable(event) {
		if(window.autoProcess){
			return;
		}
        var self = this;
        self.shapesList.removeAll();
        self.unsortedShapesList = [];
        var shapes = [], definitions, ecoMap, modeler = self.modeler;
        if (modeler) {
            definitions = modeler._definitions;
        }
        if (definitions) {
            ecoMap = definitions.rootElements;
        }
        if (ecoMap && ecoMap.length) {
            var shapesList = ecoMap[0].flowElements ? ecoMap[0].flowElements : [];
            for (var i = 0; i < shapesList.length; i++) {
                if (shapesList[i].$type === "vdml:ValueStream") {
                    shapes.push(shapesList[i]);
                    if (shapesList[i].flowElements) {
                        shapes = shapes.concat(shapesList[i].flowElements);
                    }
                }
                else if (shapesList[i].$type === "vdml:CapabilityContainer") {
                    shapes.push(shapesList[i]);
                    if (shapesList[i].flowElements) {
                        shapes = shapes.concat(shapesList[i].flowElements);
                    }
                } else {
                    shapes.push(shapesList[i]);
                }
            }

        }
        if (shapes) {
            for (var i = 0; i < shapes.length; i++) {
                if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
                    continue;
                }
                var type = shapes[i].$type;
                if (type === "vdml:BusinessItem" || type === "vdml:DeliverableFlow" || type === "vdml:CausalRelationship") {
                    continue;
                }
                var typeToDisplay = self.mappingObject[type].name;
                var name = shapes[i].name;
                var mappingEleId = shapes[i].mid;
                var packId = shapes[i].mpid;
                var mappingEleName = '';
                if (mappingEleId) {
                    self.model.getMappedElement(mappingEleId, packId, type, shapes[i], function (model) {
                        mappingEleName = model ? model.get('name') : '';
                        if (model && model.get('type') === "vdml_ValueStream") {
                            mappingEleName = model.get('persuedProposition')?model.get('persuedProposition').get('name'):model.get('name');
                        }
                    });
                }
                if (type !== "vdml:CapabilityContainer"){
                    self.shapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
                }
                self.unsortedShapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
            }
        }
        //self.setResizeHeight();
        self.shapesList.sort(self.sortShapesInTableByName);
    }
    sortShapesInTableByName(left, right) {
        if (left.name && right.name) {
            return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        }
    }
    initializePresentation() {
        var self = this;
        //self.fetchBmCounts();
        self.modeler.get("canvas").resized();
        self.modeler.get("canvas").zoom("fit-viewport", true);
        //self.afterRenderView(true);
    };
    cleanUp() {
        var self = this;
        self.parentView = null;
        if (self.modeler) {
            self.modeler.destroy();
        }
        if (ko.components.isRegistered('ValueStreamMapDetails')) {
            ko.components.unregister('ValueStreamMapDetails');
        }
        if (ko.components.isRegistered('ValueStreamMapGuidance')) {
            ko.components.unregister('ValueStreamMapGuidance');
        }
        if (ko.components.isRegistered('StrategyValuesChart')) {
            ko.components.unregister('StrategyValuesChart');
        }
        //window.utils.loadTemplate(null,true);
        if (self.spliter) {
            self.spliter.enhsplitter('remove');
        }
        /*if (self.loadedCodedContainers) {
            var codeContainersList = self.loadedCodedContainers;
            _.each(codeContainersList, function (codeContainer) {
                codeContainer.save(null, {
                    success: function () {
                        DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                    },
                    error: function (error) {
                        console.log(error);
                        //DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                    }
                });
            });
            codeContainersList.length = 0;
        }*/
    }
    saveShapeDetailsData(view){
        var self = this;
        var found = false;
        for(var i=0; i < self.children.length; i++){
            if(self.children[i].modal == view.mappedElementModel){
                self.children[i].name = view.name();
                self.children[i].description = view.description();
                found = true;
                break;
            }
        }
        if(!found){
            self.children.push({'name':view.name(),'description':view.description(),'modal':view.mappedElementModel});
        }
    }
    saveTempCapList(xml){
        var self = this;
        var xmlDoc = jQuery.parseXML(xml);
        var $xml = jQuery(xmlDoc);
        for(var i=0; i < self.tempCapbilityIdList.length; i++){
            var nodes = $xml.find('[mcid="' + self.tempCapbilityIdList[i] + '"]');
            var cap = self.getCapabilityElementModel(self.tempCapbilityIdList[i]);
            if(nodes.length > 0){
                self.model.get('capability').add(cap);
            }else {
                self.model.get('capability').remove(cap);
            }
        }
    }
    saveSyncData(revision){
        var self = this;
        for(var i=0; i < self.children.length; i++){
            var vdmlModal = self.children[i].modal;
            if(revision){
                var modelSuffix = vdmlModal.id.substr(vdmlModal.id.lastIndexOf('@') + 1);
                var type = vdmlModal.get('type').replace('_', '.')
                vdmlModal = Backbone.Relational.store.getObjectByName(""+type).find({ id: DataManager.getDataManager().get('viewAlternative') + modelSuffix });
            }
            if(vdmlModal) {
                if(vdmlModal.get('type') == "vdml_ValueStream"){
                    vdmlModal = vdmlModal.get('persuedProposition');
                }
                vdmlModal.set('name',self.children[i].name);
                vdmlModal.set('description',self.children[i].description);
            }
        }
        self.children = [];
        EcoMapMixin.removeImageArtifact(self.deletedCodedContainers,self.model.getNestedParent());
        self.deletedCodedContainers = [];
    }
    createRevision(newXML, callback, depPacks) {
        var self = this;
        window.utils.startSpinner('revisionSpinner', "Creating a copy...");
        setTimeout(function () {
            self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
                var newRep = DataManager.getDataManager().getRepositoryId(newPackage.id);
                var modelSuffix = self.model.id.substr(self.model.id.lastIndexOf('@') + 1);
                var newDiagram = Backbone.Relational.store.getObjectByName("vdml.ValueStreamMapDiagram").find({ id: newRep + modelSuffix });
                newDiagram.set('data', newXML, { silent: true });
                var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                var diagrams = newPackage.get('diagrams');
                for (var i = 0; i < diagrams.length; i++) {
                    newPackage.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
                }
                window.utils.stopSpinner('revisionSpinner');
                callback(newPackage, newDiagram);
            }, depPacks);
        }, 10);
    }
    saveDiagram(view, event, callback, revisionValueStreamMap, vdmlPackageRevision, enableComplete) {
        var self = this;
        if (self.model) {
            window.utils.startSpinner('saveSpinner', "Saving ValueStream-Map...");
            setTimeout(function () {
                    async function saveVdmioXML() {
                        let result = new Promise(function(myResolve, myReject) {
                            myResolve("Works");
                        });
                        try {
                            result = await self.modeler.saveXML({ format: true });
                            var { xml } = result;
                            if (xml) {
                                if (window.checkContextForRevision()) {
                                    if (revisionValueStreamMap) {
                                        if (vdmlPackageRevision) {
                                            //var newXml = window.utils.replaceAll(xml, DataManager.getDataManager().get('currentWorkspace').id, DataManager.getDataManager().get('viewAlternative'));
                                            self.model.set('data', xml, { silent: true });
                                            self.saveSyncData(true);
                                            self.saveTempCapList(xml);
                                        } else {
                                            self.model.set('data', xml, { silent: true });
                                            self.saveSyncData();
                                            self.saveTempCapList(xml);
                                        }
                                        var oldAltId = DataManager.getDataManager().get('currentWorkspace').id;
										var newRep = DataManager.getDataManager().get('viewAlternative');
										var packEco = self.model.getNestedParent();
										var diagrams = packEco.get('diagrams');
										for (var i = 0; i < diagrams.length; i++) {
											packEco.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
										}
                                        self.enableComplete(false);
                                        window.utils.stopSpinner('saveSpinner');
                                        if (callback) {
                                            callback();
                                        } else {
                                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionValueStreamMap.getNestedParent().get('id') + "/" + revisionValueStreamMap.getNestedParent().get('type') + "/" + revisionValueStreamMap.getNestedParent().get('version') + "/" + revisionValueStreamMap.id + "/" + revisionValueStreamMap.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                        }
                                    } else {
                                        //self.model.set('data', newXml, { silent: true });
                                        if (!revisionValueStreamMap && !vdmlPackageRevision && !self.enableComplete()) {
                                            window.utils.stopSpinner('saveSpinner');
                                            if (callback) {
                                                callback();
                                            }
                                        } else {
                                            var ecoPackage = self.model.getNestedParent();
                                            //var depPacks = ecoPackage.get('dependentPackage') ? ecoPackage.get('dependentPackage').models.concat() : [];
                                            self.createRevision(xml, function (newPackage, newDiagram) {
                                                self.saveSyncData(true);
                                                self.saveTempCapList(xml);
                                                self.enableComplete(false);
                                                window.utils.stopSpinner('saveSpinner');
                                                if (callback) {
                                                    callback();
                                                } else {
                                                    DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + newDiagram.id + "/" + newDiagram.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                                }
                                            }, []);
                                        }
                                    }
                                } else {
                                    if(vdmlPackageRevision){
                                        var xmlDoc = jQuery.parseXML(xml);
                                        var $xml = jQuery(xmlDoc);
                                        var alt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('currentWorkspace').get('id'));
                                        var vdmlPackIds = alt.getOwnedVDMLPackages();
                                        _.each(vdmlPackIds,function(packId){
                                            var pac = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: packId });
                                            if(pac) {
                                                var oldPackId = pac.get('copySource');
                                                var nodes = $xml.find('[mpid="' + oldPackId + '"]');
                                                nodes.each(function (callback, node) {
                                                    node.setAttribute('mpid', packId);
                                                    var oldmidSuffix = window.utils.getSuffix(node.getAttribute('mid'));
                                                    node.setAttribute('mid', alt.id + oldmidSuffix);
                                                })
                                            }
                                        });
                                        xml = (new XMLSerializer()).serializeToString(xmlDoc);
                                    }
                                    self.model.set('data', xml);
                                    self.saveSyncData();
                                    self.saveTempCapList(xml);
                                    self.enableComplete(false);
                                    window.utils.stopSpinner('saveSpinner');
                                    if (callback) {
                                        callback();
                                    }
                                }
                            }else {
                                    self.enableComplete(false);
                                    window.utils.stopSpinner('saveSpinner');
                                    if (callback) {
                                        callback();
                                    }
                                }
                        } catch (err) {
                            console.log(err.message, err.warnings);
                        }
                    }
                    saveVdmioXML();
                        /*self.modeler.saveXML({ format: true }, function (err, xml) {

                        });*/
            }, 100);
        } else {
            callback();
        }
    };

    hasUnsavedData() {
        if (this.enableComplete() /*&& !self.viewMode()*/) {
            return true;
        } else {
            return false;
        }
    };

    saveDataBeforeClosing(callback) {
        var self = this;
        self.saveDiagram(null, null, callback);
    };

    getViewportSize(w) {

        // Use the specified window or the current window if no argument
        w = w || window;

        // This works for all browsers except IE8 and before
        if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

        // For IE (or any browser) in Standards mode
        var d = w.document;
        if (document.compatMode == "CSS1Compat")
            return {
                w: d.documentElement.clientWidth,
                h: d.documentElement.clientHeight
            };

        // For browsers in Quirks mode
        return { w: d.body.clientWidth, h: d.body.clientHeight };

    }
    sortByName(left, right) {
        return left.get('name').toLowerCase() === right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1);
    }
    shapeDeleteModel(businessObject,element){
        var self = this;
        var selectedBmsModelsList =[];
        self.businessObject = businessObject;
        var pack = self.model.getNestedParent();
        var htmlContent = "<div class=\"row\">";
        htmlContent = htmlContent + "<span style ='margin-left:15px'>"+DataManager.getDataManager().get('localeManager').get('DeleteShapeMsg')+"</span>";
        if(businessObject.mid /*&& !window.checkContextForRevision()*/){
            htmlContent = "<span style = 'font-weight:bold'>Do you want to delete this element from following Business Models? Deleting it may lead to loss of data, that cannot be recovered, do you want to continue ? </span><br></br>";
            htmlContent += "<table class='table table-bordered' style = 'width:450px;margin-left:50px'><thead><tr>";
            htmlContent +=  "<th>Business Model(s)</th></tr></thead>";
            htmlContent +=  "<tbody id= 'bmsModelsList'>";
            htmlContent +=  "</tbody></table>"
        }
        htmlContent = htmlContent + "</div>";

        var dialog = bootbox.dialog({
            title: "<div>&nbsp;&nbsp;&nbsp;Delete Shape</div>",
            message: htmlContent,
            show: true,
            buttons:{
                main: {
                    label: "Cancel",
                    className: "btn btn-default",
                },
                success:{
                    label: '<i id="valuestreamShapeDeleteBtn" class="fa fa-check"></i> Delete',
                    className: "btn btn-complete shapeDelete", 
                    callback: function() {
							var oldPack = self.model.getNestedParent();
                            self.createRevisionBasedOnContext(oldPack, function (newPack) {
                                var rev = oldPack.id !== newPack.id;
                                var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
                                if(rev){
                                    var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                                    var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                                    var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
                                    var diagrams = newPack.get('diagrams');
                                    for (var i = 0; i < diagrams.length; i++) {
                                        newPack.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
                                    }
                                    self.model = map;
                                    var xmlDoc = jQuery.parseXML(self.model.get("data"));
		                            var jqueryXml = jQuery(xmlDoc);
                                    jqueryXml.find('[id="' + element.id + '"]').remove();
                                    jqueryXml.find('[id="' + element.id + '_di"]').remove();
                                    var newXML = (new XMLSerializer()).serializeToString(xmlDoc);
                                    self.model.set('data',newXML);
                                    window.utils.stopSpinner('revisionSpinner');
                                }
                                if (element.type === "vdml:Activity") {
                                    var outgoingConnections = element.outgoing;
                                    for (var i = 0; i < outgoingConnections.length; i++) {
                                        if (outgoingConnections[i].target.type === "vdml:CapabilityContainer" && outgoingConnections[i].target.incoming && outgoingConnections[i].target.incoming.length == 1) {
                                            self.modeler.get('modeling').removeElements([outgoingConnections[i].target]);
                                            break;
                                        }
                                    }
                                } else if(element.type === "vdml:CausalRelationship" && element.target && element.target.type === "vdml:CapabilityContainer"){
                                    self.modeler.get('modeling').removeElements([element.target]);
                                }
                                EcoMapMixin.deleteSuggestion(self.model, businessObject.id);
                                self.modeler.get('modeling').removeElements([ element ]);
                                var selectedBm = [];
                                var bms = selectedBmsModelsList;
                                for (var i = 0; i < bms.length; i++) {
                                    if ($('#' + window.utils.htmlEscape(bms[i].id)).prop('checked')) {
                                        var bmId = bms[i].id;
                                            if(rev){
                                                var bmId = altId + window.utils.getSuffix(bmId);
                                            }
                                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({
                                            id: bmId
                                        });
                                        selectedBm.push(bm);
                                    }
                                }
                                if(selectedBm.length>0){
                                    if(rev){
                                        businessObject.mid = altId + window.utils.getSuffix(businessObject.mid);
                                        businessObject.mpid = altId + window.utils.getSuffix(businessObject.mpid);
                                        EcoMapMixin.deleteTargetElement(businessObject,selectedBm,function(){
                                            EcoMapMixin.deleteSuggestion(self.model, businessObject.id);
                                            self.enableComplete(false);
                                            var newPackage = self.model.getNestedParent();
                                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + self.model.id + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                        });
                                    } else {
                                        window.utils.startSpinner('deletionSpinner', "Deleting Selected Element...");
                                        window.setTimeout(function () {
                                            EcoMapMixin.deleteTargetElement(businessObject,selectedBm,function(){
                                                EcoMapMixin.deleteSuggestion(self.model, businessObject.id);
                                                window.setTimeout(function () {
                                                    DataManager.getDataManager().invokeValueUpdates(function(){
                                                        if(self.chartViewInstance && self.chartViewInstance.initialize){
                                                            self.chartViewInstance.initialize(self.model, true, self, self.getValueList());
                                                        }
                                                        window.utils.stopSpinner('deletionSpinner');
                                                    },false);
                                                },10);
                                            });
                                        },100);
                                    }
                                } else {
                                    if(rev){
                                        self.saveDiagram(null,null,null,null, null, null);
                                        var newPackage = self.model.getNestedParent();
                                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + self.model.id + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                    }
                                }
                            });	
                    }
                }
            }
        })
        dialog.attr("id", "bootbox_id");
        $('#bootbox_id').draggable();
        if(businessObject.mid /*&& !window.checkContextForRevision()*/){
            var htmlContent ='';
            pack.checkBmModels(self.businessObject, self.businessObject.$parent.mid,function(bmsList){
                if(bmsList && !$('#bmsLists').length){
                    bmsList.sort(self.sortByName);
                    for(var i =0;i<bmsList.length;i++){
                        selectedBmsModelsList.push({id:bmsList[i].get('id')});
                        htmlContent = "<tr><td><span id = 'bmsLists' style = 'padding-left:5px'><input type=\"checkbox\" id='" + window.utils.htmlEscape(bmsList[i].id) + "'  value='" + bmsList[i].get('name') + "' style=\"cursor: pointer;\" checked /><span>" + " " + bmsList[i].get('name') + "</span></span></tr></td>";
                        $('#bmsModelsList').append(htmlContent);
                    }
                }
            });
        }
    }
    setResizeHeight(view, event) {
        var self = window.vdmModelView;
        //if (!self.showDashboardEcoMap) {
            setTimeout(function () {
                var propertiesNode = 0;
                if ($('.propertiesArea').width() > 10) {
                    propertiesNode = $('.propertiesArea').outerHeight();
                }
                //var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
                //var topAreaHeight = $('.navbar').outerHeight();
                var lhsHeight = $("#js-canvas" + self.encodeId).outerHeight() + 30;
                if ($('#mainTab' + self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('shapeDetails')) {
                    lhsHeight = $('.table-responsive').outerHeight() + $('.table-responsive').outerHeight() / 3;
                } else if ($('#mainTab' + self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('Values')) {
                    lhsHeight = $(".chart").outerHeight() + $(".chart").outerHeight()/6;
                }
                var windowHeight = window.innerHeight;
                var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]);
                if (self.spliter) {
                    self.spliter.css('height', baseHeight + 'px');
                    if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
                        var windowWidth = $(window).width() - 15;
                        $('.splitter_bar').css('left', windowWidth);
                    }
                    $("#splitPanels" + self.encodeId).enhsplitter('refresh');
                }
                var rightTab = $('#rightTab' + self.encodeId);
                if (rightTab.width() > 0) {
                    if ($('#rightTab' + self.encodeId + ' .active a')[0]) {
                        var propDivId = $('#rightTab' + self.encodeId + ' .active a')[0].hash;
                        if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                            propDivId = $('#rightTab .dropdown .active a')[0].hash;
                        }
                        var propNode = $('' + propDivId);
                    }
                }
                if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    var removedList = [];
                    for (var i = 0; i < appendList.length; i++) {
                        dropdownDiv.before(appendList[i]);
                        removedList.push(appendList[i]);
                    }
                    if (appendList.length === removedList.length) {
                        dropdownDiv.remove();
                    }
                }
                if (rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                    var rightChildren = rightTab.children();
                    var appendList = [];
                    for (var i = rightChildren.length; i > 0; i--) {
                        if (!rightChildren[i - 1].classList.contains('dropdown')) {
                            appendList.push(rightChildren[i - 1]);
                            if (rightTab.width() < 375 && rightChildren[i - 2]) {
                                appendList.push(rightChildren[i - 2]);
                                break;
                            } else {
                                break;
                            }
                        }
                    }
                    if (!rightChildren.last()[0].classList.contains('dropdown')) {
                        rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                    }
                    for (var i = 0; i < appendList.length; i++) {
                        rightTab.children().last().find('ul').prepend(appendList[i]);
                    }
                }
                if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    for (var i = 0; i < appendList.length; i++) {
                        appendList.eq(i).removeClass('active');
                    }
                }
                if ($('#mainTab' + self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('ValueStreamMap')) {
                    //self.modeler.get("canvas").zoom("fit-viewport", true);
                    self.initializePresentation();
                }
            }, 300);
        //}
    };
    getNumberOfMappings(mid, type, event) {
        var self = this;
        var shapes = self.modeler._definitions.rootElements[0].flowElements;
        var count = 0;

        for (var i = 0; i < shapes.length; i++) {
            if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
                continue;
            }
            if (type !== 'vdml:BusinessItem' || type !== "vdml:DeliverableFlow"  || type !== "vdml:CausalRelationship") {
                if (shapes[i].get('mid') === mid) {
                    count++;
                }
                var flowElements = shapes[i].get('flowElements');
                if (flowElements) {
                    for (var j = 0; j < flowElements.length; j++) {
                        if (flowElements[j].get('mid') === mid) {
                            count++;
                        }
                    }
                }
            } else {
                var flows = shapes[i].get('flows');
                for (var j = 0; j < flows.length; j++) {
                    if (flows[j].get('mid') === mid) {
                        count++;
                    }
                }
            }
        }
        return count;
    }
    getValueList() {
        var self = this;
        var valueList = [];
        _.each(self.model.get('valueStream').models, function (vs) {
            var vp = vs.get('persuedProposition');
            if(vp) {
                valueList = valueList.concat(vp.get('component').models.concat());
            }
        });
        _.each(self.model.get('activity').models, function (act) {
            act.get('containedPort').each(function (port) {
                if (port.get('type') === "vdml_OutputPort") {
                    port.get('valueAdd').each(function (valueAdd) {
                        valueList.push(valueAdd);
                    });
                }
            });
        });
        return valueList;
    }
    afterRenderView(redraw) {
        var self = this;
        /*if (window.checkContextForRevision()) {
            self.viewMode(true);
        } else {
            self.viewMode(false);
            //self.enableComplete(false);
        }*/
        
        self.checkForRevision();
        var container = $("#js-canvas" + self.encodeId);
        container.empty();
        /*if(redraw != true && self.showDashboardEcoMap){
            container.height(options.jsCanvasHeight);
        }*/
        var fontSize = '12';
        if(self.model.get('fontsize')){
            fontSize = self.model.get('fontsize');
        }
        if (!self.editMode) {
            self.modeler = new EcoNavigatedViewer({
                container: container,
                type: 'vdml_ValueStreamMapDiagram',
                additionalModules: [
                    {
                      moveCanvas: [ 'value', '' ],
                      zoomScroll: [ 'value', '' ]
                    }
                ],
                textRenderer: {
                    defaultStyle: {
                        fontSize: fontSize,
                    },
                    externalStyle: {
                        fontSize: fontSize,
                    }
                }
            });
            $('#canvas-modal-footer' + self.encodeId + ' .btn-complete').hide();
        } else {
            self.modeler = new EcoModeler({
                keyboard: { bindTo: document },
                container: container,
                type: self.model.get('type'),
                textRenderer: {
                    defaultStyle: {
                        fontSize: fontSize,
                    },
                    externalStyle: {
                        fontSize: fontSize,
                    }
                }
            });
            $('#canvas-modal-footer' + self.encodeId + ' .btn-complete').show();
            self.modeler.get('eventBus')
                .on(['commandStack.shape.create.postExecute', 'commandStack.shape.delete.postExecute', 'connection.remove', 'connection.changed', 'shape.changed', 'shape.remove'],
                function (event, element) {
                    if(event.type !== 'shape.changed' && event.type !== "connection.changed"){
                        self.loadTable(event);
                    }
                    if (event.element && event.type == "shape.remove") {
                        var bo = event.element.businessObject;
                        var backImgUrl = bo.backgroundUrl;
                        if(backImgUrl){
                            self.deletedCodedContainers.push(backImgUrl);
                        }
                        var mid = bo.get('mid');
                        if (mid) {
                            var type = bo.$type;
                            if (self.getNumberOfMappings(mid, type, event) === 0) {
                                var modelType = self.mappingObject[type];
                                var modelColl = self.model.get(modelType.collectionName);
                                if (modelColl) {
                                    modelColl.remove(mid);
                                    /*if (self.chartViewInstance && !self.showDashboardValueStreamMap) {
                                        //self.chartViewInstance.initialize(self.model, true, self, self.getValueList());
                                    }*/
                                }
                            }
                        }
                    }
                    self.enableComplete(true);
                });
        }
        self.openDiagram(self.model.get('data'));
        //if (!self.showDashboardValueStreamMap) {
            self.chartViewInstance.selectedChartType("Table");
            self.chartViewInstance.initialize(self.model, false, self, self.getValueList());
            //self.chartViewInstance.initialize(self.model, false, self, self.getValueList());
            self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '55%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight(), onDragEnd: self.setResizeHeight });
            self.spliter.enhsplitter('collapse');
            $(window).resize(function (view, event) {
                this.timer = clearTimeout();
                this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
            });
            $('#mainTab' + self.encodeId + ' a').click(function (e) {
                e.preventDefault();
                $(this).tab('show');
                self.completeButton(true);
                if($(this)[0].className === "ChartValues"){
                    self.completeButton(false);
                }
                /*self.zoomButton(false);
                if ($(this)[0].className === "showMap") {
                    self.zoomButton(true);
                }*/
                if ($(this)[0].className === "ShapeDetails") {
                    self.loadTable();
                }
                self.setResizeHeight();
            });
            $('#rightTab' + self.encodeId + ' a').click(function (e) {
                e.preventDefault();
                if ($(this).text() === "Collaboration") {
                    return false;
                }
                $(this).tab('show');
                if ($(this).text() === "Guidance") {
                    if ($('#guidanceTab' + self.encodeId).is(':empty')) {
                        $.get('js/app/version1/views/help/views/properties/ValueStreamMapGuidanceTemplate.html',function(viewStr){
                            self.countGuidanceClicks++;
                            $('#guidanceTab' + self.encodeId).empty();
                            $('#guidanceTab' + self.encodeId).append(viewStr);
                            if (self.configObj && self.configObj.guidance) {
                                $('#guidanceTab' + self.encodeId).append(self.configObj.guidance);
                            }
                            window.utils.applyFontSize($('#guidanceTab' + self.encodeId));
                            self.setResizeHeight();
                        })
                    } else {
                        self.setResizeHeight();
                    }
                } else {
                    self.setResizeHeight();
                }
            });
            container.resizable({
                stop: _.bind(function (event, ui) {
                    if(ui.size.height >= 370){
                        var self = this;
                        self.model.set('height', ui.size.height);
                    }
                }, self)
            });	
            self.setResizeHeight();
            const dialogConfig = {
                title: "<div><img class='smallIcon' src='img/icons/icons_24.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('ValueStreamArrowVideo')+"</div>",
                videos: [
                    { id: 'iXyPW6SHiek', title: '', width: '25vw', height: '14vw',description:DataManager.getDataManager().get('localeManager').get('ValueStreamMapDescription') },
                ],
            };
            DataManager.getDataManager().handleVideoClick(dialogConfig,'ValueStreamMapVideo');
        /*} else {
            $('#splitPanels' + self.encodeId).contents().unwrap();
            $('.dashboardValueStreamMap').hide();
            $('.bjs-powered-by').hide();
            $("#js-canvasresize"+self.encodeId).hide();
            $('#mainTab' + self.encodeId).hide();
            if(self.editMode){
                self.completeButton(true);
                $('#complete' +self.encodeId).css('margin-top','-20px')
            }else{
                self.completeButton(false);
                //self.zoomButton(false);
            }
            if (redraw !== true && self.parentView && self.parentView) {// when ValueStreammap is added
                window.vdmModelView.setResizeHeight();
            }
        }*/
    }
    handlePublicMenus() {
        var self = this;
        DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
    };
    showobjectExplorerTree() {
        var self = this;
        var vdm = self.model.getNestedParent();
        var addOptions = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        window.getAndCreateModalDialog(true, id, ValueStreamMapDiagramMixin, vdm, "explorerModelJson", closeView, addOptions);
        function closeView() {
            window.cleanDialogModel(id, ValueStreamMapDiagramMixin);
        }
    };

    resizeEcoElement(shapeId, newBounds) {
        var self = this;
        var shape = self.modeler.get('elementRegistry')._elements[shapeId].element;
        self.modeler.get('modeling').resizeShape(shape, newBounds);
    };

    showActivitySuggestions(element, event) {
        var self = this;
        var capMapping = false;
        if(!element.businessObject.mid && element.businessObject.get('mcid')){
            capMapping = true;
        }
        function checkEnableComplete() {
            var selectedActivityId = $('#selectActivity option:selected').attr('activityId');
            if(capMapping){
                if (selectedActivityId!== ''){
                    $('.suggestionsComplete').removeAttr('disabled');
                }else{
                    $('.suggestionsComplete').attr('disabled', 'disabled');
                }
            }else{
                var selectedBMId = $('#selectBM option:selected').attr('bmId');
                var selectedPackageId = $('#selectPackage option:selected').attr('packageId');
                if (selectedPackageId !== '' && selectedBMId !== '' && selectedActivityId !== ''){
                    $('.suggestionsComplete').removeAttr('disabled');
                }else{
                    $('.suggestionsComplete').attr('disabled', 'disabled'); 
                }
            }
        }

        var suggestedActivitiesColl = [];
        if (!capMapping){
            var activity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: element.businessObject.mid });
            var parentValueStream = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: element.businessObject.$parent.mid });
            var capReqId = activity.get('capabilityRequirement').id;
        }else{
            capReqId = element.businessObject.get('mcid');
        }

        var currentAltId = DataManager.getDataManager().get('viewAlternative');
        var currentAlternativeModel = DataManager.getDataManager().getAlternativeSync(currentAltId);             
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        var presentType = $(event.target).hasClass('icon-right') ? "source" : "target";
        var requiredType = presentType === "source" ? "target" : "source";
        currentPlan.loadCapDependencies(function (capDependencies) {
            var capDependenciesFound = _.filter(capDependencies, function (e) { if (e.get(presentType)) { return e.get(presentType).id === capReqId; } });
            _.each(capDependenciesFound, function (capDependencyOfCapFound) {
                var targetOfCapDependency = capDependencyOfCapFound.get(requiredType);
                if (targetOfCapDependency) {
                    var displayName = capDependencyOfCapFound.get('deliverableDefinition') ? targetOfCapDependency.get('name') + "-" + targetOfCapDependency.get('description') + " (" + capDependencyOfCapFound.get('deliverableDefinition').get('name') + ")" : targetOfCapDependency.get('name') + "-" + targetOfCapDependency.get('description');
                    suggestedActivitiesColl.push({ id: targetOfCapDependency.id, name: displayName, actualName: targetOfCapDependency.get('name'), activityType: false, description: targetOfCapDependency.get('description') });
                }
            });

            //load existing activities

            if(!capMapping){
                var pack = activity.getNestedParent();
                var repId = DataManager.getDataManager().getRepositoryId(pack.id);
                var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                var version = pack.version || pack.get('version');
                DataManager.getDataManager().getAllDocumentsCollectionOfType(currentAltId, 'vdml_Activity', function (result) {
                    for (var i = 0; i < result.length; i++) {
                        if (!result.at(i).get('parent')) {
                            continue;
                        }
                        suggestedActivitiesColl.push({ id: result.at(i).id, name: result.at(i).get('name'), actualName: result.at(i).get('name'), description: result.at(i).get('description') ? result.at(i).get('description') : result.at(i).get('name'), activityType: true });
                    }
                    suggestedActivitiesColl.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        }
                        else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    var packages = _.filter(currentAlternativeModel.get('phaseDesignPart').models, function (packagesRef) { if (packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1) { return packagesRef; } });
                    var suggestedPackagesColl = [];
                    for (var i = 0; i < packages.length; i++) {
                        suggestedPackagesColl.push({ id: packages[i].id, beepReference: packages[i].get('beepReference'), name: packages[i].get('name'), actualName: packages[i].get('name'), activityType: false, description: packages[i].get('description') })
                    }

                    var selectBM = "<select class=\"form-control\" id=\"selectBM\">";
                    selectBM = selectBM + "<option selected='true' bmId=''>" + "Select Business Model ..." + "</option>";
                    selectBM = selectBM + "</select><p/>";

                    var selectPackage = "<select class=\"form-control\" id=\"selectPackage\">";
                    selectPackage = selectPackage + "<option selected='true' packageId=''>" + "Select Package ..." + "</option>";
                    _.each(suggestedPackagesColl, function name(suggestedPackage) {
                        selectPackage = selectPackage + "<option beepReference = '" + suggestedPackage.beepReference + "' packageId='" + suggestedPackage.id + "' packageActualName='" + suggestedPackage.actualName + "' packageDesc='" + suggestedPackage.description + "'>" + suggestedPackage.name + "</option>";
                    });
                    selectPackage = selectPackage + "</select><p/>";
                    fillActivitiesInBootbox(selectBM, selectPackage);
                    $('#selectBM').on('change', function (e) {
                        checkEnableComplete();
                        var selectedBM = $("option:selected", this);

                        //TODO show info button
                    });
                    $('#selectPackage').on('change', function (e) {
                        checkEnableComplete();
                        var packageSelected = $("option:selected", this);
                        var beepReference = $('#selectPackage').children()[this.selectedIndex].getAttribute('beepReference');
                        var packageModel = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: beepReference });
                        if (packageModel) {
                            var BMs = packageModel.get('businessModel').models;
                        }
                        $('#selectBM').children().remove();
                        var option = document.createElement('option');
                        option.setAttribute('selected', 'true');
                        option.setAttribute('bmId', '');
                        option.innerText = 'Select Business Model...';
                        $('#selectBM').append(option);
                        if (BMs) {
                            for (i = 0; i < BMs.length; i++) {
                                option = document.createElement('option');
                                option.setAttribute('bmId', BMs[i].get('id'));
                                option.innerText = BMs[i].get('name');
                                $('#selectBM').append(option);
                            }
                        }

                        //TODO show info button
                    });

                }, false, null, false);
            }else{
                fillActivitiesInBootbox();
            }

            function fillActivitiesInBootbox(selectBM, selectPackage) {
                var htmlContent = "";
                if (selectBM){
                    htmlContent = selectPackage + selectBM;
                }
                htmlContent = htmlContent + "<select class=\"form-control\" id=\"selectActivity\">";
                htmlContent = htmlContent + "<option selected='true' activityId=''>" + "Select Activity ..." + "</option>";
                _.each(suggestedActivitiesColl, function name(activity) {
                    htmlContent = htmlContent + "<option activityId='" + activity.id + "' activityType='" + activity.activityType + "' activityActualName='" + activity.actualName + "' activityDesc='" + activity.description + "'>" + activity.name + "</option>";
                });
                htmlContent = htmlContent + "</select><p/>";

                var suggestionDialog = bootbox.dialog({
                    title: "Select Activity",
                    message: htmlContent,
                    show: false,
                    className: "modalMedium resizeBox",
                    buttons: {
                        main: {
                            label: "Close",
                            className: "btn btn-default",
                        },
                        success: {
                            label: '<i id="CompleteBtn" class="fa fa-check"></i> Complete',
                            className: "btn btn-complete suggestionsComplete",
                            callback: checkRevisionForShape
                        }
                    }
                });
                suggestionDialog.modal('show');
                $('#selectActivity').on('change', function (e) {
                    checkEnableComplete();
                    var optionSelected = $("option:selected", this);
                    var valueSelected = this.value;
                    //TODO show info button
                });
                $('.suggestionsComplete').attr('disabled', 'disabled');
            }
            
            function checkRevisionForShape(){
                pack = self.model.getNestedParent();
                var selectedActivityId = $('#selectActivity option:selected').attr('activityId');
                var selectedBMId = $('#selectBM option:selected').attr('bmId');
                var selectedPackageId = $('#selectPackage option:selected').attr('packageId');
                var activityActualName = $('#selectActivity option:selected').attr('activityActualName');
                var activityDesc = $('#selectActivity option:selected').attr('activityDesc');
                var altId = DataManager.getDataManager().getRepositoryId(pack.id);
                if (altId !== currentAlternativeModel.get('id')) {
                    self.createRevisionBasedOnContext(pack, function (newPackage) {
                        var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                        var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
                        var valueStreamMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
                        createActivityShape(newPackage, selectedActivityId, selectedBMId, selectedPackageId, activityActualName, activityDesc);
                        window.utils.stopSpinner('revisionSpinner');
                        self.model = valueStreamMap;
                        self.saveDiagram(null, null, null, valueStreamMap, newPackage, false);
                        
                        //DataManager.getDataManager().get('router').navigate("views/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + valueStreamMap.id + "/" + valueStreamMap.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                        
                    });
                } else {
                    createActivityShape(pack, selectedActivityId, selectedBMId, selectedPackageId, activityActualName, activityDesc);
                }
            }

            function createActivityShape(newPackage, selectedActivityId, selectedBMId, selectedPackageId, activityActualName, activityDesc) {
                if(newPackage){
                    pack = newPackage;
                }
                if (!selectedActivityId) {
                    return;
                }
                if(!capMapping){
                    if (!selectedBMId || !selectedPackageId) {
                        return;
                    }
                    var packages = _.filter(currentAlternativeModel.get('phaseDesignPart').models, function (packagesRef) { if (packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1) { return packagesRef; } });
                    for (var i = 0; i < packages.length; i++) {
                        if (packages[i].id == selectedPackageId) {
                            pack = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: packages[i].get('beepReference') });
                            break;
                        }
                    }
                    if (!pack){
                        return;
                    }
                    //selectedBMId = selectedBMId.indexOf
                    var bm = pack.get('businessModel').findWhere({id: selectedBMId});
                    var newAltId = window.utils.getPrefix(pack.id);
                    var revisedBMId = newAltId + selectedBMId.substr(8);
                    if(!bm){
                        bm = pack.get('businessModel').findWhere({id: revisedBMId});
                        var revised = true;
                    }
                    if(!bm){
                        return;
                    }
                }
                //var activityActualName = $('#selectActivity option:selected').attr('activityActualName');
                //var activityDesc = $('#selectActivity option:selected').attr('activityDesc');
                var activityName;
                if (activityActualName !== '' && activityDesc !== '' && activityActualName !== activityDesc) {
                    activityName = activityActualName + "-" + activityDesc;
                } else {
                    activityName = activityActualName;
                }
                var selectedActivityType = $('#selectActivity option:selected').attr('activityType');
                var elementFactory = self.modeler.get('elementFactory');
                var modeling = self.modeler.get('modeling');
                var eventBus = self.modeler.get('eventBus');
                function createShapeAndConnection(activityId, capId) {
                    var shape = elementFactory.createShape({ type: 'vdml:Activity' });
                    var parentShape = element.parent;//to make arrows visible, make them children of value stream map rather than valuestream
                    while (parentShape.type !== 'vdml:ValueStreamMap') {
                        parentShape = parentShape.parent;
                    }
                    if (presentType === "source") {
                        modeling.createShape(shape, { x: element.x + 150, y: element.y, width: element.width, height: element.height }, element.parent);
                        modeling.createConnection(element, shape, { type: 'vdml:DeliverableFlow' }, parentShape, null);
                    } else if (presentType === "target") {
                        modeling.createShape(shape, { x: element.x - 150, y: element.y, width: element.width, height: element.height }, element.parent);
                        modeling.createConnection(shape, element, { type: 'vdml:DeliverableFlow' }, parentShape, null);
                    }
                    if(activityId){
                        shape.businessObject.set('mid', activityId);
                        shape.businessObject.set('mpid', pack.id);
                    }else if(capId){
                        shape.businessObject.set('mcid', capId);
                        var capModelToBeLinked = self.getCapabilityElementModel(capId);
                        var capPackId = capModelToBeLinked.getNestedParent().id;
                        shape.businessObject.set('mcpid', capPackId);
                    }
                    
                    shape.businessObject.set('name', activityName);
                    shape.businessObject.set('description', activityDesc);
                    eventBus.fire('elements.changed', {
                        elements: [shape]
                    });
                }
                if(capMapping){
                    createShapeAndConnection(null, selectedActivityId);
                }else if (selectedActivityType === "false") {
                    //createActivity with this competence name
                    var performerRole = activity.get('performingRole');
                    var capModelToBeLinked = _.find(self.competenciesFromLib , function (e) { return e.get('id') === selectedActivityId; });
                    bm.addBMActivities(activityName, activityDesc, performerRole, currentAlternativeModel, function (newActivity) {
                        selectedActivityId = newActivity.id;
                        parentValueStream.get('valueStreamActivity').add(newActivity);
                        newActivity.set('capabilityRequirement', capModelToBeLinked);
                        createShapeAndConnection(newActivity.id);
                    });
                } else {
                    if(revised){
                        selectedActivityId = newAltId + selectedActivityId.substr(8);
                    }
                    createShapeAndConnection(selectedActivityId);
                }
            }
        });
    };

    getCapabilityElementModel(id) {
        if (!id) {
            return null;
        }
        var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
        if (!capModel) {
            capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
        }
        return capModel;
    }

    /*this.mapCapabilities = function (element) {
        var id = htmlEscape(window.guidGenerator());
        var options = { capabilityLibs: self.capabilityLibs, callback: mapCapability };
        window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);

        function mapCapability(capId, capName, capDesc, capParentId) {
            if (!capId) {
                return;
            }
            function setCapId(newEcoMap) {
                element.businessObject.set('mcid', capId);
                element.businessObject.set('mcpid', capParentId);
                self.model.get('capability').add(self.getCapabilityElementModel(capId));
                if (!element.businessObject.get('name') || element.businessObject.get('name') == "") {
                    element.businessObject.set('name', capName);
                    element.businessObject.set('description', capDesc);
                }
                if (!newEcoMap) {
                    self.modeler.get('eventBus').fire('elements.changed', {
                        elements: [element]
                    });
                }
            }
            if (window.checkContextForRevision()) {
                var pack = self.model.getNestedParent();
                self.createRevisionBasedOnContext(pack, function (newPackage) {
                    var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                    var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
                    var valueStreamMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
                    window.utils.stopSpinner('revisionSpinner');
                    self.model = valueStreamMap;
                    setCapId(valueStreamMap);
                    self.saveDiagram(null, null, null, valueStreamMap, newPackage, false);
                });
            } else {
                setCapId();
                self.saveDiagram();
            }
            
        }
    };*/
			
            
    init(model, options){
        var self = this;
        this.afterRenderView = _.bind(this.afterRenderView,self);
        this.ValueStreamMapDesignerViewModel = this;
        self.model = model;
        self.data = model.get('data');
        this.enableComplete = ko.observable(false);
        this.shapesList = ko.observableArray([]);
        this.unsortedShapesList = [];
        this.deletedCodedContainers = [];
        this.completeButton = ko.observable(true);
        //this.zoomButton = ko.observable(true);
        this.children = [];
        this.mappingObject = {};
        this.mappingObject['vdml:Capability'] = { name: 'Capability', collectionName: 'competence' };
        this.mappingObject['vdml:Competency'] = { name: DataManager.getDataManager().get('localeManager').get('Competency'), collectionName: 'competence' };
        this.mappingObject['vdml:Activity'] = { name: DataManager.getDataManager().get('localeManager').get('Activity'), collectionName: 'activity' };
        this.mappingObject['vdml:ValueStream'] = { name: DataManager.getDataManager().get('localeManager').get('ValueStream'), collectionName: 'valueStream' };
        this.mappingObject['vdml:CapabilityContainer'] = { name: DataManager.getDataManager().get('localeManager').get('CompetencyContainer'), collectionName: '' };
        //this.showDashboardValueStreamMap = false;
        this.encodeId = window.utils.htmlEscape(self.model.id);
        this.editMode = DataManager.getDataManager().get('readMode')?false:true;
        if(window.utils.checkIsLinked(null,true)){
            self.editMode = false;
        }
        this.modeler = null;
        this.showProperties = _.bind(self.showProperties,self);

        if (options.parentView){
            this.parentView = options.parentView;
        }
        /*if (options.parentView && options.parentView == "Dashboard") {
            this.showDashboardValueStreamMap = true;
            this.editMode = options.editMode;
            if (!ko.components.isRegistered('StrategyValuesChart')) {
                ko.components.register("StrategyValuesChart", {
                    viewModel: { instance: self.chartViewInstance },
                    template: '<div></div>'
                });
            }

            if (!ko.components.isRegistered('ValueStreamMapDetails')) {
                ko.components.register("ValueStreamMapDetails", {
                    viewModel: ValueStreamMapDetailsViewModel,
                    template: '<div></div>'
                });
            }

        } else {*/
            self.chartViewInstance = StrategyMapValuesChart.getInstance(this.model);
            self.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);
            if (!ko.components.isRegistered('StrategyValuesChart')) {
                ko.components.register("StrategyValuesChart", {
                    viewModel: { instance: self.chartViewInstance },
                    template: window.utils.getHtmlPage("StrategyMapValuesChartTemplate"),
                    synchronous: true
                });
            }

            if (!ko.components.isRegistered('ValueStreamMapDetails')) {
                ko.components.register("ValueStreamMapDetails", {
                    viewModel: ValueStreamMapDetailsViewModel,
                    template: window.utils.getHtmlPage("ValueStreamMapDetailsTemplate"),
                    synchronous: true
                });
            }
        //}

        if (!ko.components.isRegistered('ValueStreamMapGuidance')) {
            self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
            ko.components.register("ValueStreamMapGuidance", {
                viewModel: { instance: self.guidanceViewInstance },
                template: '<div id="guidanceTab' + self.encodeId + '"></div>',
                synchronous: true
            });
        }
        this.tempCapbilityIdList = [];
        this.capabilityLibs = [];
                //this.allCapabilities = [];
        //if (!self.showDashboardValueStreamMap) {
            self.fetchBmCounts();
        //}

        if (!self.data) {
            self.data = window.utils.getXmlData(model.get('type').replace("_", "."), window.utils.htmlEscape(model.id));
            self.model.set('data',self.data);
        }
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Delete'
            , 'Complete'
            , 'Copy'
            , 'ValueStreamMapDetails'
            , 'ValueStreamMap'
            , 'Guidance'
            , 'Collaboration'
            , 'shapeDetails'
            , 'Values'
            , 'Shape'
            , 'ElementType'
            , 'targetElement'
            , 'OpenProcess'
            , 'GenerateProcess'
            , 'PresentationType'
        ]);

    }

    static getInstance = function (model, options) {
        var view = new ValueStreamMapDesignerViewModel(model, options);
        view.init(model, options);
        return view;

    };
}
path.ValueStreamMapDesignerViewModel = ValueStreamMapDesignerViewModel;
