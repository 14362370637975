import * as $ from 'jquery';
import * as ko from 'knockout';
import * as kb from 'knockback';
import { DataManager } from '../../../../../com/vbee/data/DataManager';
import * as bootbox from '../../../../../../libs/bootbox/bootbox';
import {UserMixin} from '../../../../bo/tickets/UserMixin';

var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties",global.version);

export class CoursesViewModel {

	afterRenderView(node,view) {
		var self = view;
		if(self.options.selectedId){
		   $('#CoursesTable').show();
		}
	};

	init(model, options) {
		var self = this;
		this.CoursesViewModel = this;
		this.model = model;
		this.options = options;
		this.modId = ko.observable('');
		var dataManager = DataManager.getDataManager();
		this.licenses = ko.observableArray(dataManager.licenses);
		var subs = DataManager.getDataManager().get('subscribed');
		var proId;
		subs.map((data) => {
			if(data.packageType === 14) {
				proId = data.id;
			}
		})
		if(proId !== undefined && proId !== null) {
			DataManager.getDataManager().getMarketPlaceProductDetails(proId)
		}
		this.courses = ko.observableArray([
            { name: "Strategyplanner Basics Course", pur: false },
            // { name: "Strategyplanner Advanced Course", pur: false }
        ]);
        this.labels = {
			Close: "Close"
		};
		for (var i = 0; i < this.licenses().length; i++) {
			if (this.licenses()[i].ouuserId !== null) {
			  this.courses().map(data => {
				data.pur = true;
			  });
			}
		}
	}
	
	navigate(){
		var self = this;
		window.cleanDialogModel(self.modId,self);
		localStorage.setItem('marketplaceDetails', JSON.stringify({ details: true, marketplaceId: "0192F6C9-6836-C50D-4DFC-2CB5E4344DF2" }));
		DataManager.getDataManager().get('router').navigate("adminviews/" + "Market" , { trigger: true });
	}

	launch(){
		window.open(`http://localhost:8089/auth/oidc/`, "_blank");
	}

	showDemo(){
        var id = window.utils.htmlEscape(window.guidGenerator());
        var user;
        var options = { viewId: id , selectedId: 'demoCourse', 'width': '1000px' };
        window.getAndCreateModalDialog(false, id, UserMixin, user, "demoCourse", null, options);
    }
	
	cleanModal(){
		var self=this;
		window.cleanDialogModel(self.encodeId,self);
	};	  

    static getInstance(model, options) {
        const view = new CoursesViewModel(model, options);
		view.init(model, options);
        return view;
    }
}
path.CoursesViewModel = CoursesViewModel;