import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as Highcharts from 'highcharts';
//import * as highchartsmore from 'highcharts-more'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { BusinessModelMixin } from '../../../../../version1/bo/vdml/BusinessModelMixin'
import { DashboardMixin } from '../../../../../version1/bo/dashboard/DashboardMixin'
import { Dashboard } from '../../../../../version1/bo/dashboard/Dashboard'
import { Presentation } from '../../../../../version1/bo/dashboard/Presentation'
//import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
import { Analytics } from '../../../../../version1/bo/dashboard/Analytics'
import { PackageReference } from '../../../../../com/vbee/filesystem/PackageReference'
import { HighChartThemes } from '../../../../../com/vbee/utils/HighChartThemes'
//import {PageResize } from '../../../../../../libs/DataTable/dataTables.PageResize'
import { DataTable } from '../../../../../../libs/DataTable/jquery.dataTables'
import * as async from 'async'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead","appbo/dashboard/DashboardMixin", "appbo/vdml/BusinessModelMixin", "appbo/dashboard/Dashboard", "appbo/dashboard/Presentation", "appbo/dashboard/Analytics","appbo/transformation/PackageReference","appcommon/com/vbee/filesystem/PackageReference", "appcommon/com/vbee/utils/HighChartThemes", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize", "highcharts", "highcharts-more"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, DashboardMixin, BusinessModelMixin, Dashboard, Presentation, Analytics,PlanPackageReference,PackageReference, HighChartThemes)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class DashboardDetailsViewModel {
	dispose(){
		var self = this;
		this.parentView = null;
		if(self.dataTable){
			self.dataTable.destroy(true);
		}
		if(self.model){
			self.model.set('selectedTheme', self.selectedTheme());
		}
		window.cleanViewModel(self);		
	};


	afterRenderView() {
	};

	fetchDashboardTimeStampsList(){
		var self = this;
		var scenarioConfig = self.model.get('config');
		var dashboardTimeStamps = [];
		if(scenarioConfig !== undefined){
			var obj = JSON.parse(scenarioConfig);
			for(var alt in obj){
				dashboardTimeStamps = dashboardTimeStamps.concat(obj[alt]);
			}
			return dashboardTimeStamps;
		}else {
			return dashboardTimeStamps;
		}
	};
	
	saveDashboard() {
		var self = this;
		if(self.name().trim() != self.model.get('name') && self.name().trim() != ''){
			self.model.set('name',self.name().trim());
			window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.DashboardDetailsViewModel);
		}
		self.model.set('description', self.description());
	};
	
	createRevisionBasedOnContext(pack,createRevision,callback){
		if(window.checkContextForRevision() && createRevision) {
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive',false);
						window.utils.stopSpinner('revisionSpinner');
						callback(newVdmPackage);
						/*DataManager.getDataManager().saveData({
							success:function(){
								window.utils.stopSpinner('revisionSpinner');
								callback(newVdmPackage);
							}, 
							error:function(){
								window.utils.stopSpinner('revisionSpinner');
								callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
	importMetricsCSV() {
		var self = this;
		//DataManager.getDataManager().get('currentPlan').checkScenarioLimit(function(limitexceeded){
			var id = window.utils.htmlEscape(window.guidGenerator()) + "importData";
			var addOptions = {'parentModal':self.parentView};
			window.getAndCreateModalDialog(self,id,DashboardMixin,self.model,"ImportData",null,addOptions);
		//});
	};
	fetchBmValues(plan,valuesArray, sceName) {
		var phase = plan.get('phase');
		var sigDec = '2';
		for (var p = 0; p < phase.length; p++) {
			var models = phase.at(p).get('phaseAlternative').at(0).get('phaseDesignPart');
			var bmValues = []; var minRange; var maxRange;
			for (var m = 0; m < models.length; m++) {
				if (models.at(m).get('beepType') === "vdml_ValueDeliveryModel" && models.at(m).get('name') != "commonScenario") {
					var beepReference = models.at(m).get('beepReference');
					var bmModel = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: beepReference });
					for (var j = 0; j < bmModel.get('businessModel').models.length; j++) {
						var bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: bmModel.get('businessModel').models[j].id });
						bm.getValues(bmValues, true);
						for (var v = 0; v < bmValues.length; v++) {
							if (bmValues[v].get('valueMeasurement').get('measurement').length > 0 && bmValues[v].get('valueMeasurement').get('measurement').at(0).get('type') === "smm_CollectiveMeasurement") {
								var value = bmValues[v].get('valueMeasurement').get('measurement').at(0).get('value');
								if (sceName) {
									var found = _.filter(bmValues[v].get('valueMeasurement').get('measurement').models, function (mod) { if (mod.get('observedMeasure').get('observation').get('name') === sceName) return sceName })
									if (found && found.length > 0) {
										var measure = bmValues[v].get('valueMeasurement').get('measurement');
										if (measure && measure.length > 0) {
											minRange = measure.at(0).get('minRange');
											maxRange = measure.at(0).get('maxRange');
											var m = measure.at(0).get('observedMeasure').get('measure') ? measure.at(0).get('observedMeasure').get('measure').get('unit') : "";
											sigDec = m ? m.get('significantDecimals') : sigDec
										}
										if (!bmValues[v].get('minRange') && !bmValues[v].get('maxRange') && bmValues[v].get('valueDefinition')) {
											var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueCategory').find({ id: bmValues[v].get('valueDefinition').id });
											if (!valueDef) {
												valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: bmValues[v].get('valueDefinition').id });
											}
											if (valueDef && valueDef.get('minRange') && valueDef.get('maxRange')) {
												minRange = valueDef.get('minRange') ? valueDef.get('minRange') : "-";
												maxRange = valueDef.get('maxRange') ? valueDef.get('minRange') : "-";
											}
										}
										if (!minRange && !maxRange) {
											minRange = "-";
											maxRange = '-'
										}
										valuesArray.push({ id: bmValues[v].get('valueMeasurement').get('measurement').at(0).id, name: bmValues[v].get('name'), type: bmValues[v].get('type'), parent: bmValues[v].id, phaseName: phase.at(p).get('name'), value: parseFloat(value).toFixed(sigDec), minRange: minRange, maxRange: maxRange })
									}
								} else {
									var minRange = bmValues[v].get('minRange'); var maxRange = bmValues[v].get('maxRange');
									if (!bmValues[v].get('minRange') && !bmValues[v].get('maxRange') && bmValues[v].get('valueDefinition')) {
										var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueCategory').find({ id: bmValues[v].get('valueDefinition').id });
										if (!valueDef) {
											valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: bmValues[v].get('valueDefinition').id });
										}
										if (valueDef && valueDef.get('minRange') && valueDef.get('maxRange')) {
											minRange = valueDef.get('minRange') ? valueDef.get('minRange') : "-";
											maxRange = valueDef.get('maxRange') ? valueDef.get('minRange') : "-";
										}
									}
									if (!minRange && !maxRange) {
										minRange = "-";
										maxRange = '-'
									}
									valuesArray.push({ id: bmValues[v].get('valueMeasurement').get('measurement').at(0).id, name: bmValues[v].get('name'), type: bmValues[v].get('type'), parent: bmValues[v].id, phaseName: phase.at(p).get('name'), value: parseFloat(value).toFixed('2'), minRange: minRange, maxRange: maxRange })
								}
								
							}

						}
					}
				}
			}
		}
	}
	fetchPlanValues(plan, valuesArray, sceName) {
		var planMetrics = plan.get('planMeasureLibrariesContainer').at(0);
		var sigDec = '2';
		var observations = planMetrics ? planMetrics.get('observations') : [];
		for (var i = 0; i < observations.length; i++) {
			var observedMeasures = observations.at(i).get('observedMeasures');
			if (sceName && sceName === observations.at(i).get('name') || !sceName) {
				var phaseName = observations.at(i).get('phaseObjectiveSet').get('phaseObjectiveSetOwner').get('phaseAlternativeOwner').get('name')
				for (var j = 0; j < observedMeasures.length; j++) {
					var measuremnet = observedMeasures.at(j).get('measurements').at(0);
					if (measuremnet && measuremnet.get('type') === 'smm_CollectiveMeasurement') {
						var model = measuremnet.get('measuredCharacteristic').get('valueMeasurementOwner');
						var obMeasure = measuremnet.get('observedMeasure').get('observation');
						if (obMeasure && obMeasure.get('id') === observations.at(i).id) {
							var minRange = measuremnet.get('minRange');
							var maxRange = measuremnet.get('maxRange');
						}
						
						if (!maxRange && !minRange && model.get('valueDefinition')) {
							var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueCategory').find({ id: model.get('valueDefinition').id });
							if (!valueDef) {
								valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: model.get('valueDefinition').id });
							}
							if (valueDef && valueDef.get('minRange') && valueDef.get('maxRange')) {
								minRange = valueDef.get('minRange') ? valueDef.get('minRange') : "-";
								maxRange = valueDef.get('maxRange') ? valueDef.get('maxRange') : "-";
							}
						}
						if (!minRange && !maxRange) {
							minRange = "-";
							maxRange = "-";
						}
						var value = measuremnet.get('value');
						var measure = measuremnet.get('observedMeasure').get('measure') ? measuremnet.get('observedMeasure').get('measure').get('unit') : "";
						sigDec = measure ? measure.get('significantDecimals') : sigDec
						valuesArray.push({ id: measuremnet.id, name: model.get('name'), type: model.get('type'), parent: model.id, phaseName: phaseName, value: parseFloat(value).toFixed(sigDec), minRange: minRange, maxRange: maxRange });
					}
				}
			}
		}
		return valuesArray
	}
	addDomainField(id,check,domain) {
		var pEvent = check ? 'auto' : 'none';
		var content = "<td><select style='pointer-events : " + pEvent + "' id=" + window.utils.htmlEscape(id)+" name='domainOptions'><option id='any' value='Any'>Any</option>";
			content += "<option id='binary' value='Binary'>Binary</option><option id='boolean' value ='Boolean'>Boolean</option>",
			content += "<option id = 'integer' value='Integers'>Integers</option>",
			content += "<option id='nInterger' value='NegativeIntegers'>NegativeIntegers</option><option id='nReals' value='NegativeReals'>NegativeReals</option>",
			content += "<option id='nonNegIntegers' value='NonNegativeIntegers' selected>NonNegativeIntegers</option><option id='nonNegReals' value='NonNegativeReals'>NonNegativeReals</option>",
			content += "<option id='nonPosIntegers' value='NonPositiveIntegers'>NonPositiveIntegers</option><option id='nonPosReals' value='NonPositiveReals'>NonPositiveReals</option>",
			content += "<option id='pIntegers' value='PositiveIntegers'>PositveIntegers</option><option id='pReals' value='PositiveReals'>PostiveReals</option>",
			content += "<option id='reals' value='Reals'>Reals</input>",
			content += "</select></td></tr>";

		return content;
	}
	showallValues() {
		var self = this;
		var valuesArray = []; 
		var plan = DataManager.getDataManager().get('currentPlan');
		

		self.observations.removeAll();
		var content = "<div><div class='col-xs-1'><span style='margin-left:-16px'>Scenario</span></div>";
		content += "<div class='col-xs-7'><select style='margin-left:60px' id='selectScenario'>";
		var alts, observationModels, currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({ previousPhase: null });
		self.timeStampArray = self.fetchDashboardTimeStampsList();
		while (phase) {
			alts = phase.get('phaseAlternative').models;
			for (var i = 0; i < alts.length; i++) {
				observationModels = alts[i].getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
				for (var j = 0; j < observationModels.length; j++) {
					var name = observationModels[j].get('name');
					var timeStamp = observationModels[j].get('whenObserved');
					
					var whenObservedGuid = observationModels[j].get('whenObservedGuid');
					if (timeStamp && _.contains(self.timeStampArray, whenObservedGuid) || !timeStamp) {
						self.observations.push({ 'name': name, 'id': observationModels[j].get('id'), 'timeStamp': timeStamp, 'escapeId': window.utils.htmlEscape(observationModels[j].get('id')) });
					}
				}
			}
			phase = phase.get('nextPhase');
		}
		var observations = _.uniq(self.observations(), function (obs) { return obs.timeStamp; });
		for (var ob = 0; ob < observations.length; ob++) {
			content += "<option id=" + observations[ob].id + ">" + observations[ob].name + "</option>";
		}
		content += "</select></div></div></br></br></br>";
		content += "<div><button id='showDomain"+window.utils.htmlEscape(self.encodeId)+"' style='margin-top:-70px;float:right'>Show Domain</button></div>"
		valuesArray = self.fetchPlanValues(plan, valuesArray, observations[0].name)
		self.fetchBmValues(plan, valuesArray, observations[0].name)
		
		
		content += "<table id='myTable' class='table table-bordered'>";
		content += "<thead><tr id='myTableRow'><th>Value Name</th><th>Phase</th><th>Value</th><th>Min Bound</th><th>Max Bound</th></tr></thead>";
		content += "<tbody id='valueContent'>";
		for (var i = 0; i < valuesArray.length; i++) {
			content += "<tr id=" + window.utils.htmlEscape(valuesArray[i].id) + " parentId=" + valuesArray[i].parent + "><td>" + valuesArray[i].name + "</td><td>" + valuesArray[i].phaseName + "</td>"
			content += "<td id='value" + window.utils.htmlEscape(valuesArray[i].id) + "'>" + valuesArray[i].value + "</td><td contenteditable='true' class='minChange' id =" + valuesArray[i].id + " parentId=" + valuesArray[i].parent + ">" + valuesArray[i].minRange + "</td>"
			content += "<td contenteditable='true' class = 'maxChange' id=" + valuesArray[i].id + " parentId=" + valuesArray[i].parent + ">" + valuesArray[i].maxRange + "</td>";
		}
		content += "</tbody></table>";
		var dialogBox = bootbox.dialog({
			title: '<img src="img/Small/icons-15.png">  List of values',
			className: "dialogWide",
			message: content,
			buttons: {
				main: {
					label: "Close",
					className: "btn btn-default",
				},
				Optimise: {
					label: "Optimise",
					className: "btn btn-complete optimiseValues",
					callback: function () {
						self.pythonModel();
						return false;
					}
				},
				success: {
					label: '<i class="fa fa-check"></i> Complete',
					className: "btn btn-complete saveBounds",
					callback: function () {
						var child = $('.minChange'); 
						for (var i = 0; i < child.length; i++) {
							var model = window.utils.getElementModel(child[i].getAttribute('parentId'),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
							if (model) {
								var measure = model.get('valueMeasurement').get('measurement');
								if (measure && measure.length === 1) {
									measure.at(0).set('minRange', child[i].innerText);
								} else if (measure && measure.length > 1) {
									for (var m = 0; m < measure.length; m++) {
										var observation = measure.at(m).get('observedMeasure').get('observation');
										var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: window.utils.getPrefix(measure.at(m).id) })
										if (!alt && observation.get('phaseObjectiveSet')) {
											alt = observation.get('phaseObjectiveSet').get('phaseObjectiveSetOwner');
										}
										var phase = alt.get('phaseAlternativeOwner');
										var found = _.filter(valuesArray, function (value) { return value.id === child[i].id });
										if (found && found.length > 0 && phase.get('name') === found[0].phaseName && $('#selectScenario').val() === observation.get('name')) {
											measure.at(m).set('minRange', child[i].innerText);
										}
									}
								}
								if (model.get('valueDefinition') && !model.get('valueDefinition').get('domain')) {
									model.get('valueDefinition').set('domain', $('select[name="domainOptions"]').val())
								}
							}
						}
						var maxChild = $('.maxChange');
						for (var i = 0; i < maxChild.length; i++) {
							var model = window.utils.getElementModel(maxChild[i].getAttribute('parentId'),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
							if (model) {
								var measure = model.get('valueMeasurement').get('measurement');
								if (measure && measure.length === 1) {
									measure.at(0).set('maxRange', maxChild[i].innerText);
								} else if (measure && measure.length > 1) {
									for (var m = 0; m < measure.length; m++) {
										var observation = measure.at(m).get('observedMeasure').get('observation');
										var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: window.utils.getPrefix(measure.at(m).id) })
										if (!alt && observation.get('phaseObjectiveSet')) {
											alt = observation.get('phaseObjectiveSet').get('phaseObjectiveSetOwner');
										}
										var phase = alt.get('phaseAlternativeOwner');
										var found = _.filter(valuesArray, function (value) { return value.id === maxChild[i].id });
										if (found && found.length > 0 && phase.get('name') === found[0].phaseName && $('#selectScenario').val() === observation.get('name')) {
											measure.at(m).set('maxRange', maxChild[i].innerText);
										}
									}
								}
								
								if (model.get('valueDefinition') && !model.get('valueDefinition').get('domain')) {
									model.get('valueDefinition').set('domain', $('select[name="domainOptions"]').val())
								}
							}
						}
					}
				}
			}
		});
		$('.minChange').on('keyup', function () {
			var val = event.currentTarget.value; 
			var sibling = $('.maxChange');
			var maxChange;
			for (var i = 0; i < sibling.length; i++) {
				if (sibling[i].id === event.currentTarget.id) {
					maxChange = sibling[i];
					break;
				}
			}
			$('.optimiseValues').addClass('disabled');
			$('.saveBounds').addClass('disabled');
			if (val && val.trim() && maxChange && maxChange.textContent && maxChange.textContent.trim()) {
				$('.optimiseValues').removeClass('disabled');
				$('.saveBounds').removeClass('disabled');
			}
		})
		$('.maxChange').on('keyup', function () {
			var val = event.currentTarget.textContent;
			var sibling = $('.minChange');
			var minChange;
			for (var i = 0; i < sibling.length; i++) {
				if (sibling[i].id === event.currentTarget.id) {
					minChange = sibling[i];
					break;
				}
			}
			$('.optimiseValues').addClass('disabled');
			$('.saveBounds').addClass('disabled');
			if (val && val.trim() && minChange && minChange.textContent && minChange.textContent.trim()) {
				$('.optimiseValues').removeClass('disabled');
				$('.saveBounds').removeClass('disabled');
			}
		})
		$('#selectScenario').on('change', function () {
			valuesArray.length = 0;
			$('#domainField').remove()
			var val = $("#selectScenario option:selected")[0].innerText;
			valuesArray = self.fetchPlanValues(plan, valuesArray, val)
			self.fetchBmValues(plan, valuesArray, val)
			var htmlContent = "";
			for (var i = 0; i < valuesArray.length; i++) {
				htmlContent += "<tr id=" + valuesArray[i].id + "><td>" + valuesArray[i].name + "</td><td>" + valuesArray[i].phaseName + "</td>"
				htmlContent += "<td>" + valuesArray[i].value + "</td><td contenteditable='true' class='minChange' id =" + valuesArray[i].id + " parentId=" + valuesArray[i].parent + ">" + valuesArray[i].minRange + "</td>"
				htmlContent += "<td contenteditable='true' class = 'maxChange' id=" + valuesArray[i].id + " parentId=" + valuesArray[i].parent + ">" + valuesArray[i].maxRange + "</td>";
				
				
			}
			$('#valueContent').empty();
			$('#valueContent').append(htmlContent)
		});
		$('#showDomain' + self.encodeId).on('click', function () {
			if ($('#domainField') && $('#domainField').length === 0) {
				var head = "<th id='domainField'>Domain</th>";
				$('#myTableRow').append(head);
				var tbody = $('#valueContent').children();
				for (var i = 0; i < tbody.length; i++) {
					var content = "";
					var model = window.utils.getElementModel(tbody[i].getAttribute('parentid'),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
					if (model && model.get("valueDefinition")) {
						content += self.addDomainField(tbody[i].id, true);
					} else {
						content += self.addDomainField(tbody[i].id);
					}
					var hmtl = $(content);
					tbody[i].append(hmtl[0])
				}
			}
		})
	}
	pythonModel() {
		var self = this;
		self.observations.removeAll();
		var date = new Date();
		var dataGuid = DataManager.getDataManager().guidGenerator();
		dataGuid = dataGuid.replaceAll(/[@#-]/g,'_');
		self.timeStampArray = self.fetchDashboardTimeStampsList();

		var htmlContent = "<div class=\"exportClass\"><fieldset class=\"fieldsetClass\"><legend class=\"legendClass\">"+DataManager.getDataManager().get('localeManager').get('Include')+":</legend><input type=\"checkbox\" id='" + dataGuid + "ValueMeasurements' value='Value Measurements' checked disabled style=\"cursor: pointer;\" >&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "ValueMeasurements'>Value Measurements</label><br>";
		htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "RecipientMeasurements' value='Recipient Measurements' checked  disabled style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "RecipientMeasurements'>Recipient Opinion Measurements</label><br>";
		htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "SatisfactionLevelMeasurements' value='Satisfaction Level Measurements' checked  disabled style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "SatisfactionLevelMeasurements'>Satisfaction Measurements</label><br>";
		htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "PercentageWeightMeasurements' value='Percentage Weight Measurements' checked  disabled style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "PercentageWeightMeasurements'>Weight Measurements</label></fieldset></div>";
		htmlContent = htmlContent + "<br><div class=\"exportClass\"><label>"+DataManager.getDataManager().get('localeManager').get('selectScenario')+"</label><br>";

		var alts, observationModels, phases = DataManager.getDataManager().get('currentPlan').get('phase').models;
		async.eachSeries(phases, function (phase, phaseHandled) {
			alts = phase.get('phaseAlternative').models;
			async.eachSeries(alts, function (alt, altHandled) {
				observationModels = alt.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
				async.eachSeries(observationModels, function (observation, obsHandled) {
					if (self.timeStampArray.indexOf(observation.get('whenObserved')) > -1 || !observation.get('whenObserved') || !isNaN(observation.get('whenObserved'))) {
						var name = observation.get('name');
						var timeStamp = observation.get('whenObserved');
						var timeStampGuid = observation.get('whenObservedGuid');
						self.observations.push({ 'name': name, 'id': observation.get('id'), 'timeStamp': timeStamp, 'timeStampGuid': timeStampGuid, 'escapeId': window.utils.htmlEscape(observation.get('id')), checked: false });
					}
					obsHandled();
				}, function () {
					altHandled();
				});
			}, function () {
				phaseHandled();
			});
			//self.observations().sort(sortByName);
		}, function () {
			self.observations(_.uniq(self.observations(), function (obs) {
				return obs.timeStamp;
			}));
			//self.observations().sort(sortByName);
			var checked = "";
			for (var i = 0; i < self.observations().length; i++) {
				checked = "";
				if (!self.observations()[i].timeStamp) {
					checked = " checked";
				}
				htmlContent = htmlContent + "<label style=\"display: inline\" for:" + self.observations()[i].id + "><input style=\"cursor:pointer;margin-left: 2.8%\" id=" + self.observations()[i].id + " value=" + self.observations()[i].timeStamp + checked + " type=\"checkbox\" disabled>&nbsp;&nbsp;<span style=\"cursor:pointer;word-wrap: break-word\">" + self.observations()[i].name + "</span></label><br>";
			}
			htmlContent = htmlContent + "</div><br>";
		});
		htmlContent = htmlContent + "<div class=\"exportClass row\"><div class=\"col-xs-3\"><label>Format</label></div><div class=\"col-xs-5\"><span>Python Model</span></div></div><br>";
		htmlContent += "<div class=\"optimise row\"><div class=\"col-xs-3\"><label>Optimise</label></div><div class=\"col-xs-5\"><input type=\"checkbox\" id='" + window.utils.htmlEscape(dataGuid) + "optimise' value='Optimise' checked style=\"cursor: pointer;\"></div></div><br>"
		htmlContent += "<div class=\"objective row\"><div class=\"col-xs-3\"><label>Choose Objective</label></div><div class=\"col-xs-7\"><span class=\"glyphicon glyphicon-plus glyphicon-button\ style=\"font-size:medium\" id='" + window.utils.htmlEscape(dataGuid) + "chooseValue'></span></div></div><br>"
		bootbox.dialog({
			title: '<img src="img/Small/icons-15.png">  Python Model',
			message: htmlContent,
			buttons: {
				main: {
					label: "Close",
					className: "btn btn-default",
				},
				success: {
					label: '<i id="generateCompleteBtn" class="fa fa-check"></i> Complete',
					className: "btn btn-complete",
					callback: function () {
						var selectedOptions = jQuery('.exportClass input:checked');
						var excludeValue = true;
						var excludeRecipientOpinion = true;
						var excludeSatisfaction = true;
						var excludePercentageWeight = true;
						var filterObs = [];
						for (var i = 0; i < selectedOptions.length; i++) {
							var val = selectedOptions[i].value;
							if (val === 'Value Measurements') {
								excludeValue = false;
							} else if (val === 'Recipient Measurements') {
								excludeRecipientOpinion = false;
							} else if (val === 'Satisfaction Level Measurements') {
								excludeSatisfaction = false;
							} else if (val === 'Percentage Weight Measurements') {
								excludePercentageWeight = false;
							} else {
								if (val === "undefined") {
									filterObs.push(undefined);
								} else {
									filterObs.push(val);
								}
							}
						}
						
						var timeStampGuid = null;
						var scenarioName = null;
						var selectedFormat = "Python Model"
						var isOptimise = $('#' + window.utils.htmlEscape(dataGuid) + "optimise").is(':checked') ? true : false;
						DataManager.getDataManager().get('currentPlan').generateMetricsCSV(undefined, filterObs, undefined, excludeRecipientOpinion, excludePercentageWeight, excludeSatisfaction, excludeValue, selectedFormat, timeStampGuid, scenarioName, isOptimise, self.objectiveArray);
					}
				}
			}
		});

		$('#' + window.utils.htmlEscape(dataGuid) + "optimise").on('click', function () {
			if ($('#' + window.utils.htmlEscape(dataGuid) + "optimise").is(':checked')) {
				$('.objective').show()
			} else {
				$('.objective').hide();
			}
		})

		$('#' + window.utils.htmlEscape(dataGuid) + "chooseValue").on('click', function () {
			var valuesArray = []; 
			var plan = DataManager.getDataManager().get('currentPlan');
			valuesArray = self.fetchPlanValues(plan, valuesArray, $("#selectScenario option:selected")[0].innerText)
			self.fetchBmValues(plan, valuesArray, $("#selectScenario option:selected")[0].innerText);
			
			var content = "<div><b>List of Values : </b><br>";
			for (var i = 0; i < valuesArray.length; i++) {
				var valueName = valuesArray[i].name + " (" + valuesArray[i].phaseName + ")";
				content += "<input type=radio id=" + window.utils.htmlEscape(valuesArray[i].id) + " value=\"" + valueName + "\" name=valuesList originalId = " + valuesArray[i].id + ">  " + valueName + "<br>"
			}
			content += "</div></br>";
			content += "<div class=\"minMaximise row\"><div class=\"col-xs-3\"><label>Select</label></div><div class=\"col-xs-5\"><input type = radio id = minimiseValue name = selectValue checked value=0>&nbspMinimise&nbsp&nbsp&nbsp<input type = radio id = maximiseValue name = selectValue value=1>&nbspMaximise</div></div><br>"
			content += "<div class=\"row\"><div class=\"col-xs-3\"><label>Enter Value</label><span style='color:orange;font-size:small'>*</span></div><div class=\"col-xs-7\"><input type=form id=" + window.utils.htmlEscape(dataGuid) + "value><span id=" + window.utils.htmlEscape(dataGuid) + "unit style='margin-left:10px'></span></div></div>";
			bootbox.dialog({
				title: '<img src="img/Small/icons-15.png">  List of values',
				message: content,
				buttons: {
					main: {
						label: "Close",
						className: "btn btn-default",
					},
					success: {
						label: '<i class="fa fa-check"></i> Complete',
						className: "btn btn-complete objectiveComplete disabled",
						callback: function () {
							var text = $('input[name="valuesList"]:checked').val();
							dataGuid = dataGuid.replaceAll(/[@#-]/g,'_');
							$('#' + window.utils.htmlEscape(dataGuid) + "chooseValue").removeClass('glyphicon glyphicon-plus glyphicon-button');
							var type = "Minimize";
							if ($('input[name="selectValue"]:checked').val() === "1") {
								type = "Maximize";
							}
							text += " (" + type + " :" + $('#' + window.utils.htmlEscape(dataGuid) + "value").val() + "  " + $('#' + window.utils.htmlEscape(dataGuid) + "unit").text() + " )";
							$('#' + window.utils.htmlEscape(dataGuid) + "chooseValue").text(text);
							self.objectiveArray = ({ id: $('input[name="valuesList"]:checked')[0].id, minOrMax: $('input[name="selectValue"]:checked').val(), value: $('#' + window.utils.htmlEscape(dataGuid) + "value").val(), unit: $('#' + window.utils.htmlEscape(dataGuid) + 'unit').text() })
						}
					}
				}
			})
			if (valuesArray.length > 0) {
				$('#' + window.utils.htmlEscape(valuesArray[0].id)).attr('checked', true);
			}
			$('input[name="valuesList"]').on('change', function (view) {
				var id = this.getAttribute('originalId');
				var model = Backbone.Relational.store.getObjectByName('smm.CollectiveMeasurement').find({ id: id });
				if (model) {
					var unit = model.get('observedMeasure').get('measure') ? model.get('observedMeasure').get('measure').get('unit') : "";
					$('#' + window.utils.htmlEscape(dataGuid) + "value").val(model.get('value'));
					$('#' + window.utils.htmlEscape(dataGuid) + "unit").text(unit ? unit.get('name') : "")
					$('.objectiveComplete').removeClass('disabled');
				}
			})

			$('#' + window.utils.htmlEscape(dataGuid) + "value").on('keyup', function (view) {
				var value = view.currentTarget.value;
				$('.objectiveComplete').addClass('disabled');
				if (value && value.trim()) {
					$('.objectiveComplete').removeClass('disabled');
				}
			})
		})
	}
	// sortByName(left, right) {
	// 	return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
	// }
	generateMetricsCSV() {
		var self = this;
		self.observations.removeAll();
		self.timeStampArray = self.fetchDashboardTimeStampsList();
		DataManager.getDataManager().get('currentPlan').scenarioExportDialog(self.timeStampArray);
	};
	
	deleteDashboard() {
		var self = this;
		var packaltId = self.model.id.substr(0,self.model.id.lastIndexOf('@')+1); 
		var viewAltId = DataManager.getDataManager().get('viewAlternative');
		function doDashboardDelete(){
				var vdmPackage = self.model.getNestedParent();
				self.createRevisionBasedOnContext(vdmPackage,true,function(newVdmPackage){
					window.utils.startSpinner('chartDeleteSpinner','Deleting Dashboard ...');
					window.setTimeout(function(){
						if(window.checkContextForRevision()) {
							var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
							var altId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);
						}			                	
						var chartToBeDeleted = window.checkContextForRevision() ? newVdmPackage.get('dashboard').findWhere({'id':altId+bmId}) : self.model;
						var chartToBeDeletedLen = chartToBeDeleted.get('presentation').length;
                        while (chartToBeDeletedLen--) {
                            var presentation = chartToBeDeleted.get('presentation').models[chartToBeDeletedLen];
                            if (presentation) {
                            	presentation.destroy();
                            }
                        }
						/*var charts = chartToBeDeleted.get('presentation');
						charts.each(function (chart) {
							var chartFound = newVdmPackage.get('presentation').findWhere({'id':chart.id});
							if(chartFound){
								newVdmPackage.get('presentation').remove(chart.id);
							}
						});*/
						var scenarioList = [];
						var config = chartToBeDeleted.get('config');
						if(config) {
							var configJson = JSON.parse(config);
							for(var alt in configJson){
								for(var whenObserved in configJson[alt]){
									scenarioList.push(configJson[alt][whenObserved]);
								}	
							}
							scenarioList = _.uniq(scenarioList);
						}
						
						function deleteDashScenarios(){
							packaltId = self.model.id.substr(0,self.model.id.lastIndexOf('@')+1); 
							viewAltId = DataManager.getDataManager().get('viewAlternative');
							if(viewAltId === packaltId){
								DataManager.getDataManager().get('currentPlan').deleteScenario(scenarioList, function () {
									chartToBeDeleted.destroy();
									DataManager.getDataManager().calculateValuesCache = {};
									window.utils.stopSpinner('chartDeleteSpinner');
									DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
								});
							}else{
								chartToBeDeleted.destroy();
								window.utils.stopSpinner('chartDeleteSpinner');
								DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
							} 
						}
						DataManager.getDataManager().invokeValueUpdates(deleteDashScenarios);
					},100);
					});   
				}
			var scenarios = self.model.get('config');
			var chart = self.model.get('presentation');
			//var settings = self.model.get('settings');
			if((chart.length > 0 || (scenarios && scenarios.length > 0)) && viewAltId === packaltId){               
				bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert', 'Dashboard'), function(result) {
					if(result) {
						doDashboardDelete();
					}    
				});		
			}else{
				doDashboardDelete();
			}
	};
	
	saveSettings(view,encodeId,selectedValues){
		var self = this;
		window.utils.startSpinner('savingSelectionSpinner', "Saving Values...");    
			setTimeout(function(){
				var vdmPackage = self.model.getNestedParent();
				var createRevision = false;
				function arraysEqual(arr1, arr2) {
					if(arr1.length !== arr2.length)
						return false;
					for(var i = arr1.length; i--;) {
						if(arr1[i].id !== arr2[i].escapeId+'value')
							return false;
					}
				
					return true;
				}
				createRevision = !arraysEqual(selectedValues,self.selectedValuesArray);
				self.createRevisionBasedOnContext(vdmPackage,createRevision,function(newVdmPackage){
					self.selectedValuesArray.length = 0;
					self.selectedValuesArray = self.selectedValuesArray.concat(selectedValues);
					if(newVdmPackage !== vdmPackage){
						var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
						var viewAltId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);			                	
						var analyticChart = newVdmPackage.get('dashboard').findWhere({'id':viewAltId+bmId});
						self.model = analyticChart;
						self.refreshTable();
						DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newVdmPackage.get('id') +"/" + newVdmPackage.get('type') + "/" +  newVdmPackage.get('version') + "/"+ analyticChart.id + "/"+ analyticChart.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
					}else {
						self.refreshTable(function(){
							self.displayTable();
						});
						self.setResizeHeight();
					}
					window.cleanDialogModel(encodeId,view);
					window.utils.stopSpinner('savingSelectionSpinner');
				});
			},100);
	};
	
	openSelectValuesView(){
		var self = this;
		var id = window.utils.htmlEscape(window.guidGenerator()) + "addValue";
		var addOptions = {'viewType':'addValue','settings':true};
		window.getAndCreateModalDialog(self,id,DashboardMixin,self.model,"SelectChartValues",self.saveSettings,addOptions);
	};
	
	fillAlternatives(){
		var self = this;
		var planPhases = DataManager.getDataManager().get('currentPlan').get('phase');
		for(var j=0;j<planPhases.length;j++){
			var alts = planPhases.at(j).get('phaseAlternative');
			alts.each(function(alt){
				self.altList.push({id:alt.get('id'),name:alt.get('name')});
			});
		}
	}
	
	refreshTable(callback){
		var self = this;
		self.selectedValues.removeAll();
		for(var i=0;i<self.selectedValuesArray.length;i++){
			if(!self.selectedValuesArray[i].sourceType || !self.selectedValuesArray[i].sourceName){
				var valComp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: self.selectedValuesArray[i].id });
				if(!valComp){
					valComp = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: self.selectedValuesArray[i].id });
				}
				if(valComp){
					self.selectedValuesArray[i].sourceType = window.utils.getSourceType(valComp);
					self.selectedValuesArray[i].sourceName = window.utils.getSourceName(valComp);
				}
			}
			self.selectedValues.push(self.selectedValuesArray[i]);
		}
		//if(self.selectedValues().length>0){
			self.setDashBoardSettings(self.selectedValues());
		//}
		callback && callback();
	};
	

	/*self.refreshTable();
		setTimeout(function(){
			self.displayTable();
		}, 100);*/
	/*updateObservation();
	this.updateObservations = function () {
		window.utils.startSpinner('updateObservation','Updating observations ...');
		setTimeout(function(){
			updateObservation(true, function(){
				window.utils.stopSpinner('updateObservation');
			});
		}, 100);
	};*/
	displayTable(){
		var self = this;
		var myTableDiv = $('#valueColl' + self.encodeId);
		myTableDiv.empty();
		var table = document.createElement('TABLE');
		table.id = 'table' + self.encodeId;
		table.className="display";
		//table.className="pageResize";
		table.cellspacing="0";
		table.width="100%";
		//for header
		var tableHeader = document.createElement('THEAD');
		table.appendChild(tableHeader);
		var headerTR1 = document.createElement('TR');
		tableHeader.appendChild(headerTR1);
		
		var th1 = document.createElement('TH');
		th1.appendChild(document.createTextNode(self.labels['InputValues']()));
		headerTR1.appendChild(th1);
		
		var th2 = document.createElement('TH');
		th2.appendChild(document.createTextNode(self.labels['source']()));
		headerTR1.appendChild(th2);
		
		var th3 = document.createElement('TH');
		th3.appendChild(document.createTextNode(self.labels['sourceType']()));
		headerTR1.appendChild(th3);
		
		var th4 = document.createElement('TH');
		th4.appendChild(document.createTextNode(self.labels['Delete']()));
		headerTR1.appendChild(th4);
		
		//for tbody
		var tableBody = document.createElement('TBODY');
		table.appendChild(tableBody);
		for (var i=0; i<self.selectedValues().length; i++){
			var value = self.selectedValues()[i];
			var tr = document.createElement('TR');
			tableBody.appendChild(tr);
			
			var td1 = document.createElement('TD');
			td1.appendChild(document.createTextNode(value.name));
			//td1.setAttribute("id", value.escapeId);
			td1.style.textAlign = "left";
			tr.appendChild(td1);
			
			var td2 = document.createElement('TD');
			td2.appendChild(document.createTextNode(value.sourceName));
			//td2.setAttribute("id", value.source);
			td2.style.textAlign = "left";
			tr.appendChild(td2);
			
			var td3 = document.createElement('TD');
			td3.appendChild(document.createTextNode(value.sourceType));
			//td3.setAttribute("id", value.sourceType);
			td3.style.textAlign = "left";
			tr.appendChild(td3);
			
			var td4 = document.createElement('TD');
			var icon = document.createElement('icon');
			icon.className = 'glyphicon glyphicon-trash glyphicon-button';
			icon.title = 'Delete Value';
			icon.id = value.id;
			icon.style.fontSize = '1.2em';
			td4.appendChild(icon);
			tr.appendChild(td4);
			$(icon).on('click', function(event){
				self.deleteDirectValue(value, event, function(){
					self.displayTable ? self.displayTable() : '';
				});
			});
		}
		myTableDiv.append(table);
		self.dataTable = $('#table' + self.encodeId).DataTable({
			"columnDefs": [
				{ "orderable": false, "targets": 3} 
			],
			"sDom": '<"top"f><"table-container"t><"bottom d-flex"<"pull-left"i><"pull-right"p>>',
			"fnDrawCallback": function( oSettings ) {
				//self.setResizeHeight();
			}
		});
		$('#table'+self.encodeId+'_filter').css({float:'left'});
		$('#table'+self.encodeId+'_length').css('width','32%');
        if(!DataManager.getDataManager().get('readMode') || !window.utils.checkIsLinked(null,true)){
		    $("<div style=\"float:right; margin-left:5px;\"><span id: 'valueAddIconDiv'"+self.encodeId+"}\">"+
				    "<b>"+DataManager.getDataManager().get('localeManager').get('addAnother')+"</b>"+
				    "<button id = 'valueAddIconDiv'"+self.encodeId+"\" style=\"float:right; font-size: 1.2em;border: 0px;background: #f5f5f5;\" data-toggle=\"tooltip\" title=\"Add Value\" class=\"glyphicon glyphicon-plus-sign glyphicon-button \"></button>"+
		    "</span></div>").insertAfter($('#table'+self.encodeId+'_wrapper').find('.dt-search'));
        }
		$('#valueAddIconDiv').click(function(){
			var id = window.utils.htmlEscape(window.guidGenerator()) + "addValue";
			var addOptions = {'viewType':'addValue','settings':true};
			window.getAndCreateModalDialog(self,id,DashboardMixin,self.model,"SelectChartValues",self.saveSettings,addOptions);
		});
	};
	fetchComponentByEscapeId(valId){
		var self = this;
		var valComp = null;
		if(self.selectedValues()){
			valComp = _.filter(self.selectedValues(),function(obj){return obj.id === valId;})[0];
		}
		return valComp;
	}

	setDashBoardSettings(valuesArray){
		var self = this;
		var ids='';
		var id = '';
		for(var i=0;i<valuesArray.length;i++){
			id = valuesArray[i].id;
			ids = ids.concat(id).concat(',');
		}
		var settings = self.model.get('settings');
		if(settings){
			var obj = JSON.parse(settings);
			self.settingsList=[];
			if(obj.length>0){
				obj[0].values = ids;
				self.settingsList = obj;
			}
		}
		if(!settings || !obj){
			var newObj = {'values':ids};
			self.settingsList=[];
			self.settingsList.push(newObj);
		}
		var new_list = JSON.stringify(self.settingsList);
		self.model.set('settings',new_list);
	}
	
	deleteDirectValue(data, event, callback){
		var self = this;
		event.stopPropagation();
		var currentId = event.currentTarget.id;
		var valComp = self.fetchComponentByEscapeId(currentId);
		var vdmPackage = self.model.getNestedParent();
		self.createRevisionBasedOnContext(vdmPackage,true,function(newVdmPackage){
			window.utils.startSpinner('deleteValue','Deleting Value ...');
			window.setTimeout(function(){
				
				if(newVdmPackage !== vdmPackage){
					var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
					var viewAltId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);			                	
					var analyticChart = newVdmPackage.get('dashboard').findWhere({'id':viewAltId+bmId});
					self.model = analyticChart;
					self.selectedValues.remove(valComp);
					self.setDashBoardSettings(self.selectedValues());
					DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newVdmPackage.get('id') +"/" + newVdmPackage.get('type') + "/" +  newVdmPackage.get('version') + "/"+ analyticChart.id + "/"+ analyticChart.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
					}else {
					self.selectedValues.remove(valComp);
					self.selectedValuesArray.splice(_.findLastIndex(self.selectedValuesArray, {id:currentId}) ,1);
					self.setDashBoardSettings(self.selectedValues());
					self.parentView.setResizeHeight(false);
					}
				
				window.utils.stopSpinner('deleteValue');
				callback && callback();
			},10);
		});
	};
	setResizeHeight(){
		var self = this;
		window.vdmModelView.setResizeHeight(false);
	};
	init(model, options){
		var self = this;
		this.DashboardDetailsViewModel = this;
		this.saveSettings =_.bind(this.saveSettings,this);
		this.parentView = model;
		this.model = model.model;
		this.id = ko.observable(this.model.id);
		this.name = ko.observable(this.model.get('name'));
		this.description = ko.observable(this.model.get('description'));
		this.observations = ko.observableArray([]);
		this.selectedValues = ko.observableArray();
		this.selectedValuesArray = [];
		this.themesArray = ['Default', 'Dark Unica', 'Sand Signika', 'Grid Light'];
		this.selectedTheme = ko.observable();
		self.enableUpdate = ko.observable(false);
		self.altList = ko.observableArray([]);
		this.parentView.DashboardDetailsViewModel = self;
		self.parentView.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);
		self.initialized = false;
		this.showPythonModal = ko.observable(false);
		if(DataManager.getDataManager().get('debugMode')){
			self.showPythonModal(true);
		}
		function htmlEscape(str) {
    		return String(str).replaceAll(/[@#-]/g,'_');
            //.replace(/@/g, '_')
            //.replace(/ /g, '')
            //.replace(/#/g, '');
		}
		this.encodeId = htmlEscape(this.model.id);
		//this.encodeId = window.utils.htmlEscape(this.model.id);
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'source'
			, 'sourceType'
			, 'addAnother'
			, 'InputValues'
			, 'Delete'
			, 'ImportCSV'
			, 'ExportCSV'
			, 'Complete'
			, 'Theme'
		]);
		this.name.subscribe(function (val) {
			self.description(val);
		});

		this.selectedTheme.subscribe(function (val) {
			if (self.initialized) {
				Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
			}
			switch (val) {
				case 'Default':
					Highcharts.theme = self.parentView.highchartsDefaultTheme;
					Highcharts.setOptions(Highcharts.theme);
					if (self.initialized) {
						window.utils.startSpinner('refreshCharts', 'Refreshing ...');
						self.parentView.setResizeHeight(true);
					}
					break;

				case 'Dark Unica':
					Highcharts.theme = HighChartThemes.DarkUnica();
					Highcharts.setOptions(Highcharts.theme);
					if (self.initialized) {
						window.utils.startSpinner('refreshCharts', 'Refreshing ...');
						self.parentView.setResizeHeight(true);
					}
					break;

				case 'Sand Signika':
					Highcharts.theme = HighChartThemes.SandSignika();
					Highcharts.setOptions(Highcharts.theme);
					if (self.initialized) {
						window.utils.startSpinner('refreshCharts', 'Refreshing ...');
						self.parentView.setResizeHeight(true);
					}
					break;

				case 'Grid Light':
					Highcharts.theme = HighChartThemes.GridLight();
					Highcharts.setOptions(Highcharts.theme);
					if (self.initialized) {
						window.utils.startSpinner('refreshCharts', 'Refreshing ...');
						self.parentView.setResizeHeight(true);
					}
					break;
			}
			self.initialized = true;
		});
		
		//self.refreshTable();
	}

	loadTableData = function(){
		var self = this;
		self.fillAlternatives();
		if(self.model.get('settings')){
			var obj = JSON.parse(self.model.get('settings'));
			if(obj.length>0){
				var valuesExists = obj[0].values;
				if(valuesExists){
					var valArray = valuesExists.split(',');
					for(var i=0;i<valArray.length;i++){
						var valExits = window.utils.getElementModel(valArray[i],['vdml.ValuePropositionComponent','vdml.ValueAdd']);
						if(valExits){	
							var sourceType = window.utils.getSourceType(valExits);
							if(sourceType === "Plan"){
								var object = {id:valExits.get('id'),sourceType: sourceType, sourceName: window.utils.getSourceName(valExits),name:valExits.get('name'), escapeId:window.utils.htmlEscape(valExits.get('id')), suffixId:valExits.get('id').substr(valExits.get('id').lastIndexOf('@')+1), checked:true, displayName:valExits.get('name')};
							}else{
								object = {id:valExits.get('id'),sourceType: sourceType, sourceName: window.utils.getSourceName(valExits), name:valExits.get('name'), escapeId:window.utils.htmlEscape(valExits.get('id')), suffixId:valExits.get('id').substr(valExits.get('id').lastIndexOf('@')+1), checked:true, displayName:valExits.get('name')};
							}
							self.selectedValuesArray.push(object);
							self.selectedValues.push(object);
						}
					}
				}else{
					self.selectedValuesArray=[];
				}
			}
		}
		if(self.model.get('selectedTheme')){
			self.selectedTheme(self.model.get('selectedTheme'));
		}
	}
	
	static getInstance = function(model,options){
         var view = new DashboardDetailsViewModel(model, options);
         view.init(model, options);
         return view;
	};
}
path.DashboardDetailsViewModel = DashboardDetailsViewModel;